import React, { useEffect, useState } from 'react';
import LeftNav from '../components/LeftNav';
import TopPageHeader from '../components/TopPageHeader';
import Modal from '../components/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom'

const SetUpUserPage = () => {
    const [isAddNewOpen, setIsAddNewOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [postData, setPostData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [departmentlist,setDepartmentlist] = useState([]);
    const [searchData, setSearchData] = useState('');
    const [pageno,PageNumber]=useState(1);
    const [isnameexist,setisnameexist]=useState(true);
    const [isusernameexist,setisusernameexist]=useState(true);
    const [ispasswordexist,setispasswordexist]=useState(true);
    const [isicnoexist,setisicnoexist]=useState(true);
    const [isdidexist,setisdidexist]=useState(true);
    const [isdatejoinexist,setisdatejoinexist]=useState(true);
    const [ismainloading,setismainloading]=useState(false);
    const [ismodalloading,setismodalloading]=useState(false);
    const navigate = useNavigate();

    const getDepartmentNameFromId = (DepartmentId) => {
        if(departmentlist.length !== 0){
        const department = departmentlist.find(department => department.id === DepartmentId);
        return department.name;
        }
    };

    const handleEditClick = (item) => {
        setisusernameexist(true);
        setisnameexist(true);
        setispasswordexist(true);
        setisicnoexist(true);
        setisdidexist(true);
        setisdatejoinexist(true);
        setSelectedItem(item);
        setIsEditOpen(true);
    };

    const handleEditInputChange = (property, value) => {
        setSelectedItem({
            ...selectedItem,
            [property]: value,
        });
    };

    const previouspage =()=>{
        if (pageno!=1){
            setPageNumber(pageno-1);
        }
    }
    const setPageNumber =(page)=>{
            PageNumber(page);
            getuserlist('',page)
        }

    const getdepartment = () => {
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=get-department";

        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                setDepartmentlist(data);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

    const AddNew = () =>{
        setisusernameexist(true);
        setisnameexist(true);
        setispasswordexist(true);
        setisicnoexist(true);
        setisdidexist(true);
        setisdatejoinexist(true);
        clearSelectedItem();
        setIsAddNewOpen(true);
    };

    const clearSelectedItem = () => {
        setSelectedItem({
            'department_id':'',
            'username':'',
            'password':'',
            'name':'',
            'ic_no':'',
            'date_join':'',
        }
        );
    };

    const searchfunction =(value)=>{
        setSearchData(value);
        getuserlist(value,1);
    }

    const getuserlist = async(searchvalue,page) => {
        setismainloading(true);
        let offset=(page-1)*20;
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=get-staff";
        formData.append('searchword', searchvalue);
        formData.append('offset',String(offset));

        return await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                setPostData(data);
                setismainloading(false);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
            setismainloading(false);
        });
    };

    useEffect(() => {
        getdepartment();
        getuserlist('',pageno);
        
}, []);

const addnewuser = async() =>{
    const confirmed = window.confirm("Are you sure you want to save changes?");
    if (!confirmed) {
        toast.error("User Create Canceled", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        return;
    }
 
    const validateField = (field) => field.trim() !== '';

    const isNameValid = validateField(selectedItem.name);
    const isUsernameValid = validateField(selectedItem.username);
    const isPasswordValid = validateField(selectedItem.password);
    const isicnoValid = validateField(selectedItem.ic_no);
    const isdidValid = validateField(selectedItem.department_id);
    const isdatejoinValid = validateField(selectedItem.date_join);

    setisnameexist(isNameValid);
    setisusernameexist(isUsernameValid);
    setispasswordexist(isPasswordValid);
    setisicnoexist(isicnoValid);
    setisdidexist(isdidValid);
    setisdatejoinexist(isdatejoinValid);

    if (!isNameValid || !isUsernameValid || !isPasswordValid || !isicnoValid || !isdidValid || !isdatejoinValid) {
        return;
    }
    const formData = new FormData();
    const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=add-staff";
    formData.append('name', selectedItem.name);
    formData.append('username', selectedItem.username);
    formData.append('password', selectedItem.password);
    formData.append('ic_no', selectedItem.ic_no);
    formData.append('department_id', selectedItem.department_id);
    formData.append('date_join', moment(selectedItem.date_join).format('YYYY-MM-DD'));
    formData.append('technician', false);
    console.log(selectedItem.name);
    return await fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': sessionStorage.getItem('token'),
            'Access-Control-Allow-Credentials':true,
        },
        body: formData
    })
    .then((response) => {
        if (!response.ok) {
            if (response.status === 401) {
                sessionStorage.clear();
                navigate('/login');
            } else {
                throw new Error('API Failed');
            }
        }
        return response.json();
    })
    .then((json) => {
        const { success, data} = json;
        if (success==1){
            toast.success("User Create Completed", {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        console.log(1);
                        setIsAddNewOpen(false);
                        getuserlist('',1);
        }
    })
    .catch(error => {
        console.error('Server Connection Failed');
    });

};

const edituser = async() =>{
    const confirmed = window.confirm("Are you sure you want to save changes?");
    if (!confirmed) {
        toast.error("User Info Update Canceled", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        return;
    }
 
    const validateField = (field) => field.trim() !== '';

    const isNameValid = validateField(selectedItem.name);
    const isUsernameValid = validateField(selectedItem.username);
    const isPasswordValid = validateField(selectedItem.password);
    const isicnoValid = validateField(selectedItem.ic_no);
    const isdidValid = validateField(selectedItem.department_id);
    const isdatejoinValid = validateField(selectedItem.date_join);

    setisnameexist(isNameValid);
    setisusernameexist(isUsernameValid);
    setispasswordexist(isPasswordValid);
    setisicnoexist(isicnoValid);
    setisdidexist(isdidValid);
    setisdatejoinexist(isdatejoinValid);

    if (!isNameValid || !isUsernameValid || !isPasswordValid || !isicnoValid || !isdidValid || !isdatejoinValid) {
        return;
    }
    const formData = new FormData();
    const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=edit-staff";
    formData.append('id', selectedItem.id);
    formData.append('name', selectedItem.name);
    formData.append('username', selectedItem.username);
    formData.append('password', selectedItem.password);
    formData.append('ic_no', selectedItem.ic_no);
    formData.append('department_id', selectedItem.department_id);
    formData.append('date_join', moment(selectedItem.date_join).format('YYYY-MM-DD'));
    formData.append('technician', selectedItem.technician);
    return await fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': sessionStorage.getItem('token'),
            'Access-Control-Allow-Credentials':true,
        },
        body: formData
    })
    .then((response) => {
        if (!response.ok) {
            if (response.status === 401) {
                sessionStorage.clear();
                navigate('/login');
            } else {
                throw new Error('API Failed');
            }
        }
        return response.json();
    })
    .then((json) => {
        const { success, data} = json;
        if (success==1){
            toast.success("User Info Update Completed", {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        console.log(1);
                        setIsEditOpen(false);
                        getuserlist('',1);
        }
    })
    .catch(error => {
        console.error('Server Connection Failed',error.message);
    });

};

    return (
            <div className='main-content'>
                <TopPageHeader title='User' />

                <section className='content-area'>
                    <header className='content-area__header'>
                        <button className='content-area__header--btn' onClick={() => AddNew()}>+ Add New User</button>
                        {
                            isAddNewOpen &&
                            <Modal
                                title='Add New User'
                                setIsOpen={setIsAddNewOpen}
                                actionBtn={ismodalloading ? (null):(<button className='form-action-btn' onClick={() => addnewuser()}>Save</button>)}
                                style={{ width: '64rem', height: '64rem' }}
                            >
                                {ismodalloading ? (
                                                <div className='loading-gif-container'>
                                                <img src='./../images/spinner.gif' alt='Loading...' />
                                                </div>
                                        ) : (
                                <div className='grid-1-form'>
                                    <div className='form-control'>
                                        <label className='form-control__label'>User Name</label>
                                        <input className='form-control__input' type="text" placeholder='Name' value={selectedItem.username} onChange={(e) => handleEditInputChange('username', e.target.value)} />
                                        {isusernameexist === false && (
                                            <label className='form-control__error'>Please key in User Name</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Name</label>
                                        <input className='form-control__input' type="text" placeholder='Name' value={selectedItem.name} onChange={(e) => handleEditInputChange('name', e.target.value)} />
                                        {isnameexist === false && (
                                            <label className='form-control__error'>Please key in Name</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>IC No.</label>
                                        <input className='form-control__input' type="text" placeholder='IC No.' value={selectedItem.ic_no} onChange={(e) => handleEditInputChange('ic_no', e.target.value)}/>
                                        {isicnoexist === false && (
                                            <label className='form-control__error'>Please key in IC No.</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Department</label>
                                        <select className='form-control__input' placeholder='Company Name' value={selectedItem.department_id} onChange={(e) => handleEditInputChange('department_id', e.target.value)}>
                                        <option value=''>Please Select A Department</option>
                                        {departmentlist.map((department) => (
                                            <option key={department.id} value={department.id}>
                                                {department.name}
                                            </option>
                                        ))}
                                        </select>
                                        {isdidexist === false && (
                                            <label className='form-control__error'>Please select a department</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Password</label>
                                        <input className='form-control__input' type="text" placeholder='Password' value={selectedItem.password} onChange={(e) => handleEditInputChange('password', e.target.value)} />
                                        {ispasswordexist === false && (
                                            <label className='form-control__error'>Please key in Password</label>
                                        )}
                                    </div>
                                    <div className='form-control'>
                                        <label className='form-control__label'>Date Join</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Date Join' selected={selectedItem.date_join} onChange={(date) => handleEditInputChange('date_join', date)} dateFormat="dd/MM/yyyy" onKeyDown={(e) => e.preventDefault()} />
                                        {isdatejoinexist === false && (
                                            <label className='form-control__error'>Please key in Date Join</label>
                                        )}
                                    </div>
                                </div>
                                        )
                                        }
                            </Modal>
                        }

                        <div>
                            <div className='content-area__header--search'>
                                <img className='search-icon' src='./../images/search-icon.png' alt='search icon' />
                                <input className='search-input' type="text" placeholder='Search' value={searchData} onChange={(e) =>{searchfunction(e.target.value)}}/>
                            </div>

                            <nav className='content-area__header--pagination'>
                                <button className='pagination-btn nav' onClick={previouspage}>&lt;</button>
                                <button className={`pagination-btn ${pageno === 1 ? 'active' : ''}`} onClick={() => setPageNumber(1)}>1</button>
                                <button className={`pagination-btn ${pageno === 2 ? 'active' : ''}`} onClick={() => setPageNumber(2)}>2</button>
                                <button className={`pagination-btn ${pageno === 3 ? 'active' : ''}`} onClick={() => setPageNumber(3)}>3</button>
                                <button className='pagination-btn'>...</button>
                                <button className={`pagination-btn ${pageno === 10 ? 'active' : ''}`} onClick={() => setPageNumber(10)}>10</button>
                                <button className='pagination-btn nav' onClick={() => setPageNumber(pageno+1)}>&gt;</button>
                            </nav>
                        </div>
                    </header>
                    {ismainloading ? (
                        <div className='loading-screen'>
                        <img src='./../images/spinner.gif' alt='Loading...' />
                        </div>
                    ) : (
                    <>
                    <div className='content-box'>
                        <div className='data-table scroll-sm'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>User Name</th>
                                        <th>Name</th>
                                        <th>IC No.</th>
                                        <th>Department</th>
                                        <th>Date Join</th>
                                        <th>Password</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                {postData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{(pageno-1)*20+index + 1}</td>
                                        <td>{item.username}</td>
                                        <td>{item.name}</td>
                                        <td>{item.ic_no}</td>
                                        <td>{getDepartmentNameFromId(item.department_id)}</td>
                                        <td>{item.date_join}</td>
                                        <td>{item.password}</td>
                                        <td>
                                        <button className='view-btn' onClick={() => handleEditClick(item)}>View details</button>
                                        </td>
                                    </tr>
                                    )
                                )
                            }
                                </tbody>
                            </table>
                        </div>

                        {
                            isEditOpen &&
                            <Modal
                                title='Edit User'
                                setIsOpen={setIsEditOpen}
                                actionBtn={ismodalloading ? (null):(<button className='form-action-btn' onClick={async() =>{setismodalloading(true); await edituser();setismodalloading(false)}}>Save</button>)}
                                style={{ width: '64rem', height: '64rem' }}
                            >
                                {ismodalloading ? (
                                                <div className='loading-gif-container'>
                                                <img src='./../images/spinner.gif' alt='Loading...' />
                                                </div>
                                        ) : (
                                <div className='grid-1-form'>

                                    <div className='form-control'>
                                        <label className='form-control__label'>User ID</label>
                                        <input className='form-control__input' type="text" placeholder='User ID' value={selectedItem.id} disabled={true} style={{backgroundColor:'#C0C0C0'}} />
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>User Name</label>
                                        <input className='form-control__input' type="text" placeholder='User Name' value={selectedItem.username} disabled={true} style={{backgroundColor:'#C0C0C0'}} />
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Name</label>
                                        <input className='form-control__input' type="text" placeholder='Name' value={selectedItem.name} onChange={(e) => handleEditInputChange('name', e.target.value)} />
                                        {isnameexist === false && (
                                            <label className='form-control__error'>Please key in Name</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>IC No.</label>
                                        <input className='form-control__input' type="text" placeholder='IC No.' value={selectedItem.ic_no} onChange={(e) => handleEditInputChange('ic_no', e.target.value)}/>
                                        {isicnoexist === false && (
                                            <label className='form-control__error'>Please key in IC No.</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Department</label>
                                        <select className='form-control__input' placeholder='Company Name' value={selectedItem.department_id} onChange={(e) => handleEditInputChange('department_id', e.target.value)}>
                                        {departmentlist.map((department) => (
                                            <option key={department.id} value={department.id}>
                                                {department.name}
                                            </option>
                                        ))}
                                        </select>
                                        {isdidexist === false && (
                                            <label className='form-control__error'>Please select a department</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Password</label>
                                        <input className='form-control__input' type="text" placeholder='Password' value={selectedItem.password} onChange={(e) => handleEditInputChange('password', e.target.value)} />
                                        {ispasswordexist === false && (
                                            <label className='form-control__error'>Please key in Password</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Date Join</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Date Join' selected={selectedItem.date_join} onChange={(date) => handleEditInputChange('date_join', date)} dateFormat="dd/MM/yyyy" onKeyDown={(e) => e.preventDefault()} />
                                        {isdatejoinexist === false && (
                                            <label className='form-control__error'>Please key in Date Join</label>
                                        )}
                                    </div>
                                </div>
                                        )
                                    }
                            </Modal>
                        }
                    </div>
                    </>
                    )
                }
                </section>
            </div>
    )
}

export default SetUpUserPage;