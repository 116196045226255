import React, { useEffect, useState, useRef } from 'react';
import TopPageHeader from '../components/TopPageHeader';
import Modal from '../components/Modal';
import Multiselect from 'multiselect-react-dropdown';
import { NumberInput } from '../components/Inputs';
import SignatureCanvas from 'react-signature-canvas'
import moment from 'moment';
import { useReactMediaRecorder } from "react-media-recorder-2";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AsyncCompiler } from 'sass';
import Moment from 'react-moment';

function AppointmentPage() {
    const [isAddNewOpen, setIsAddNewOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [postData, setPostData] = useState([]);
    const [pageno,PageNumber]=useState(1);
    const [companylist,setCompanylist] = useState([]);
    const [vehiclelist,setVehiclelist] = useState([]);
    const [customerlist,setCustomerlist] = useState([]);
    const navigate = useNavigate();
    const [ismainloading,setismainloading]=useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [servicelist, setservicelist]=useState([]);
    const [ismodalloading,setismodalloading]=useState(false);
    const [searchData, setSearchData] = useState('');
    const [todaydate, settodaydate]=useState(new Date);
    const [isActive, setIsActive] = useState(false);
    const [filterVisible, setFilterVisible] = React.useState({
        all: true,
        booked: true,
        cancelled: true,
        completed: true,
    });

    const [activeFilter, setActiveFilter] = useState('all');
      
    const handleFilterClick = (filter) => {
      setActiveFilter(filter);
    };

    const handleEditInputChange = (property, value) => {
        setSelectedItem({
            ...selectedItem,
            [property]: value,
        });
    };
    
    const initialise = () =>{
        setSelectedItem(
            {
                company_id:'',
                service_id:'',
                vehicle_id:'',
                customer_id:'',
            }
        )
    }

    const previouspage =()=>{
        if (pageno!=1){
            setPageNumber(pageno-1);
        }
    }

    const setPageNumber =async(page)=>{
        setismainloading(true);
        PageNumber(page);
        await getappointmentlist('',page);
        setismainloading(false);
    }

    
    const searchfunction =async(value)=>{
        setismainloading(true);
        setSearchData(value);
        PageNumber(1);
        setismainloading(false);
    }

    useEffect(() => {
        appointment(pageno);
        getcompany();   
    }, []);

    useEffect(() => {
        appointment(pageno);       
    }, [searchData,pageno,activeFilter]);

    const appointment =async(pageno)=>{
        setismainloading(true);
        await getappointmentlist(pageno);
        setismainloading(false);
    }

    const getservice = () => {
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=get-servicelist";
        
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                setservicelist(data);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

    const getcompany = () => {
        const url = "https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=get-companylist";
        return fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data } = json;
            if (success == 1) {
                setCompanylist(data);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };
    
    const getcustomerlist = async(id) => {
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=get-customerlist";
        formData.append('company_id', id);

        return await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                setCustomerlist(data);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

    const getcustomervehiclelist = async(id) => {
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=get-customer-vehiclelist";
        formData.append('company_id', id);

        return await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                setVehiclelist(data);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

    const acceptappointment = async() => {
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=approve-appointment";
        formData.append('appointment_id', selectedItem.appointment_id);
        formData.append('estimated_out', selectedItem.estimated_out);
        return await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                toast.success("Appointment Accepted", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                appointment(pageno);
                setIsEditOpen(false);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

    const rejectappointment = async(id) => {
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=delete-appointment";
        formData.append('appointment_id', id);

        return await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                toast.success("Appointment Rejected", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                appointment(pageno);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

    const getappointmentlist = async(page) => {
        let offset=(page-1)*20;
        const formData = new FormData();
        if(searchData!=''){
            formData.append('customer_id',String(searchData));
        }
        if(activeFilter!='all'){
            formData.append('filter', String(activeFilter));
        }
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=get-appointment";
        formData.append('offset',String(offset));
        return await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                setPostData(data);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

    const toggleHandler = () => {
        setIsActive(!isActive);
      };

   const addnewappointment = async () => {
        if (selectedItem.company_id.trim() !=='' && selectedItem.customer_id.trim() !=='' && 
            selectedItem.vehicle_id.trim() !== '' && selectedItem.service_id.trim() !== '') {
        const formData = new FormData();
            const url = "https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=add-appointment";
            formData.append('service_id', selectedItem.service_id);
            formData.append('vehicle_id', selectedItem.vehicle_id);
            formData.append('company_id', selectedItem.company_id);
            formData.append('customer_id', selectedItem.customer_id);
            formData.append('appointment_date', selectedItem.appointment_at);
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': sessionStorage.getItem('token'),
                        'Access-Control-Allow-Credentials': true,
                        'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
                    },
                    body: formData
                });
        
                if (!response.ok) {
                    if (response.status === 401) {
                        sessionStorage.clear();
                        navigate('/login');
                    } else {
                        throw new Error('API Failed');
                    }
                }
                
                let json;
                try {
                    json = await response.json();
                } catch (e) {
                    throw new Error('Failed to parse JSON');
                }
                const { success, data } = json;
    
                if (success == 1) {
                    toast.success("Successfully Add New Appointment", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setIsAddNewOpen(false);
                    appointment(pageno);
                    setSelectedItem(null);
                }
            } catch (error) {
                console.error('Server Connection Failed:', error);
            }
        }else{
            toast.success("Please Complete the form", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        
    }

    return (
        <>
        <div className='main-content'>
            <TopPageHeader title='Manage Appointment' />

            <section className='content-area'>
                
                <header className='content-area__header'>
                    <div>
                        <button className='content-area__header--btn' onClick={async() => {setismodalloading(true); initialise();setIsAddNewOpen(true); await getservice();setismodalloading(false)}}>+ Add New Appointments</button>

                        {
                            isAddNewOpen &&
                            <Modal
                                title='Make New Appointment'
                                setIsOpen={setIsAddNewOpen}
                                
                                actionBtn={ismodalloading ? (null):(<button className='form-action-btn' onClick={async()=>{setismodalloading(true);await addnewappointment();setismodalloading(false)}}>Add New Appointment</button>)}
                                modelStyle='light-center'
                                style={{ width: '100rem', height: '45rem' }}
                            >
                            {ismodalloading ? (
                                                <div className='loading-gif-container'>
                                                <img src='./../images/spinner.gif' alt='Loading...' />
                                                </div>
                                        ) : (
                                <div className='grid-3-form'>
                                    <div className='form-control'>
                                        <label className='form-control__label'>Appointment Date</label>
                                        <DatePicker
                                            className="form-control__input datepicker"
                                            placeholderText="Select Appointment Date & Time"
                                            selected={selectedItem.appointment_at}
                                            onChange={(date) => handleEditInputChange('appointment_at', date)}
                                            showTimeSelect // Enables time selection
                                            dateFormat="dd/MM/yyyy h:mm aa" // Date and time format
                                            timeFormat="h:mm aa" // Time format
                                            timeIntervals={15} // Time interval in minutes
                                            minDate={new Date()} // Prevent past dates
                                            onKeyDown={(e) => e.preventDefault()} // Disable keyboard input
                                        />
                                    </div>
                                <div className='form-control'>
                                        <label className='form-control__label'>Company</label>
                                        <select className='form-control__input' value={selectedItem.company_id || ""} onChange={async(e)=>{setismodalloading(true); handleEditInputChange('company_id', e.target.value);await getcustomerlist(e.target.value);await getcustomervehiclelist(e.target.value);setismodalloading(false)}}>
                                        <option value="">Select a company</option>
                                            {companylist.map((item,index)=>(
                                                <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                            ))}
                                        </select>
                                    </div>
                                        <div className='form-control'>
                                        <label className='form-control__label'>Customer</label>
                                        <select className='form-control__input' value={selectedItem.customer_id || ""} onChange={(e)=>handleEditInputChange('customer_id', e.target.value)}>
                                        <option value="">Select a customer</option>
                                            {customerlist.map((item,index)=>(
                                                <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='form-control'>
                                        <label className='form-control__label'>Vehicle No</label>
                                        <select className='form-control__input' value={selectedItem.vehicle_id || ""} onChange={(e)=>handleEditInputChange('vehicle_id', e.target.value)}>
                                        <option value="">Select a Vehicle</option>
                                            {vehiclelist.map((item,index)=>(
                                                <option key={item.id} value={item.id}>
                                                {item.vehicle_no}
                                            </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='form-control'>
                                        <label className='form-control__label'>Service Type</label>
                                        <select className='form-control__input' value={selectedItem.service_id || ""} onChange={(e)=>handleEditInputChange('service_id', e.target.value)}>
                                        <option value="">Select a service</option>
                                            {servicelist.map((item,index)=>(
                                                <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                        )
                                        }
                            </Modal>
                        }

                        <div className="content-area__header--filter-row">
                            <label className="filter-row-label">Filter by :</label>
                            {filterVisible.all && (
                                <button
                                className={`filter-row-btn ${activeFilter === 'all' ? 'active' : ''}`}
                                onClick={() => handleFilterClick('all')}
                                >
                                All
                                </button>
                            )}
                            {filterVisible.booked && (
                                <button
                                className={`filter-row-btn ${activeFilter === 'booked' ? 'active' : ''}`}
                                onClick={() => handleFilterClick('booked')}
                                >
                                Booked
                                </button>
                            )}
                            {filterVisible.cancelled && (
                                <button
                                className={`filter-row-btn ${activeFilter === 'cancelled' ? 'active' : ''}`}
                                onClick={() => handleFilterClick('cancelled')}
                                >
                                Cancelled
                                </button>
                            )}
                            {filterVisible.completed && (
                                <button
                                className={`filter-row-btn ${activeFilter === 'completed' ? 'active' : ''}`}
                                onClick={() => handleFilterClick('completed')}
                                >
                                Completed
                                </button>
                            )}
                        </div>
                    </div>

                    <div>
                        <div className='content-area__header--search'>
                            <img className='search-icon' src='./../images/search-icon.png' alt='search icon' /> 
                            <select className='search-input' placeholder='Search' value={searchData || "" } onChange={(e) =>{searchfunction(e.target.value)}}>
                                        <option value="" >Select a company</option>
                                        {companylist.map((company) => (
                                            <option key={company.id} value={company.id}>
                                                {company.name}
                                            </option>
                                        ))}
                                        </select>
                        </div>

                        <nav className='content-area__header--pagination'>
                                <button className='pagination-btn nav' onClick={previouspage}>&lt;</button>
                                <button className={`pagination-btn ${pageno === 1 ? 'active' : ''}`} onClick={() => setPageNumber(1)}>1</button>
                                <button className={`pagination-btn ${pageno === 2 ? 'active' : ''}`} onClick={() => setPageNumber(2)}>2</button>
                                <button className={`pagination-btn ${pageno === 3 ? 'active' : ''}`} onClick={() => setPageNumber(3)}>3</button>
                                <button className='pagination-btn'>...</button>
                                <button className={`pagination-btn ${pageno === 10 ? 'active' : ''}`} onClick={() => setPageNumber(10)}>10</button>
                                <button className='pagination-btn nav' onClick={() => setPageNumber(pageno+1)}>&gt;</button>
                            </nav>

                        
                    </div>
                </header>
                {ismainloading ? (
                        <div className='loading-screen'>
                        <img src='./../images/spinner.gif' alt='Loading...' />
                        </div>
                    ) : (
                    <>
                    <div className='content-box'>
                        <div className='data-table scroll-sm'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Customer ID.</th>
                                        <th>Vehicle No.</th>
                                        <th>Reg No.</th>
                                        <th>Status</th>
                                        <th>Appointment Date & Time</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                {postData.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.customer_id}</td>
                                            <td>{item.vehicle_no}</td>
                                            <td>{item.registration_no}</td>
                                            <td><span
                                                className={`status-badge ${
                                                typeof item.status === 'string'
                                                    ? item.status.toLowerCase().replace(/\s+/g, '-')
                                                    : "unknown"
                                                }`}
                                            >
                                                {item.status || 'Unknown'}
                                            </span></td>
                                            <td>
                                                {moment(item.appointment_at).format("DD/MM/YYYY")}
                                                <br />
                                                {moment(item.appointment_at).format("hh:mm:ss A")}
                                            </td>
                                            <td>
                                                <div className="action-buttons">
                                                    {item.status =="Pending Reviewed" ? (
                                                        <>
                                                    <button className="view-btn accept-btn" onClick={()=>{handleEditInputChange("appointment_id",item.id);setIsEditOpen(true)}}>Accept</button>
                                                    <button className="view-btn reject-btn" onClick={()=>rejectappointment(item.id)}>Reject</button>
                                                    </>
                                                    ):(null)
                                                }
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {
                            isEditOpen &&
                            <Modal
                                title='Estimate Out'
                                setIsOpen={setIsEditOpen}
                                actionBtn={<button className='form-action-btn' onClick={() => acceptappointment()}>Accept Appointment</button>}
                                style={{ width: '70rem', height: '30rem' }}
                            >
                                {ismodalloading ? (
                                                <div className='loading-gif-container'>
                                                <img src='./../images/spinner.gif' alt='Loading...' />
                                                </div>
                                        ) : (
                                    <>
                                <div className='grid-3-form'>
                                    <div className='form-control'>
                                        <label className='form-control__label'>Estimate Out</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Estimate Out' selected={selectedItem.estimated_out} minDate={todaydate} onChange={(date) => handleEditInputChange('estimated_out', date)} dateFormat="dd/MM/yyyy" onKeyDown={(e) => e.preventDefault()} />
                                    </div>
                                </div>
                                </>
                                )}
                            </Modal>
                        }
                    </div>
                    </>
                    )
                    }
            </section>
        </div>
     
</>
    )
}

export default AppointmentPage;