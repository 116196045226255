import React, { useEffect, useState } from 'react';
import LeftNav from '../components/LeftNav';
import TopPageHeader from '../components/TopPageHeader';
import Modal from '../components/Modal';
import moment from"moment";
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const SetUpVehiclePage = () => {
    const [isAddNewOpen, setIsAddNewOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [postData, setPostData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [companylist,setCompanylist] = useState([]);
    const [searchData, setSearchData] = useState('');
    const [pageno,PageNumber]=useState(1);
    const [ismainloading,setismainloading]=useState(false);
    const [isIdValid, setIsIdValid] = useState(true);
    const [isVehicleNoValid, setIsVehicleNoValid] = useState(true);
    const [isRegistrationNoValid, setIsRegistrationNoValid] = useState(true);
    const [isCompanyIdValid, setIsCompanyIdValid] = useState(true);
    const [isBrandValid, setIsBrandValid] = useState(true);
    const [isEquipmentTypeValid, setIsEquipmentTypeValid] = useState(true);
    const [isModelValid, setIsModelValid] = useState(true);
    const [isLastServiceValid, setIsLastServiceValid] = useState(true);
    const [isNextServiceValid, setIsNextServiceValid] = useState(true);
    const [isLastMileageValid, setIsLastMileageValid] = useState(true);
    const [isNextMileageValid, setIsNextMileageValid] = useState(true);
    const [isRoadTaxExpiryValid, setIsRoadTaxExpiryValid] = useState(true);
    const [isChassisNoValid, setIsChassisNoValid] = useState(true);
    const [isEngineNoValid, setIsEngineNoValid] = useState(true);
    const [isInsuranceExpiryValid, setIsInsuranceExpiryValid] = useState(true);
    const [isNextPuspakomValid, setIsNextPuspakomValid] = useState(true);

    const [ismodalloading,setismodalloading]=useState(false);
    const navigate = useNavigate();

    const todaydate = new Date;
    const handleEditClick = async(item) => {
        setIsEditOpen(true);
        setismodalloading(true);
        setIsVehicleNoValid(true);
        setIsRegistrationNoValid(true);
        setIsCompanyIdValid(true);
        setIsBrandValid(true);
        setIsEquipmentTypeValid(true);
        setIsModelValid(true);
        setIsLastServiceValid(true);
        setIsNextServiceValid(true);
        setIsLastMileageValid(true);
        setIsNextMileageValid(true);
        setIsRoadTaxExpiryValid(true);
        setIsChassisNoValid(true);
        setIsEngineNoValid(true);
        setIsInsuranceExpiryValid(true);
        setIsNextPuspakomValid(true);
        setSelectedItem(item);
        await getcompany();
        setismodalloading(false);
    };
    const searchfunction =(value)=>{
        setSearchData(value);
        getvehiclelist(value,1);
    }
    const AddNew = async() =>{
        setIsAddNewOpen(true);
        setismodalloading(true);
        setIsVehicleNoValid(true);
        setIsRegistrationNoValid(true);
        setIsCompanyIdValid(true);
        setIsBrandValid(true);
        setIsEquipmentTypeValid(true);
        setIsModelValid(true);
        setIsLastServiceValid(true);
        setIsNextServiceValid(true);
        setIsLastMileageValid(true);
        setIsNextMileageValid(true);
        setIsRoadTaxExpiryValid(true);
        setIsChassisNoValid(true);
        setIsEngineNoValid(true);
        setIsInsuranceExpiryValid(true);
        setIsNextPuspakomValid(true);
        clearSelectedItem();
        await getcompany();
        setismodalloading(false);
    };

    const clearSelectedItem = () => {
        setSelectedItem(null);
        setSelectedItem({
            ['company_id']:'',
            ['id']:'',
            ['vehicle_no']: '',
            ['registration_no']: '',
            ['company_id']:'',
            ['brand']:'',
            ['equipment_type']:'',
            ['model']:'',
            ['last_service']:'',
            ['next_service']:'',
            ['road_tax_expiry']: '',
            ['chassis_no']: '',
            ['engine_no']:'',
            ['insurance_expiry']: '',
            ['next_puspakom']:'',
        });
    };

    const previouspage =()=>{
        if (pageno!=1){
            setPageNumber(pageno-1);
        }
    }
    const setPageNumber =async(page)=>{
            setismainloading(true);
            PageNumber(page);
            await getvehiclelist('',page)
            setismainloading(false);

        }

    const getCompanyNameFromId = (companyId) => {
        
        const company = companylist.find(company => company.id === companyId);
        return company.name;
    };

    const getcompany = async() => {
        const formData = new FormData();
        const url = "https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=get-company";
        return await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data } = json;
            if (success == 1) {
                setCompanylist(data);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

        const handleInputChange = (property, value) => {
        if(property=='company_id'){
            if(value==''){
                setSelectedItem({
                    ...selectedItem,
                    [property]: '',
                    ['company_name']: '',
                });
            }else{
            setSelectedItem({
                ...selectedItem,
                [property]: value,
                ['company_name']: getCompanyNameFromId(value),
            });
        }
        }else{
        setSelectedItem({
            ...selectedItem,
            [property]: value,
        });
    }
    };

    const EditSave = async () => {
        const confirmed = window.confirm("Are you sure you want to save changes?");
        if (!confirmed) {
            toast.error("Changes canceled", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        const validateField = (field) => {
            if (typeof field === 'string') {
              return field.trim() !== ''; // Check for non-empty strings
            } else if (typeof field === 'number') {
              return !isNaN(field); // Check for valid numbers
            }
            return false; // Return false for other data types
          };
          
    
        const vehicleNoValid = validateField(selectedItem.vehicle_no);
        const registrationNoValid = validateField(selectedItem.registration_no);
        const companyIdValid = validateField(selectedItem.company_id);
        const brandValid = validateField(selectedItem.brand);
        const equipmentTypeValid = validateField(selectedItem.equipment_type);
        const modelValid = validateField(selectedItem.model);
        const lastServiceValid = validateField(selectedItem.last_service);
        const nextServiceValid = validateField(selectedItem.next_service);
        const lastMileageValid = validateField(selectedItem.last_mileage);
        const nextMileageValid = validateField(selectedItem.next_mileage);
        const roadTaxExpiryValid = validateField(selectedItem.road_tax_expiry);
        const chassisNoValid = validateField(selectedItem.chassis_no);
        const engineNoValid = validateField(selectedItem.engine_no);
        const insuranceExpiryValid = validateField(selectedItem.insurance_expiry);
        const nextPuspakomValid = validateField(selectedItem.next_puspakom);
    
        setIsVehicleNoValid(vehicleNoValid);
        setIsRegistrationNoValid(registrationNoValid);
        setIsCompanyIdValid(companyIdValid);
        setIsBrandValid(brandValid);
        setIsEquipmentTypeValid(equipmentTypeValid);
        setIsModelValid(modelValid);
        setIsLastServiceValid(lastServiceValid);
        setIsNextServiceValid(nextServiceValid);
        setIsLastServiceValid(lastMileageValid);
        setIsNextServiceValid(nextMileageValid);
        setIsRoadTaxExpiryValid(roadTaxExpiryValid);
        setIsChassisNoValid(chassisNoValid);
        setIsEngineNoValid(engineNoValid);
        setIsInsuranceExpiryValid(insuranceExpiryValid);
        setIsNextPuspakomValid(nextPuspakomValid);
    
        if (!vehicleNoValid || !registrationNoValid || !companyIdValid || !brandValid || 
            !equipmentTypeValid || !modelValid || !lastServiceValid || !nextServiceValid || !lastMileageValid || !nextMileageValid || 
            !roadTaxExpiryValid || !chassisNoValid || !engineNoValid || 
            !insuranceExpiryValid || !nextPuspakomValid) {
            toast.error("Please fill out all fields correctly", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=edit-customer-vehicle";
        formData.append('id', selectedItem.id);
        formData.append('vehicle_no', selectedItem.vehicle_no);
        formData.append('registration_no', selectedItem.registration_no);
        formData.append('company_id', selectedItem.company_id);
        formData.append('brand', selectedItem.brand);
        formData.append('equipment_type', selectedItem.equipment_type);
        formData.append('model', selectedItem.model);
        formData.append('last_service', moment(selectedItem.last_service).format('YYYY-MM-DD'));
        formData.append('next_service', moment(selectedItem.next_service).format('YYYY-MM-DD'));
        formData.append('last_mileage', selectedItem.last_mileage);
        formData.append('next_mileage', selectedItem.next_mileage);
        formData.append('road_tax_expiry', moment(selectedItem.road_tax_expiry).format('YYYY-MM-DD'));
        formData.append('chassis_no', selectedItem.chassis_no);
        formData.append('engine_no', selectedItem.engine_no);
        formData.append('insurance_expiry', moment(selectedItem.insurance_expiry).format('YYYY-MM-DD'));
        formData.append('next_puspakom', moment(selectedItem.next_puspakom).format('YYYY-MM-DD'));
        return await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                toast.success("Changes completed", {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            setIsEditOpen(false);
                            getvehiclelist('',1);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
        
    
    };

    const addnewvehicle = async() =>{
        const confirmed = window.confirm("Are you sure you want to save changes?");
        if (!confirmed) {
            toast.error("Customer Create Canceled", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
    
        const validateField = (field) => field && field.trim() !== '';
    
        const vehicleNoValid = validateField(selectedItem.vehicle_no);
        const registrationNoValid = validateField(selectedItem.registration_no);
        const companyIdValid = validateField(selectedItem.company_id);
        const brandValid = validateField(selectedItem.brand);
        const equipmentTypeValid = validateField(selectedItem.equipment_type);
        const modelValid = validateField(selectedItem.model);
        const lastServiceValid = validateField(selectedItem.last_service);
        const nextServiceValid = validateField(selectedItem.next_service);
        const lastMileageValid = validateField(selectedItem.last_mileage);
        const nextMileageValid = validateField(selectedItem.next_mileage);
        const roadTaxExpiryValid = validateField(selectedItem.road_tax_expiry);
        const chassisNoValid = validateField(selectedItem.chassis_no);
        const engineNoValid = validateField(selectedItem.engine_no);
        const insuranceExpiryValid = validateField(selectedItem.insurance_expiry);
        const nextPuspakomValid = validateField(selectedItem.next_puspakom);
    
        setIsVehicleNoValid(vehicleNoValid);
        setIsRegistrationNoValid(registrationNoValid);
        setIsCompanyIdValid(companyIdValid);
        setIsBrandValid(brandValid);
        setIsEquipmentTypeValid(equipmentTypeValid);
        setIsModelValid(modelValid);
        setIsLastServiceValid(lastServiceValid);
        setIsNextServiceValid(nextServiceValid);
        setIsLastServiceValid(lastMileageValid);
        setIsNextServiceValid(nextMileageValid);
        setIsRoadTaxExpiryValid(roadTaxExpiryValid);
        setIsChassisNoValid(chassisNoValid);
        setIsEngineNoValid(engineNoValid);
        setIsInsuranceExpiryValid(insuranceExpiryValid);
        setIsNextPuspakomValid(nextPuspakomValid);
    
        if (!vehicleNoValid || !registrationNoValid || !companyIdValid || !brandValid || 
            !equipmentTypeValid || !modelValid || !lastServiceValid || !nextServiceValid || !lastMileageValid || !nextMileageValid || 
            !roadTaxExpiryValid || !chassisNoValid || !engineNoValid || 
            !insuranceExpiryValid || !nextPuspakomValid) {
            toast.error("Please fill out all fields correctly", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=add-customer-vehicle";
        formData.append('vehicle_no', selectedItem.vehicle_no);
        formData.append('registration_no', selectedItem.registration_no);
        formData.append('company_id', selectedItem.company_id);
        formData.append('brand', selectedItem.brand);
        formData.append('equipment_type', selectedItem.equipment_type);
        formData.append('model', selectedItem.model);
        formData.append('last_service', moment(selectedItem.last_service).format('YYYY-MM-DD'));
        formData.append('next_service', moment(selectedItem.next_service).format('YYYY-MM-DD'));
        formData.append('last_mileage', selectedItem.last_mileage);
        formData.append('next_mileage', selectedItem.next_mileage);
        formData.append('road_tax_expiry', moment(selectedItem.road_tax_expiry).format('YYYY-MM-DD'));
        formData.append('chassis_no', selectedItem.chassis_no);
        formData.append('engine_no', selectedItem.engine_no);
        formData.append('insurance_expiry', moment(selectedItem.insurance_expiry).format('YYYY-MM-DD'));
        formData.append('next_puspakom', moment(selectedItem.next_puspakom).format('YYYY-MM-DD'));
        return await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                toast.success("Vehicle Add Completed", {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            setIsAddNewOpen(false);
                            getvehiclelist('',1);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

    const getvehiclelist = async(searchvalue,page) => {
        
        let offset=(page-1)*20;
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=get-customer-vehicle";
        formData.append('searchword', searchvalue);
        formData.append('offset',String(offset));
        return await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                setPostData(data);
                return postData.map((item, index) => (
                    <tr key={index}>
                        <td>{item.vehicle_no}</td>
                        <td>{item.equipment_type}</td>
                        <td>{item.company_name}</td>
                        <td>{item.registration_no}</td>
                        <td>{item.brand}</td>
                        <td>{item.last_service}</td>
                        <td>
                        <button className='view-btn' onClick={() => handleEditClick(item)}>View details</button>
            </td>
        </tr>
                )
            )
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

    useEffect(() => {
        setPageNumber(1);
    }, []);

    return (
            <div className='main-content'>
                <TopPageHeader title='Vehicle' />

                <section className='content-area'>
                    <header className='content-area__header'>
                        <button className='content-area__header--btn' onClick={() => AddNew()}>+ Add New Vechicle</button>
                        {
                            isAddNewOpen &&
                            <Modal
                                title='Add New Vehicle'
                                setIsOpen={setIsAddNewOpen}
                                actionBtn={ismodalloading ? (null):(<button className='form-action-btn' onClick={async()=>{setismodalloading(true); await addnewvehicle(); setismodalloading(false)}}>Save</button>)}
                            >
                                {ismodalloading ? (
                                                <div className='loading-gif-container'>
                                                <img src='./../images/spinner.gif' alt='Loading...' />
                                                </div>
                                        ) : (
                                <div className='grid-3-form'>
                                    
                                        <div className='form-control'>
                                        <label className='form-control__label'>Reg No.</label>
                                        <input className='form-control__input' type="text" placeholder=' Reg No.' value={selectedItem.registration_no} onChange={(e) => handleInputChange('registration_no', e.target.value)} />
                                        {!isRegistrationNoValid && (
                                            <label className='form-control__error'>Please key in Reg No.</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Road Tax Expiry</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Road Tax Expiry' selected={selectedItem.road_tax_expiry} minDate={todaydate} onChange={(date) => handleInputChange('road_tax_expiry', date)} dateFormat="dd/MM/yyyy" onKeyDown={(e) => e.preventDefault()} />
                                        {!isRoadTaxExpiryValid && (
                                            <label className='form-control__error'>Please key in Road Tax Expiry</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Company Name</label>
                                        <select className='form-control__input' placeholder='Company Name' value={selectedItem.company_id || "" } onChange={(e) => handleInputChange('company_id', e.target.value)}>
                                        <option value="" >Select a company</option>
                                        {companylist.map((company) => (
                                            <option key={company.id} value={company.id}>
                                                {company.name}
                                            </option>
                                        ))}
                                        </select>
                                        {!isCompanyIdValid && (
                                            <label className='form-control__error'>Please select a Company Name</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Vehicle No.</label>
                                        <input className='form-control__input' type="text" placeholder='Vehicle No.' value={selectedItem.vehicle_no} onChange={(e) => handleInputChange('vehicle_no', e.target.value)} />
                                        {!isVehicleNoValid && (
                                            <label className='form-control__error'>Please key in Vehicle No.</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Next Puspakom</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Next Puspakom' selected={selectedItem.next_puspakom} minDate={todaydate} onChange={(date) => handleInputChange('next_puspakom', date)} dateFormat="dd/MM/yyyy" onKeyDown={(e) => e.preventDefault()} />
                                        {!isNextPuspakomValid && (
                                            <label className='form-control__error'>Please key in Next Puspakom</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Equipment Type</label>
                                        <select className='form-control__input' placeholder='Equipment Type' value={selectedItem.equipment_type || "" } onChange={(e) => handleInputChange('equipment_tyoe', e.target.value)}>
                                        <option value="" >Select a Equipment Type</option>
                                        <option key={"Prime Mover"} value={"Prime Mover"}>
                                                {"Prime Mover"}
                                            </option>
                                        <option key={"20 Trailer"} value={"20 Trailer"}>
                                            {"20 Trailer"}
                                        </option>
                                        <option key={"40 Trailer"} value={"40 Trailer"}>
                                                {"40 Trailer"}
                                            </option>
                                        </select>
                                        {!isEquipmentTypeValid && (
                                            <label className='form-control__error'>Please key in Equipment Type</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Chasis No.</label>
                                        <input className='form-control__input' type="text" placeholder='Chasis No.' value={selectedItem.chassis_no} onChange={(e) => handleInputChange('chassis_no', e.target.value)} />
                                        {!isChassisNoValid && (
                                            <label className='form-control__error'>Please key in Chassis No.</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Last Service</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Last Service' selected={selectedItem.last_service} minDate={todaydate} onChange={(date) => handleInputChange('last_service', date)} dateFormat="dd/MM/yyyy" onKeyDown={(e) => e.preventDefault()} />
                                        {!isLastServiceValid && (
                                            <label className='form-control__error'>Please key in Last Service</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Last Mileage</label>
                                        <input className='form-control__input' type="number" placeholder='Last Mileage' selected={selectedItem.last_mileage} onChange={(e) => handleInputChange('last_mileage', e.target.value)} />
                                        {!isLastMileageValid && (
                                            <label className='form-control__error'>Please key in Last Mileage</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Engine No.</label>
                                        <input className='form-control__input' type="text" placeholder='Engine No.' value={selectedItem.engine_no} onChange={(e) => handleInputChange('engine_no', e.target.value)} />
                                        {!isEngineNoValid && (
                                            <label className='form-control__error'>Please key in Engine No.</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Next Service</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Last Service' selected={selectedItem.next_service} minDate={todaydate} onChange={(date) => handleInputChange('next_service', date)} dateFormat="dd/MM/yyyy" onKeyDown={(e) => e.preventDefault()} />
                                        {!isNextServiceValid && (
                                            <label className='form-control__error'>Please key in Next Service</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Next Mileage</label>
                                        <input className='form-control__input' type="number" placeholder='Next Mileage' selected={selectedItem.next_mileage} onChange={(e) => handleInputChange('next_mileage', e.target.value)} />
                                        {!isNextMileageValid && (
                                            <label className='form-control__error'>Please key in Next Mileage</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Brand</label>
                                        <input className='form-control__input' type="text" placeholder='Brand' value={selectedItem.brand} onChange={(e) => handleInputChange('brand', e.target.value)} />
                                        {!isBrandValid && (
                                            <label className='form-control__error'>Please key in Brand</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Model</label>
                                        <input className='form-control__input' type="text" placeholder='Model' value={selectedItem.model} onChange={(e) => handleInputChange('model', e.target.value)} />
                                        {!isModelValid && (
                                            <label className='form-control__error'>Please key in Model</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Insurance Expiry</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Insurance Expiry' selected={selectedItem.insurance_expiry} minDate={todaydate} onChange={(date) => handleInputChange('insurance_expiry', date)} dateFormat="dd/MM/yyyy" onKeyDown={(e) => e.preventDefault()} />
                                        {!isInsuranceExpiryValid && (
                                            <label className='form-control__error'>Please key in Insurance Expiry</label>
                                        )}
                                    </div>
                                </div>
                                        )
                                        }
                            </Modal>
                        }

                        <div>
                            <div className='content-area__header--search'>
                                <img className='search-icon' src='./../images/search-icon.png' alt='search icon' />
                                <input className='search-input' type="text" placeholder='Search' value={searchData} onChange={(e) =>{searchfunction(e.target.value)}}/>
                            </div>

                            <nav className='content-area__header--pagination'>
                                <button className='pagination-btn nav' onClick={previouspage}>&lt;</button>
                                <button className={`pagination-btn ${pageno === 1 ? 'active' : ''}`} onClick={() => setPageNumber(1)}>1</button>
                                <button className={`pagination-btn ${pageno === 2 ? 'active' : ''}`} onClick={() => setPageNumber(2)}>2</button>
                                <button className={`pagination-btn ${pageno === 3 ? 'active' : ''}`} onClick={() => setPageNumber(3)}>3</button>
                                <button className='pagination-btn'>...</button>
                                <button className={`pagination-btn ${pageno === 10 ? 'active' : ''}`} onClick={() => setPageNumber(10)}>10</button>
                                <button className='pagination-btn nav' onClick={() => setPageNumber(pageno+1)}>&gt;</button>
                            </nav>
                        </div>
                    </header>
                    {ismainloading ? (
                        <div className='loading-screen'>
                        <img src='./../images/spinner.gif' alt='Loading...' />
                        </div>
                    ) : (
                    <>
                    <div className='content-box'>
                        <div className='data-table scroll-sm'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Vehicle No.</th>
                                        <th>Equipment Type</th>
                                        <th>Customer Name</th>
                                        <th>Vehicle Registration No.</th>
                                        <th>Model</th>
                                        <th>Last Service</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                {postData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.vehicle_no}</td>
                                        <td>{item.equipment_type}</td>
                                        <td>{item.company_name}</td>
                                        <td>{item.registration_no}</td>
                                        <td>{item.brand}</td>
                                        <td>{item.last_service}</td>
                                        <td>
                                        <button className='view-btn' onClick={() => handleEditClick(item)}>View details</button>
                                    </td>
                                    </tr>
                                    )
                                    )}
                                </tbody>
                            </table>
                        </div>

                        {
                            isEditOpen &&
                            <Modal
                                title=' Vehicle Details'
                                setIsOpen={setIsEditOpen}
                                actionBtn={ismodalloading ? (null):(<button className='form-action-btn' onClick={async()=>{setismodalloading(true); await EditSave(); setismodalloading(false)}}>Save</button>)}
                            >
                                {ismodalloading ? (
                                                <div className='loading-gif-container'>
                                                <img src='./../images/spinner.gif' alt='Loading...' />
                                                </div>
                                        ) : (
                                <div className='grid-3-form'>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Reg No.</label>
                                        <input className='form-control__input' type="text" placeholder=' Reg No.' value={selectedItem.registration_no} onChange={(e) => handleInputChange('registration_no', e.target.value)} />
                                        {!isRegistrationNoValid && (
                                            <label className='form-control__error'>Please key in Reg No.</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Road Tax Expiry</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Road Tax Expiry' selected={selectedItem.road_tax_expiry} minDate={todaydate} onChange={(date) => handleInputChange('road_tax_expiry', date)} dateFormat="dd/MM/yyyy" onKeyDown={(e) => e.preventDefault()}  />
                                        {!isRoadTaxExpiryValid && (
                                            <label className='form-control__error'>Please key in Road Tax Expiry</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Company Name</label>
                                        <select className='form-control__input' placeholder='Company Name' value={selectedItem.company_id || "" } onChange={(e) => handleInputChange('company_id', e.target.value)}>
                                        <option value="" >Select a company</option>
                                        {companylist.map((company) => (
                                            <option key={company.id} value={company.id}>
                                                {company.name}
                                            </option>
                                        ))}
                                        </select>
                                        {!isCompanyIdValid && (
                                            <label className='form-control__error'>Please select a Company Name</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Vehicle No.</label>
                                        <input className='form-control__input' type="text" placeholder='Vehicle No.' value={selectedItem.vehicle_no} onChange={(e) => handleInputChange('vehicle_no', e.target.value)} />
                                        {!isVehicleNoValid && (
                                            <label className='form-control__error'>Please key in Vehicle No.</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Next Puspakom</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Next Puspakom' selected={selectedItem.next_puspakom} minDate={todaydate} onChange={(date) => handleInputChange('next_puspakom', date)} dateFormat="dd/MM/yyyy" onKeyDown={(e) => e.preventDefault()} />
                                        {!isNextPuspakomValid && (
                                            <label className='form-control__error'>Please key in Next Puspakom</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Equipment Type</label>
                                        <select className='form-control__input' placeholder='Equipment Type' value={selectedItem.equipment_type || "" } onChange={(e) => handleInputChange('equipment_tyoe', e.target.value)}>
                                        <option value="" >Select a Equipment Type</option>
                                        <option key={"Prime Mover"} value={"Prime Mover"}>
                                                {"Prime Mover"}
                                            </option>
                                        <option key={"20 Trailer"} value={"20 Trailer"}>
                                            {"20 Trailer"}
                                        </option>
                                        <option key={"40 Trailer"} value={"40 Trailer"}>
                                                {"40 Trailer"}
                                            </option>
                                        </select>
                                        {!isEquipmentTypeValid && (
                                            <label className='form-control__error'>Please key in Equipment Type</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Chasis No.</label>
                                        <input className='form-control__input' type="text" placeholder='Chasis No.' value={selectedItem.chassis_no} onChange={(e) => handleInputChange('chassis_no', e.target.value)} />
                                        {!isChassisNoValid && (
                                            <label className='form-control__error'>Please key in Chassis No.</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Last Service</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Last Service' selected={selectedItem.last_service} minDate={todaydate} onChange={(date) => handleInputChange('last_service', date)} dateFormat="dd/MM/yyyy" onKeyDown={(e) => e.preventDefault()} />
                                        {!isLastServiceValid && (
                                            <label className='form-control__error'>Please key in Last Service</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Last Mileage</label>
                                        <input className='form-control__input' type="number" placeholder='Last Mileage' selected={selectedItem.last_mileage} onChange={(e) => handleInputChange('last_mileage', e.target.value)} />
                                        {!isLastMileageValid && (
                                            <label className='form-control__error'>Please key in Last Mileage</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Engine No.</label>
                                        <input className='form-control__input' type="text" placeholder='Engine No.' value={selectedItem.engine_no} onChange={(e) => handleInputChange('engine_no', e.target.value)} />
                                        {!isEngineNoValid && (
                                            <label className='form-control__error'>Please key in Engine No.</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Next Service</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Next Service' selected={selectedItem.next_service} minDate={todaydate} onChange={(date) => handleInputChange('next_service', date)} dateFormat="dd/MM/yyyy" onKeyDown={(e) => e.preventDefault()} />
                                        {!isNextServiceValid && (
                                            <label className='form-control__error'>Please key in Next Service</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Next Mileage</label>
                                        <input className='form-control__input' type="number" placeholder='Next Mileage' selected={selectedItem.next_mileage} onChange={(e) => handleInputChange('next_mileage', e.target.value)} />
                                        {!isNextMileageValid && (
                                            <label className='form-control__error'>Please key in Next Mileage</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Brand</label>
                                        <input className='form-control__input' type="text" placeholder='Brand' value={selectedItem.brand} onChange={(e) => handleInputChange('brand', e.target.value)} />
                                        {!isBrandValid && (
                                            <label className='form-control__error'>Please key in Brand</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Model</label>
                                        <input className='form-control__input' type="text" placeholder='Model' value={selectedItem.model} onChange={(e) => handleInputChange('model', e.target.value)} />
                                        {!isModelValid && (
                                            <label className='form-control__error'>Please key in Model</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Insurance Expiry</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Insurance Expiry' selected={selectedItem.insurance_expiry} minDate={todaydate} onChange={(date) => handleInputChange('insurance_expiry', date)} dateFormat="dd/MM/yyyy" onKeyDown={(e) => e.preventDefault()} />
                                        {!isInsuranceExpiryValid && (
                                            <label className='form-control__error'>Please key in Insurance Expiry</label>
                                        )} 
                                    </div>
                                </div>
                                        )
                                        }
                            </Modal>
                        }
                    </div>
                    </>
                    )
                    }
                </section>
            </div>
    )
}

export default SetUpVehiclePage;