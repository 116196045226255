import React, { useEffect, useState, useRef } from 'react';
import TopPageHeader from '../components/TopPageHeader';
import Modal from '../components/Modal';
import Multiselect from 'multiselect-react-dropdown';
import { NumberInput } from '../components/Inputs';
import SignatureCanvas from 'react-signature-canvas'
import moment from 'moment';
import { useReactMediaRecorder } from "react-media-recorder-2";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AsyncCompiler } from 'sass';
import Moment from 'react-moment';

function IndustryNewsPage() {
    const [isAddNewOpen, setIsAddNewOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [postData, setPostData] = useState([]);
    const [pageno,PageNumber]=useState(1);
    const [companylist,setCompanylist] = useState([]);
    const [vehiclelist,setVehiclelist] = useState([]);
    const [customerlist,setCustomerlist] = useState([]);
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const [ismainloading,setismainloading]=useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [servicelist, setservicelist]=useState([]);
    const [ismodalloading,setismodalloading]=useState(false);
    const [productImage, setProductImage] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [searchData, setSearchData] = useState('');
    const [todaydate, settodaydate]=useState(new Date);
    const [filterVisible, setFilterVisible] = React.useState({
        all: true,
        booked: true,
        cancelled: true,
        completed: true,
    });

    const [activeFilter, setActiveFilter] = useState('all');
      
    const handleFilterClick = (filter) => {
      setActiveFilter(filter);
    };

    const handleEditInputChange = (property, value) => {
        setSelectedItem({
            ...selectedItem,
            [property]: value,
        });
    };
    
    const initialise = () =>{
        setSelectedItem(
            {
                title:'',
                content:'',
                picurl:'',
                report_date:'',
            }
        )
    }

    const previouspage =()=>{
        if (pageno!=1){
            setPageNumber(pageno-1);
        }
    }

    const setPageNumber =async(page)=>{
        PageNumber(page);
    }

    useEffect(()=>{
        getlist(pageno);    
    },[pageno,searchData])
    
    const getlist = async(page) => {
        setismainloading(true);
        await getnewslist(page);
        setismainloading(false);
    }

    const searchfunction =async(value)=>{
        setismainloading(true);
        setSearchData(value);
        setismainloading(false);
    }

    useEffect(() => {
        getnewslist(pageno);
    }, []);

    useEffect(() => {
        setSelectedImage(null);
    }, [isAddNewOpen,isEditOpen]);

    const handleUploadClick = () => {
        if (fileInputRef.current) {
          fileInputRef.current.click(); // Trigger the file input click
        }
      };
    
      const handleFileChange = (event) => {
        const files = event.target.files;
        if (files && files.length > 0) {
          const file = files[0];
          console.log('Selected file:', file);
          setSelectedImage(file);
          const reader = new FileReader();
          reader.onloadend = () => {
            setProductImage(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };

    const acceptappointment = async() => {
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=approve-appointment";
        formData.append('appointment_id', selectedItem.appointment_id);
        formData.append('estimated_out', selectedItem.estimated_out);
        return await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                toast.success("Appointment Accepted", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                getnewslist(pageno);
                setIsEditOpen(false);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

    const getnewslist = async(page) => {
        let offset=(page-1)*20;
        const formData = new FormData();
        if(searchData!=''){
            formData.append('searchword',String(searchData));
        }
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=get-news";
        formData.append('offset',String(offset));
        return await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                setPostData(data);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

   const addnews = async () => {
        if (selectedItem.title.trim() !=='' && selectedItem.content.trim() !=='' ) {
        const formData = new FormData();
            const url = "https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=add-news";
            formData.append('title', selectedItem.title);
            formData.append('content', selectedItem.content);
            formData.append('repodate', selectedItem.report_date);
            if (selectedImage) {
                formData.append('image', selectedImage);
                formData.append('imageurl', '');
              }
              else{
                formData.append('imageurl', selectedItem.image);
              }
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': sessionStorage.getItem('token'),
                        'Access-Control-Allow-Credentials': true,
                        'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
                    },
                    body: formData
                });
        
                if (!response.ok) {
                    if (response.status === 401) {
                        sessionStorage.clear();
                        navigate('/login');
                    } else {
                        throw new Error('API Failed');
                    }
                }
                
                let json;
                try {
                    json = await response.json();
                } catch (e) {
                    throw new Error('Failed to parse JSON');
                }
                const { success, data } = json;
    
                if (success == 1) {
                    toast.success("Successfully Add News", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setIsAddNewOpen(false);
                    getnewslist(pageno);
                    setSelectedItem(null);
                }
            } catch (error) {
                console.error('Server Connection Failed:', error);
            }
        }else{
            toast.success("Please Complete the form", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        
    }

    const EditNews = async (id) => {
        if (selectedItem.title.trim() !=='' && selectedItem.content.trim() !=='' ) {
        const formData = new FormData();
            const url = "https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=update-news";
            formData.append('id', id);
            formData.append('title', selectedItem.title);
            formData.append('content', selectedItem.content);
            formData.append('repodate', selectedItem.report_date);
            if (selectedImage) {
                formData.append('image', selectedImage);
              }
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': sessionStorage.getItem('token'),
                        'Access-Control-Allow-Credentials': true,
                        'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
                    },
                    body: formData
                });
        
                if (!response.ok) {
                    if (response.status === 401) {
                        sessionStorage.clear();
                        navigate('/login');
                    } else {
                        throw new Error('API Failed');
                    }
                }
                
                let json;
                try {
                    json = await response.json();
                } catch (e) {
                    throw new Error('Failed to parse JSON');
                }
                const { success, data } = json;
    
                if (success == 1) {
                    toast.success("Successfully Edit News", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setIsEditOpen(false);
                    getnewslist(pageno);
                    setSelectedItem(null);
                }
            } catch (error) {
                console.error('Server Connection Failed:', error);
            }
        }else{
            toast.success("Please Complete the form", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        
    }
    const deletenews = async (id) => {
        const formData = new FormData();
            const url = "https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=delete-news";
            formData.append('id', id);
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': sessionStorage.getItem('token'),
                        'Access-Control-Allow-Credentials': true,
                        'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
                    },
                    body: formData
                });
        
                if (!response.ok) {
                    if (response.status === 401) {
                        sessionStorage.clear();
                        navigate('/login');
                    } else {
                        throw new Error('API Failed');
                    }
                }
                
                let json;
                try {
                    json = await response.json();
                } catch (e) {
                    throw new Error('Failed to parse JSON');
                }
                const { success, data } = json;
    
                if (success == 1) {
                    toast.success("Successfully Delete News", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setIsEditOpen(false);
                    getnewslist(pageno);
                    setSelectedItem(null);
                }
            } catch (error) {
                console.error('Server Connection Failed:', error);
            }
        
        
    }

    return (
        <>
        <div className='main-content'>
            <TopPageHeader title='Manage Appointment' />

            <section className='content-area'>
                
                <header className='content-area__header'>
                    <div>
                        <button className='content-area__header--btn' onClick={async() => {setismodalloading(true); initialise();setIsAddNewOpen(true);setismodalloading(false)}}>+ Add News</button>

                        {
                            isAddNewOpen &&
                            <Modal
                                title='Add New News'
                                setIsOpen={setIsAddNewOpen}
                                actionBtn={<button className='form-action-btn' onClick={async()=>{setismodalloading(true);await addnews();setismodalloading(false)}}>Add News</button>}
                                modelStyle='light-center'
                                style={{ width: '100rem', height: '45rem' }}
                            >
                            {ismodalloading ? (
                                                <div className='loading-gif-container'>
                                                <img src='./../images/spinner.gif' alt='Loading...' />
                                                </div>
                                        ) : (
                                <div className='grid-3-form'>
                                    <div className='form-control'>
                                        <label className='form-control__label'>Report Date</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='report date' selected={selectedItem.report_date} minDate={todaydate} onChange={(date) => handleEditInputChange('report_date', date)} dateFormat="dd/MM/yyyy" onKeyDown={(e) => e.preventDefault()} />
                                    </div>
                                <div className='form-control'>
                                <label className='form-control__label'>Picture Upload</label>
                                <button
                                    onClick={handleUploadClick}
                                    style={{
                                    backgroundColor: '#FFE9FD',
                                    border: '2px solid #FF008E',
                                    borderRadius: '10px',
                                    padding: '10px 20px',
                                    color: '#FF008E',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    transition: 'background-color 0.3s',
                                    }}
                                    onMouseOver={(e) => (e.target.style.backgroundColor = '#FF008E')}
                                    onMouseOut={(e) => (e.target.style.backgroundColor = '#FFE9FD')}
                                >
                                    Upload File
                                </button>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                />
                                    </div>
                                        <div className='form-control'>
                                        <label className='form-control__label'>Title</label>
                                        <input className='form-control__input' value={selectedItem.title || ""} onChange={(e)=>handleEditInputChange('title', e.target.value)}/>
                                    </div>
                                    <div className='form-control'>
                                        <label className='form-control__label'>Content</label>
                                        <input className='form-control__input'style={{height: '100px'}} value={selectedItem.content || ""} onChange={(e)=>handleEditInputChange('content', e.target.value)}/>
                                    </div>
                                </div>
                                        )
                                        }
                            </Modal>
                        }

                        {/*<div className="content-area__header--filter-row">
                            <label className="filter-row-label">Filter by :</label>
                            {filterVisible.all && (
                                <button
                                className={`filter-row-btn ${activeFilter === 'all' ? 'active' : ''}`}
                                onClick={() => handleFilterClick('all')}
                                >
                                All
                                </button>
                            )}
                            {filterVisible.booked && (
                                <button
                                className={`filter-row-btn ${activeFilter === 'booked' ? 'active' : ''}`}
                                onClick={() => handleFilterClick('booked')}
                                >
                                Booked
                                </button>
                            )}
                            {filterVisible.cancelled && (
                                <button
                                className={`filter-row-btn ${activeFilter === 'cancelled' ? 'active' : ''}`}
                                onClick={() => handleFilterClick('cancelled')}
                                >
                                Cancelled
                                </button>
                            )}
                            {filterVisible.completed && (
                                <button
                                className={`filter-row-btn ${activeFilter === 'completed' ? 'active' : ''}`}
                                onClick={() => handleFilterClick('completed')}
                                >
                                Completed
                                </button>
                            )}
                        </div>*/}
                    </div>

                    <div>
                        <div className='content-area__header--search'>
                                <img className='search-icon' src='./../images/search-icon.png' alt='search icon' />
                                <input className='search-input' type="text" placeholder='Search' value={searchData} onChange={(e) =>{searchfunction(e.target.value)}}/>
                            </div>

                        <nav className='content-area__header--pagination'>
                                <button className='pagination-btn nav' onClick={previouspage}>&lt;</button>
                                <button className={`pagination-btn ${pageno === 1 ? 'active' : ''}`} onClick={() => setPageNumber(1)}>1</button>
                                <button className={`pagination-btn ${pageno === 2 ? 'active' : ''}`} onClick={() => setPageNumber(2)}>2</button>
                                <button className={`pagination-btn ${pageno === 3 ? 'active' : ''}`} onClick={() => setPageNumber(3)}>3</button>
                                <button className='pagination-btn'>...</button>
                                <button className={`pagination-btn ${pageno === 10 ? 'active' : ''}`} onClick={() => setPageNumber(10)}>10</button>
                                <button className='pagination-btn nav' onClick={() => setPageNumber(pageno+1)}>&gt;</button>
                            </nav>

                        
                    </div>
                </header>
                {ismainloading ? (
                        <div className='loading-screen'>
                        <img src='./../images/spinner.gif' alt='Loading...' />
                        </div>
                    ) : (
                    <>
                    <div className='content-box'>
                        <div className='data-table scroll-sm'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Picture</th>
                                        <th>Created Date</th>
                                        <th>Public Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                {postData.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.title}</td>
                                            <td><img
                                                src={item.picurl}
                                                alt={`Image ${index + 1}`}
                                                style={{ maxWidth: "100px", maxHeight: "100px", objectFit: "cover" }}
                                            /></td>
                                            <td>
                                                {moment(item.created_at).format("DD/MM/YYYY")}
                                                <br />
                                                {moment(item.created_at).format("hh:mm:ss A")}
                                            </td>
                                            <td>{item.report_date}</td>
                                            <td>
                                                <div className="action-buttons">
                                                    <button className="view-btn" onClick={()=>{setSelectedItem(item);setIsEditOpen(true)}}>Edit</button>
                                                    <button className="view-btn" onClick={()=>deletenews(item.id)}><i className="fa fa-trash"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {
                            isEditOpen &&
                            <Modal
                                title='Edit News'
                                setIsOpen={setIsEditOpen}
                                actionBtn={<button className='form-action-btn' onClick={async() => {setismodalloading(true);await EditNews(selectedItem.id);setismodalloading(false)}}>Edit News</button>}
                                style={{ width: '100rem', height: '45rem' }}
                            >
                                {ismodalloading ? (
                                                <div className='loading-gif-container'>
                                                <img src='./../images/spinner.gif' alt='Loading...' />
                                                </div>
                                        ) : (
                                            <div className='grid-3-form'>
                                            <div className='form-control'>
                                                <label className='form-control__label'>Report Date</label>
                                                <DatePicker className='form-control__input datepicker' placeholder='report date' selected={selectedItem.report_date} minDate={todaydate} onChange={(date) => handleEditInputChange('report_date', date)} dateFormat="dd/MM/yyyy" onKeyDown={(e) => e.preventDefault()} />
                                            </div>
                                        <div className='form-control'>
                                        <label className='form-control__label'>Picture Upload</label>
                                        <button
                                            onClick={handleUploadClick}
                                            style={{
                                            backgroundColor: '#FFE9FD',
                                            border: '2px solid #FF008E',
                                            borderRadius: '10px',
                                            padding: '10px 20px',
                                            color: '#FF008E',
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            transition: 'background-color 0.3s',
                                            }}
                                            onMouseOver={(e) => (e.target.style.backgroundColor = '#FF008E')}
                                            onMouseOut={(e) => (e.target.style.backgroundColor = '#FFE9FD')}
                                        >
                                            Upload File
                                        </button>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleFileChange}
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                        />
                                            </div>
                                                <div className='form-control'>
                                                <label className='form-control__label'>Title</label>
                                                <input className='form-control__input' value={selectedItem.title || ""} onChange={(e)=>handleEditInputChange('title', e.target.value)}/>
                                            </div>
                                            <div className='form-control'>
                                                <label className='form-control__label'>Content</label>
                                                <textarea className='form-control__input' style={{height: '150px'}} value={selectedItem.content || ""} onChange={(e)=>handleEditInputChange('content', e.target.value)}/>
                                            </div>
                                        </div>
                                                )
                                                }
                            </Modal>
                        }
                    </div>
                    </>
                    )
                    }
            </section>
        </div>
     
</>
    )
}

export default IndustryNewsPage;