import React, { useEffect, useState } from 'react';
import TopPageHeader from '../components/TopPageHeader';
import Modal from '../components/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'

const SetUpCompanyPage = () => {
    const [isAddNewOpen, setIsAddNewOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [postData, setPostData] = useState([]);
    const [searchData, setSearchData] = useState('');
    const [pageno,PageNumber]=useState(1);
    const [ismainloading,setismainloading]=useState(false);
    const [isnameexist,setisnameexist]=useState(true);
    const [isphoneexist,setisphoneexist]=useState(true);
    const [isemailexist,setisemailexist]=useState(true);
    const [isaddressexist,setisaddressexist]=useState(true);
    const [ismodalloading,setismodalloading]=useState(false);
    const navigate = useNavigate();
   
    const handleEditInputChange = (property, value) => {
        setSelectedItem({
            ...selectedItem,
            [property]: value,
        });
    };

    const validateEmail = (email) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if(regex.test(email)==true){
            setisemailexist(true);
        }else{
            setisemailexist(false);
        }
    };

const validatePhone = (phone) => {
    const malaysiaPhoneRegex = /^(?:\+?60|0)(1[0-9]{1}[0-9]{6,7}|[3-9][0-9]{7,8})$/;
    if (malaysiaPhoneRegex.test(phone)==true) {
      setisphoneexist(true);
    } else {
      setisphoneexist(false);
    }
  };

    const clearSelectedItem = () => {
        setSelectedItem({
            name:'',
            phone:'',
            email:'',
            address:'',
        })
    };

    

    const getcompany = async(searchvalue,page) => {
        const formData = new FormData();
        let offset=(page-1)*20;
        const url = "https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=get-company";
        formData.append('offset',String(offset));
        return await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body:formData,
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data } = json;
            if (success == 1) {
                setPostData(data);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };
    
    const AddNew = () =>{
        setisnameexist(true);
        setisphoneexist(true);
        setisemailexist(true);
        setisaddressexist(true);
        clearSelectedItem();
        setIsAddNewOpen(true);
    };

       
    const addnewcompany = async() => {
        const confirmed = window.confirm("Are you sure you want to save changes?");
        if (!confirmed) {
            toast.error("Company Create Canceled", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
    
        // Validate fields
        const validateField = (field) => field.trim() !== '';
    
        const isNameValid = validateField(selectedItem.name);
        const isAddressValid = validateField(selectedItem.address);
    
        setisnameexist(isNameValid);
        setisaddressexist(isAddressValid);
    
        if (!isNameValid || !isphoneexist|| !isemailexist || !isAddressValid) {
            return;
        }
    
        // Prepare form data
        const formData = new FormData();
        const url = "https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=add-company";
        formData.append('name', selectedItem.name);
        formData.append('phone', selectedItem.phone);
        formData.append('email', selectedItem.email);
        formData.append('address', selectedItem.address);
    
        await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials': true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success } = json;
            if (success === 1) {
                toast.success("Company Create Completed", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setIsAddNewOpen(false);
                getcompany('', 1);
            } else if (success === 2) {
                toast.error("Company Name Exist", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch((error) => {
            console.error('Server Connection Failed', error);
        });
    };

    const searchfunction =async(value)=>{
        setismainloading(true);
        setSearchData(value);
        PageNumber(1);
        await getcompany(value,1)
        setismainloading(false);
    }

    const previouspage =()=>{
        if (pageno!=1){
            setPageNumber(pageno-1);
        }
    }
    const setPageNumber =async(page)=>{
        setismainloading(true);
        PageNumber(page);
        await getcompany('',page)
        setismainloading(false);
    }
    const waitcompanylist=async()=>{
        setismainloading(true);
        await getcompany('',1);
        setismainloading(false);
    }
    useEffect(() => {
        waitcompanylist();
        
    }, []);
    

    return (
            <div className='main-content'>
                <TopPageHeader title='Company' />

                <section className='content-area'>
                
                    <header className='content-area__header'>
                        <button className='content-area__header--btn' onClick={() => AddNew()}>+ Add New Company</button>
                        {
                            isAddNewOpen &&
                            <Modal
                                title='Add New Company'
                                setIsOpen={setIsAddNewOpen}
                                actionBtn={<button className='form-action-btn' onClick={async() => {setismodalloading(true);addnewcompany();setismodalloading(false)}}>Save</button>}
                            >
                                <div className='grid-3-form'>
                                    <div className='form-control'>
                                        <label className='form-control__label'>Company Name</label>
                                        <input className='form-control__input' type="text" placeholder='Company Name' onChange={(e) => handleEditInputChange('name', e.target.value)} />
                                        {isnameexist === false && (
                                            <label className='form-control__error'>Please key in Company Name</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Phone No</label>
                                        
                                        <input className='form-control__input' type="text" placeholder='Phone No' onChange={(e) => {validatePhone(e.target.value); handleEditInputChange('phone', e.target.value)}} />
                                        {isphoneexist === false && (
                                            <label className='form-control__error'>Please key in a valid Phone No</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Email</label>
                                        <input className='form-control__input' type="email" placeholder='Email' onChange={(e) => {validateEmail(e.target.value); handleEditInputChange('email', e.target.value)}}/>
                                        {isemailexist === false && (
                                            <label className='form-control__error'>Please key in a Valid Email</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Address</label>
                                        <input className='form-control__input' type="text" placeholder='Address' onChange={(e) => handleEditInputChange('address', e.target.value)}/>
                                        {isaddressexist === false && (
                                            <label className='form-control__error'>Please key in Address</label>
                                        )}
                                    </div>
                                </div>
                            </Modal>
                        }

                        <div>
                            <div className='content-area__header--search'>
                                <img className='search-icon' src='./../images/search-icon.png' alt='search icon' />
                                <input className='search-input' type="text" placeholder='Search' value={searchData} onChange={(e) =>{searchfunction(e.target.value)}}/>
                            </div>

                            <nav className='content-area__header--pagination'>
                                <button className='pagination-btn nav' onClick={previouspage}>&lt;</button>
                                <button className={`pagination-btn ${pageno === 1 ? 'active' : ''}`} onClick={() => setPageNumber(1)}>1</button>
                                <button className={`pagination-btn ${pageno === 2 ? 'active' : ''}`} onClick={() => setPageNumber(2)}>2</button>
                                <button className={`pagination-btn ${pageno === 3 ? 'active' : ''}`} onClick={() => setPageNumber(3)}>3</button>
                                <button className='pagination-btn'>...</button>
                                <button className={`pagination-btn ${pageno === 10 ? 'active' : ''}`} onClick={() => setPageNumber(10)}>10</button>
                                <button className='pagination-btn nav' onClick={() => setPageNumber(pageno+1)}>&gt;</button>
                            </nav>
                        </div>
                    </header>
                    {ismainloading ? (
                        <div className='loading-screen'>
                        <img src='./../images/spinner.gif' alt='Loading...' />
                        </div>
                    ) : (
                    <>
                    <div className='content-box'>
                        <div className='data-table scroll-sm'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Company Name.</th>
                                        <th>Phone</th>
                                        <th>Email</th>
                                        <th>Address</th>
                                    </tr>
                                </thead>

                                <tbody>
                                {postData.map((item, index) => (
                                        <tr key={index}>
                                            <td>{(pageno-1)*20+index + 1}</td>
                                            <td>{item.name}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.email}</td>
                                            <td>{item.address}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </>
                    )
                    }
                </section>
            </div>
    )
}

export default SetUpCompanyPage;