import React, { useState,useEffect } from 'react';
import TopPageHeader from '../components/TopPageHeader';
import Modal from '../components/Modal';
import Multiselect from 'multiselect-react-dropdown';
import { useNavigate } from 'react-router-dom'
import moment from 'moment';

function JobHistoryPage() {
    const [isAddNewOpen, setIsAddNewOpen] = useState(false);
    const [isFilterByOpen, setIsFilterByOpen] = useState(false);
    const [isViewDetailsOpen, setIsViewDetailsOpen] = useState(false);
    const [isFilterSortByOpen, setIsFilterSortByOpen] = useState(false);
    const [postData, setPostData] = useState([]);
    const [searchData, setSearchData] = useState('');
    const [pageno,PageNumber]=useState(1);
    const [filterlist,setfilterlist]=useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedTechnicians, setSelectedTechnicians] = useState([]);
    const [pageelement,setPageElement]=useState([]);
    const [complainlist, setcomplainlist] = useState([]);
    const [inspectionlist, setinspectionlist] = useState([]);
    const [partslist,setpartslist] = useState([]);
    const [modallist, setcurrentmodallist]=useState('');
    const [ismainloading,setismainloading]=useState(false);
    const [ismodalloading,setismodalloading]=useState(false);
    const navigate = useNavigate();

    const setPageNumber =async(page)=>{
        setismainloading(true);
        PageNumber(page);
        await getjoblist('',page);
        setismainloading(false);
    }

    const searchfunction =(value)=>{
        setSearchData(value);
        getjoblist(value,1);
    }
    const previouspage =()=>{
        if (pageno!=1){
            setPageNumber(pageno-1);
        }
    }
    const handleRemovefilter = (selectedList, removedItem) => {
        setfilterlist(filterlist.filter(key => key !== removedItem.key));
      };
    const handleSelectfilter = (selectedList, selectedItem) => {
        setfilterlist([...filterlist, selectedItem.key]);
    };
    const options=[
        {
            key: '0',
            name:'Job Created'
        },
        {
            key: '1',
            name: 'Check-in'
        },
        {
            key: '2',
            name: 'Inspection'
        },
        {
            key: '3',
            name: 'Job Estimation'
        },
        {
            key: '4',
            name: 'Pending Approval'
        },
        {
            key: '5',
            name: 'Waiting for Parts'
        }
        ,
        {
            key: '6',
            name: 'Assign Technician'
        },
        {
            key: '7',
            name: 'Completed'
        },
        {
            key: '8',
            name: 'QC'
        },
        {
            key: '9',
            name: 'Waiting for Vehicle Collect'
        }
    ];
    const renderinspectionlist=(item,index)=>{
        if (modallist=='jobdetail'){
        if(item.type=='0'){
            return(
            <div className='customer-complain__row'>
            <div className='customer-complain__row--number'>{index+1}</div>
            <input className='form-control__input' type="text" placeholder='' value={item.message} disabled />
            </div>
            )
        }
        else if (item.type=='1'){
        return(
            <div className='customer-complain__row'>
            <div className='customer-complain__row--number'>{index+1}</div>
            <input className='form-control__input' type="text" placeholder='' value={item.message} disabled />
            <button class="form-add-new-btn" onClick={()=>handlePlay(item.path)}>
                <img style={{ height: '2rem', marginRight: '.8rem' }} src='./../images/play-icon.png' alt='play icon' />Play
            </button>
            </div>
        )}
        else
        {
            return(       
            <div className='customer-complain__row align-items-start'>
            <div className='customer-complain__row--number'>{index+1}</div>
            {item.path && item.path.length < 30 ? (
            <div className='flex-grow-1'>
                <img className='h-100' src={`https://adminpanel.mewahautoworks.com.my/api/${item.path}`} alt="truck" />
            </div>
            ) : (
            <div className='flex-grow-1'>
                <img className='h-100' src={item.path} alt="truck" />
            </div>
    )}
        </div>
        )
    }
    }
    }
    const handlePlay = (url) => {
        const audio = new Audio(url);
    
        // Add error handling
        audio.addEventListener('error', (e) => {
            console.error('Failed to play audio. Please check the URL or audio format.', e);
        });
    
        // Play the audio
        audio.play()
            .then(() => {
                console.log('Audio is playing');
            })
            .catch((error) => {
                console.error('Error occurred while trying to play the audio:', error);
            });
    };
    const renderComplainlistinspection=(item,index)=>{
        if (modallist=='jobdetail'){
            if(item.type=='0'){
                return(
                
                <div className='customer-complain__row'>
                <div className='customer-complain__row--number'>{index+1}</div>
                <input className='form-control__input' type="text" placeholder='' value={item.message} disabled />
                </div>
                )
            }
            else if (item.type=='1'){
            return(
                <div className='customer-complain__row'>
                <div className='customer-complain__row--number'>{index+1}</div>
                <input className='form-control__input' type="text" placeholder='' value={item.message} disabled />
                <button class="form-add-new-btn" onClick={()=>handlePlay(item.path)}>
                    <img style={{ height: '2rem', marginRight: '.8rem' }} src='./../images/play-icon.png' alt='play icon' />Play
                </button>
                </div>
            )}
            else
            {
                return(       
                <div className='customer-complain__row align-items-start'>
                <div className='customer-complain__row--number'>{index+1}</div>
                {item.path && item.path.length < 30 ? (
                <div className='flex-grow-1'>
                    <img className='h-100' src={`https://adminpanel.mewahautoworks.com.my/api/${item.path}`} alt="truck" />
                </div>
                ) : (
                <div className='flex-grow-1'>
                    <img className='h-100' src={item.path} alt="truck" />
                </div>
        )}
            </div>
            )
        }  
        }
    }
    const getjobdetail = (job_id) => {
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=get-job-detail";
        formData.append('job_id', job_id);
        
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                setSelectedItem(data);
                setcomplainlist(data.complain);
                setpartslist(data.parts);
                setinspectionlist(data.inspection);
                setSelectedTechnicians(data.assignment);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };
    const getjoblist = async(searchvalue,page) => {
        let offset=(page-1)*20;
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=get-job-list";
        if (pageelement.service_id !== undefined && pageelement.service_id !== '') {
            formData.append('service_id', pageelement.service_id);
        }
        if (pageelement.vehicle_no !== undefined && pageelement.vehicle_no !== '') {
            formData.append('vehicle_no', pageelement.vehicle_no);
        }
        if (filterlist.length > 0) {
            formData.append('status', filterlist.join(','));
        }
        if (pageelement.sortby !== undefined && pageelement.sortby !== '') {
            formData.append('sort', pageelement.sortby);
        }
        if (pageelement.order !== undefined && pageelement.order !== '') {
            formData.append('order', pageelement.order);
        }
        if (pageelement.completed !== undefined && pageelement.completed !== '') {
            formData.append('completed', pageelement.completed);
        }else{
            formData.append('completed', 1);
        }
        formData.append('offset',String(offset));

        return await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                setPostData(data);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };
    const waitjoblist = async() =>{
        setismainloading(true);
        await getjoblist('',pageno);
        setismainloading(false);
    }

    useEffect(() => {
        setPageElement({
            ...pageelement,
            ['service_id']: '',
            ['vehicle_no']: '',
            ['status']: '',
            ['sortby']: '',
            ['order']: '',
            ['completed']: '',         
        });
            waitjoblist();
            
    }, []);
    return (
        <div className='main-content'>
            <TopPageHeader title='Jobs History' />

            <section className='content-area'>
            {ismainloading ? (
                    <div className='loading-screen'>
                    <img src='./../images/spinner.gif' alt='Loading...' />
                    </div>
            ) : (
                <>
                <header className='content-area__header'>
                    <div>
                        {//<button className='content-area__header--btn' onClick={() => setIsAddNewOpen(true)}>+ Add New Job</button>
}
                        {
                            isAddNewOpen &&
                            <Modal
                                title='Add New Job'
                                setIsOpen={setIsAddNewOpen}
                                actionBtn={<button className='form-action-btn'>Save</button>}
                                modelStyle='light-center'
                                style={{ width: '52rem', height: '45rem' }}
                            >
                                <div className='grid-1-form'>
                                    <div className='form-control'>
                                        <label className='form-control__label'>Job Type</label>
                                        <select className='form-control__input'>
                                            <option value="1">TRACK SALES</option>
                                            <option value="2">PARK SALES</option>
                                            <option value="3">WORKSHOP REPAIR</option>
                                            <option value="4">RETURN JOB</option>
                                            <option value="5">SERVICING</option>
                                        </select>
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Equipment No.</label>
                                        <input className='form-control__input' type="text" placeholder='Equipment No.' value='' />
                                    </div>
                                </div>
                            </Modal>
                        }

                        <div className='content-area__header--filter-row'>
                            {/*
                            <label className='filter-row-label'>Filter by :</label>
                            <button className='filter-row-btn active'>All</button>
                            */}
                            <button className='filter-row-btn active'>Repair</button>
                            {/*
                            <button className='filter-row-btn'>Part Sales</button>
                            <button className='filter-row-btn'>Track Sales</button>
                            <button className='filter-row-btn'>Servicing</button>
                            */}
                        </div>
                    </div>

                    <div>
                    <div className='content-area__header--search'>
                            <img className='search-icon' src='./../images/search-icon.png' alt='search icon' /> 
                            <input className='search-input' type="text" placeholder='Search' value={searchData} onChange={(e) =>{searchfunction(e.target.value)}}/>
                        </div>

                        <nav className='content-area__header--pagination'>
                                <button className='pagination-btn nav' onClick={previouspage}>&lt;</button>\\
                                <button className={`pagination-btn ${pageno === 1 ? 'active' : ''}`} onClick={() => setPageNumber(1)}>1</button>
                                <button className={`pagination-btn ${pageno === 2 ? 'active' : ''}`} onClick={() => setPageNumber(2)}>2</button>
                                <button className={`pagination-btn ${pageno === 3 ? 'active' : ''}`} onClick={() => setPageNumber(3)}>3</button>
                                <button className='pagination-btn'>...</button>
                                <button className={`pagination-btn ${pageno === 10 ? 'active' : ''}`} onClick={() => setPageNumber(10)}>10</button>
                                <button className='pagination-btn nav' onClick={() => setPageNumber(pageno+1)}>&gt;</button>
                            </nav>

                        <div className='content-area__header--filter-bar'>
                            {/*
                            <button className='filter-btn' onClick={() => setIsFilterByOpen(true)}><i class="fa fa-sliders" aria-hidden="true"></i>Filter by</button>
                            */}
                            <div className='d-flex flex-grow-1 flex-basis-10 position-relative ml-8'>
                                <button className='filter-btn' onClick={() => setIsFilterSortByOpen(!isFilterSortByOpen)}><i class="fa fa-sort-amount-desc" aria-hidden="true"></i>Sort by</button>
                                {
                                    isFilterSortByOpen &&
                                    <div className='filter-dropdown'>
                                        <button className='filter-dropdown__btn' onClick={async()=> {setismainloading(true); setPageElement({...pageelement,['sortby']:'id',['order']:'asc'});setPageNumber(1);await getjoblist('',1); setismainloading(false)}}>ORDER ID (HIGH TO LOW)</button>
                                        <button className='filter-dropdown__btn' onClick={async()=> {setismainloading(true); setPageElement({...pageelement,['sortby']:'id',['order']:'desc'});setPageNumber(1);await getjoblist('',1); setismainloading(false)}}>ORDER ID (LOW TO HIGH)</button>
                                        <button className='filter-dropdown__btn' onClick={async()=> {setismainloading(true); setPageElement({...pageelement,['sortby']:'created_at',['order']:'asc'});setPageNumber(1);await getjoblist('',1); setismainloading(false)}}>DATE (OLDER TO LATEST)</button>
                                        <button className='filter-dropdown__btn' onClick={async()=> {setismainloading(true); setPageElement({...pageelement,['sortby']:'created_at',['order']:'desc'});setPageNumber(1);await getjoblist('',1); setismainloading(false)}}>DATE (LATEST TO OLDER)</button>
                                        <button className='filter-dropdown__btn' onClick={async()=> {setismainloading(true); setPageElement({...pageelement,['sortby']:'technician_names',['order']:'asc'});setPageNumber(1);await getjoblist('',1); setismainloading(false)}}>FOREMAN (A TO Z)</button>
                                        <button className='filter-dropdown__btn' onClick={async()=> {setismainloading(true); setPageElement({...pageelement,['sortby']:'technician_names',['order']:'desc'});setPageNumber(1);await getjoblist('',1); setismainloading(false)}}>FOREMAN (Z TO A)</button>
                                        <button className='filter-dropdown__btn' onClick={async()=> {setismainloading(true); setPageElement({...pageelement,['sortby']:'customer_name',['order']:'asc'});setPageNumber(1);await getjoblist('',1); setismainloading(false)}}>CUSTOMER (A TO Z)</button>
                                        <button className='filter-dropdown__btn' onClick={async()=> {setismainloading(true); setPageElement({...pageelement,['sortby']:'customer_name',['order']:'desc'});setPageNumber(1);await getjoblist('',1); setismainloading(false)}}>CUSTOMER (Z TO A)</button>
                                    </div>
                                }
                            </div>
                        </div>


                        {
                            isFilterByOpen &&
                            <Modal
                                title='Filter by'
                                setIsOpen={setIsFilterByOpen}
                                actionBtn={<button className='form-action-btn' onClick={async()=>{setismainloading(true); await getjoblist('',pageno);setIsFilterByOpen(false);setismainloading(false)}}>Filter</button>}
                                modelStyle='light-center'
                                style={{ width: '52rem', height: '45rem' }}
                            >
                                <div className='grid-1-form'>
                                    <div className='form-control'>
                                        <label className='form-control__label'>Status</label>
                                        <Multiselect
                                            displayValue="name"
                                            onKeyPressFn={function noRefCheck() { }}
                                            onRemove={handleRemovefilter}
                                            onSearch={function noRefCheck() { }}
                                            onSelect={handleSelectfilter}
                                            options={options}
                                            selectedValues={filterlist.map(key => 
                                                options.find(option => option.key === key)
                                            )}
                                            showCheckbox
                                            style={{
                                                option: {
                                                    'font-family': 'Inter',
                                                    'font-size': '1.3rem',
                                                    'font-weight': 400,
                                                    'line-height': '2.5rem',
                                                    'color': '#27303D',
                                                    'height': '2.5rem',
                                                    'pending': '0rem',
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </Modal>
                        }
                    </div>
                </header>

                <div className='content-box transparent flex-column overflow-auto scroll-sm2'>
                {postData.map((item, index) => (
                    <div className='job-box'>
                        <div className='job-box__detail'>
                            <p className='job-box__detail--text'>Order ID: {item.id}</p>
                            <h3 className='job-box__detail--title'>{item.vehicle_no}</h3>
                            <p className='job-box__detail--text'>{item.equipment_type}</p>
                            <p className='job-box__detail--text'>{item.company_name}</p>
                            <div className='job-box__detail--btn-row'>
                            <button className='row-btn' onClick={async()=> {setismodalloading(true); setSelectedItem(item); setIsViewDetailsOpen(true); setcurrentmodallist('jobdetail'); await getjobdetail(item.id); setismodalloading(false)}}>View details</button>
                            </div>
                        </div>
                        <div className='job-box__steps'>
                            <div className='step-percent'>{parseInt(item.status)*10}%</div>
                            {parseInt(item.status)>0?(
                            <div className='step-btn done'>
                                Job Created
                                <div className='step-info'>
                                    <p>{moment(item.created_at).format('DD MMM YYYY')}</p>
                                    <p>{moment(item.created_at).format('h:mm A')}</p>
                                </div>
                            </div>
                            ):(
                            <div className='step-btn'>
                                Job Created
                            </div>    
                            )
                        }
                        {parseInt(item.status)>1?(
                            <div className='step-btn done'>
                                Check-in
                                <div className='step-info'>
                                    <p>{moment(item.checkin_at).format('DD MMM YYYY')}</p>
                                    <p>{moment(item.checkin_at).format('h:mm A')}</p>
                                </div>
                            </div>
                            ):(
                            <div className='step-btn'>
                                Check-in
                            </div>    
                            )
                        }
                        {parseInt(item.status)>2?(
                            <div className='step-btn done'>
                                Inspection
                                <div className='step-info'>
                                    <p>{moment(item.inspected_at).format('DD MMM YYYY')}</p>
                                    <p>{moment(item.inspected_at).format('h:mm A')}</p>
                                </div>
                            </div>
                            ):(
                            <div className='step-btn'>
                                Inspection
                            </div>    
                            )
                        }
                        {parseInt(item.status)>3?(
                            <div className='step-btn done'>
                                Job Estimation
                                <div className='step-info'>
                                    <p>{moment(item.estimated_at).format('DD MMM YYYY')}</p>
                                    <p>{moment(item.estimated_at).format('h:mm A')}</p>
                                </div>
                            </div>
                            ):(
                            <div className='step-btn'>
                                Job Estimation
                            </div>    
                            )
                        }
                        {parseInt(item.status)>4?(
                            <div className='step-btn done'>
                                Pending Approval
                                <div className='step-info'>
                                    <p>{moment(item.approved_at).format('DD MMM YYYY')}</p>
                                    <p>{moment(item.approved_at).format('h:mm A')}</p>
                                </div>
                            </div>
                            ):(
                            <div className='step-btn'>
                                Pending Approval
                            </div>    
                            )
                        }
                        {parseInt(item.status)>5?(
                            <div className='step-btn done'>
                                Waiting for Parts
                                <div className='step-info'>
                                    <p>{moment(item.parts_arrived_at).format('DD MMM YYYY')}</p>
                                    <p>{moment(item.parts_arrived_at).format('h:mm A')}</p>
                                </div>
                            </div>
                            ):(
                            <div className='step-btn'>
                                Waiting for Parts
                            </div>    
                            )
                        }
                        {parseInt(item.status)>6?(
                            <div className='step-btn done'>
                                Assign Technician
                                <div className='step-info'>
                                    <p>{moment(item.parts_assigned_at).format('DD MMM YYYY')}</p>
                                    <p>{moment(item.parts_assigned_at).format('h:mm A')}</p>
                                </div>
                            </div>
                            ):(
                            <div className='step-btn'>
                                Assign Technician
                            </div>    
                            )
                        }
                        {parseInt(item.status)>7?(
                            <div className='step-btn done'>
                                Completed
                                <div className='step-info'>
                                    <p>{moment(item.completed_at).format('DD MMM YYYY')}</p>
                                    <p>{moment(item.completed_at).format('h:mm A')}</p>
                                </div>
                            </div>
                            ):(
                            <div className='step-btn'>
                                Completed
                            </div>    
                            )
                        }
                        {parseInt(item.status)>8?(
                            <div className='step-btn done'>
                                QC
                                <div className='step-info'>
                                    <p>{moment(item.qc_at).format('DD MMM YYYY')}</p>
                                    <p>{moment(item.qc_at).format('h:mm A')}</p>
                                </div>
                            </div>
                            ):(
                            <div className='step-btn'>
                                QC
                            </div>    
                            )
                        }
                        {parseInt(item.status)>9?(
                            <div className='step-btn done'>
                                Waiting for Vehicle Collect
                                <div className='step-info'>
                                    <p>{moment(item.collected_at).format('DD MMM YYYY')}</p>
                                    <p>{moment(item.collected_at).format('h:mm A')}</p>
                                </div>
                            </div>
                            ):(
                            <div className='step-btn'>
                                Waiting for Vehicle Collect
                            </div>    
                            )
                        }
        
                        </div>
                    </div>
                ))
                }

{
                        isViewDetailsOpen &&
                        <Modal
                            title={`View details (${selectedItem.registration_no})`}
                            setIsOpen={setIsViewDetailsOpen}
                            actionBtn={
                                <button className='form-action-btn danger' onClick={()=>setIsViewDetailsOpen(false)}>Close</button>
                            }
                            style={{ width: '100rem', height: '70rem' }}
                        >
                            {ismodalloading ? (
                                <div className='loading-gif-container'>
                                <img src='./../images/spinner.gif' alt='Loading...' />
                                </div>
                            ) : (
                                <>
                            <div className='grid-2-form'>
                                <div className='form-control'>
                                    <label className='form-control__label'>Vehicle No:</label>
                                    <input className='form-control__input' type='text' placeholder='Vehicle No' value={selectedItem.vehicle_no} disabled />
                                </div>

                                <div className='form-control'>
                                    <label className='form-control__label'>Date:</label>
                                    <input className='form-control__input' type='text' placeholder='Date' value={moment(selectedItem.created_at).format('DD/MM/YY')} disabled />
                                </div>

                                <div className='form-control'>
                                    <label className='form-control__label'>Part:</label>
                                    <input className='form-control__input' type='text' placeholder='Part' value={Array.isArray(partslist) ? partslist.map(part => part.name).join(', ') : ''} disabled />
                                </div>

                                <div className='form-control'>
                                    <label className='form-control__label'>Detention Day:</label>
                                    <input className='form-control__input' type='text' placeholder='Detention Day' value={`${moment(selectedItem.estimated_out).diff(moment(selectedItem.checkin_at),'days')} days`} disabled />
                                </div>

                                <div className='form-control'>
                                    <label className='form-control__label'>Technician:</label>
                                    <input className='form-control__input' type='text' placeholder='Technician' value={Array.isArray(selectedTechnicians) ? selectedTechnicians.map(technicians => technicians.technician_name).join(', ') : ''} disabled />
                                </div>

                                <div className='form-control'>
                                    <label className='form-control__label'>QC Checked by:</label>
                                    <input className='form-control__input' type='text' placeholder='QC Checked by' value={selectedItem.qc_check} disabled />
                                </div>
                            </div>
                            <div className='grid-2-form grid-form-dark'>
                                <div className='form-control'>
                                    <label className='form-control__label'>Carry out by:</label>
                                    <input className='form-control__input' type='text' placeholder='Inspection Carry' value={selectedItem.inspection_carry} disabled />
                                </div>

                                <div className='form-control'>
                                    <label className='form-control__label'>Accompanied by:</label>
                                    <input className='form-control__input' type='text' placeholder='Inspection Accompany' value={selectedItem.inspection_accompany} disabled />
                                </div>
                            </div>

                            <div className='customer-complain border-top-none mt-0 scroll-sm'>
                                <p className='customer-complain__title'>Customer Complain:</p>

                                {complainlist.map((item,index)=> renderComplainlistinspection(item, index))}

                            </div>

                            <div className='new-complain border-top-none mt-0'>
                                <p className='customer-complain__title'>Add-on after inspection</p>

                                {inspectionlist.map((item,index)=>renderinspectionlist(item, index))}
                                
                            </div>
                            </>
                            )
                        }
                        </Modal>
}

                </div>
                </>
            )
        }
            </section>
        </div>
    )
}

export default JobHistoryPage;