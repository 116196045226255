import React from 'react';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';

const RadarSkillChart = ({ selectedItem }) => {
    const data = [
        { subject: 'Safety', A: selectedItem.skill1, fullMark: 20 },
        { subject: 'Leadership', A: selectedItem.skill2, fullMark: 20 },
        { subject: 'Engine', A: selectedItem.skill3, fullMark: 20 },
        { subject: 'Communication', A: selectedItem.skill4, fullMark: 20 },
        { subject: 'Solving', A: selectedItem.skill5, fullMark: 20 }
      ];
    
      return (
        <div style={{ width: '100%', height: 240 }}>
          <ResponsiveContainer width="100%" height="100%">
            <RadarChart
              cx="50%"
              cy="50%"
              outerRadius="70%"
              data={data}
            >
              <PolarGrid />
              <PolarAngleAxis dataKey="subject" />
              <PolarRadiusAxis angle={30} domain={[0, 20]} />
              <Radar
                name="Skills"
                dataKey="A"
                stroke="#8884d8"
                fill="#8884d8"
                fillOpacity={0.6}
              />
            </RadarChart>
          </ResponsiveContainer>
        </div>
      );
    };
    
    
export default RadarSkillChart;