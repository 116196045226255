import React, { useEffect, useState, useRef } from 'react';
import TopPageHeader from '../components/TopPageHeader';
import Modal from '../components/Modal';
import Multiselect from 'multiselect-react-dropdown';
import { NumberInput } from '../components/Inputs';
import SignatureCanvas from 'react-signature-canvas'
import moment from 'moment';
import { useReactMediaRecorder } from "react-media-recorder-2";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AsyncCompiler } from 'sass';
import Moment from 'react-moment';

function JobPage() {
    const [isAddNewOpen, setIsAddNewOpen] = useState(false);
    const [isFilterByOpen, setIsFilterByOpen] = useState(false);
    const [isStartJobOpen, setIsStartJobOpen] = useState(false);
    const [isViewDetailsOpen, setIsViewDetailsOpen] = useState(false);
    const [isCompleteJobOpen, setIsCompleteJobOpen] = useState(false);
    const [isCheckInOpen, setIsCheckInOpen] = useState(false);
    const [isInspectionOpen, setIsInspectionOpen] = useState(false);
    const [isJobEstimationOpen, setIsJobEstimationOpen] = useState(false);
    const [isWaitingForPartsOpen, setIsWaitingForPartsOpen] = useState(false);
    const [isAssignTechnicianOpen, setIsAssignTechnicianOpen] = useState(false);
    const [isCompletedOpen, setIsCompletedOpen] = useState(false);
    const [isCompleedFormanSignOpen, setIsCompleedFormanSignOpen] = useState(false);
    const [isQCOpen, setIsQCOpen] = useState(false);
    const [isVehicleCollectedOpen, setIsVehicleCollectedOpen] = useState(false);
    const [isFilterSortByOpen, setIsFilterSortByOpen] = useState(false);
    const [postData, setPostData] = useState([]);
    const [searchData, setSearchData] = useState('');
    const [pageno,PageNumber]=useState(1);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedTechnicians, setSelectedTechnicians] = useState([]);
    const [deletelist,setdeletelist]=useState([]);
    const [addlist,setaddlist]=useState([]);
    const [complainlist, setcomplainlist] = useState([]);
    const [inspectionlist, setinspectionlist] = useState([]);
    const [pageelement,setPageElement]=useState([]);
    const navigate = useNavigate();
    const [complaininput,setcomplaininput]=useState([]);
    const [inspectioninput,setinspectioninput]=useState([]);
    const [Foremanlist, setForemanlist]=useState([]);
    const [workdeadline, setdeadline]=useState('');
    const [filterlist,setfilterlist]=useState([]);
    const [lastTranscript, setLastTranscript] = useState('');
    const [modallist, setcurrentmodallist]=useState('');
    const [servicelist, setservicelist]=useState([]);
    const [companylist,setCompanylist] = useState([]);
    const [customerlist,setCustomerlist] = useState([]);
    const [vehiclelist,setVehiclelist] = useState([]);
    const [estimateinput,setestimateinput] = useState([]);
    const [estimatelist,setestimatelist] = useState([]);
    const [partslist,setpartslist] = useState([]);
    const sigCanvas = useRef({});
    const [imageURL, setImageURL] = useState(null);
    const [ismainloading,setismainloading]=useState(false);
    const [ismodalloading,setismodalloading]=useState(false);
    const [todaydate, settodaydate]=useState(new Date)
    let upfail=false;
    const [filterVisible, setFilterVisible] = React.useState({
        all: false,
        repair: true,
        partSales: false,
        trackSales: false,
        servicing: false,
    });
    const { status, startRecording, stopRecording } = useReactMediaRecorder({
          audio: true,
          type: 'audio/mp3',
          onStop: (blobUrl, blob) => {
            // Handle the recorded audio blob
            handleRecordedAudio(blobUrl, blob);
          }
        });
        let recognition;
      
        const handleRecordedAudio = (blobUrl, blob) => {
            if(modallist==='complainlist'){
                setcomplaininput((prevInput) => ({
                    ...prevInput,
                    ['job_id']:selectedItem.id,
                    ['type']:'1',
                    ['path']:blobUrl,
                    ['media']:blob,
                  }));
            }else if(modallist==='inspectionlist'){
                setinspectioninput((prevInput) => ({
                    ...prevInput,
                    ['job_id']:selectedItem.id,
                    ['type']:'1',
                    ['path']:blobUrl,
                    ['media']:blob,
                  }));
            }
            
        };
        const handleSpeechRecognitionResult = (event) => {
            const transcript = event.results[0][0].transcript;
            // Check if the new transcript is different from the previous one
              setcomplaininput((prevInput) => ({
                ...prevInput,
                message: complaininput.message+transcript,
              }));
              setLastTranscript(transcript);
          };

        const startRecognition = () => {
          recognition = new window.webkitSpeechRecognition();
          recognition.continuous = true;
          recognition.interimResults = true;
          recognition.onresult = handleSpeechRecognitionResult;
      
          recognition.start();
        };
      
        const stopRecognition = () => {
          if (recognition) {
            recognition.stop();
          }
        };
      
        const toggleRecordingAndRecognition = () => {
          if (status === 'recording') {
            stopRecording();
            //stopRecognition();
          } else {
            startRecording();
            //startRecognition();
          }
        };
        const options=[
            {
                key: '0',
                name:'Job Created'
            },
            {
                key: '1',
                name: 'Check-in'
            },
            {
                key: '2',
                name: 'Inspection'
            },
            {
                key: '3',
                name: 'Job Estimation'
            },
            {
                key: '4',
                name: 'Pending Approval'
            },
            {
                key: '5',
                name: 'Waiting for Parts'
            }
            ,
            {
                key: '6',
                name: 'Assign Technician'
            },
            {
                key: '7',
                name: 'Completed'
            },
            {
                key: '8',
                name: 'QC'
            },
            {
                key: '9',
                name: 'Waiting for Vehicle Collect'
            }
        ];
        const handleInputChange = (property, value) => {
            setSelectedItem({
                ...selectedItem,
                [property]: value,
            });
        };

      const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        const url = URL.createObjectURL(selectedFile);
        if(modallist==='complainlist'){
        setcomplaininput({
            ['job_id']:selectedItem.id,
            ['type']:'2',
            ['message']: '',
            ['path']:url,
            ['media']:selectedFile,
          });
        }else if(modallist==='inspectionlist'){
            setinspectioninput({
                ['job_id']:selectedItem.id,
                ['type']:'2',
                ['message']: '',
                ['path']:url,
                ['media']:selectedFile,
              });
        }

    };


    const previouspage =()=>{
        if (pageno!=1){
            setPageNumber(pageno-1);
        }
    }

    const clearSignature = () => {
        sigCanvas.current.clear();

      };

    const getPriceFromId = (partId) => {
        
        const part = partslist.find(part => part.id === partId);
        return part ? part.price : '' ;
    };
    const getstatusFromId = (partId) => {
        const part = partslist.find(part => part.id === partId);
        return part ? part.status : ''; 
    };

    const setPageNumber =async(page)=>{
        setismainloading(true);
        PageNumber(page);
        await getjoblist('',page);
        setismainloading(false);
    }

    const clearSelectedItem = () => {
        setSelectedItem(null);
        setSelectedItem('department_id','');
    };
    
    const searchfunction =async(value)=>{
        setSearchData(value);
        setismainloading(true);
        await getjoblist(value,1);
        setismainloading(false);
    }
    const removeItemByIndex = (indexToRemove) => {
        if(modallist==='complainlist'){
            if(complainlist[indexToRemove]?.id){
        setdeletelist((prevDeletelist) => [
            ...prevDeletelist,
            complainlist[indexToRemove]
        ]);
    }

    setcomplainlist((prevList) => {
        return prevList.filter((_, index) => index !== indexToRemove);
    });
    }else if(modallist==='inspectionlist'){
        if(inspectionlist[indexToRemove]?.id){
        setdeletelist((prevDeletelist) => [
            ...prevDeletelist,
            inspectionlist[indexToRemove]
        ]);
    }
    setinspectionlist((prevList) => {
        return prevList.filter((_, index) => index !== indexToRemove);
    });
    }else if(modallist==='estimatelist'){
        if(estimatelist[indexToRemove]?.id){
        setdeletelist((prevDeletelist) => [
            ...prevDeletelist,
            estimatelist[indexToRemove]
        ]);
    }
    setestimatelist((prevList) => {
        return prevList.filter((_, index) => index !== indexToRemove);
    });
    };
}
    const updateinspectionChecked = async()=>{
        for (const item of complainlist) {
            const formData = new FormData();
            const url = "https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=checked-job-complain-inspection";
            formData.append('id', item.id);
            const checkedValue = item.checked === true || item.checked === "1" ? "1" : "0";
            formData.append('inspectionchecked', checkedValue);
        
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': sessionStorage.getItem('token'),
                        'Access-Control-Allow-Credentials': true,
                        'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
                    },
                    body: formData
                });
        
                if (!response.ok) {
                    if (response.status === 401) {
                        sessionStorage.clear();
                        navigate('/login');
                    } else {
                        throw new Error('API Failed');
                    }
                }
        
                const json = await response.json();
                const { success, data } = json;
    
                if (success === 1) {
                }
            } catch (error) {
                upfail=true;
                console.error('Server Connection Failed:', error);
            }
        }
        //setcomplainlist([]);
    }

    const updateqcChecked = async()=>{
        for (const item of complainlist) {
            const formData = new FormData();
            const url = "https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=checked-job-complain-qc";
            formData.append('id', item.id);
            const qccheckedValue = item.qcchecked === true || item.qcchecked === "1" ? "1" : "0";
            formData.append('qcchecked', qccheckedValue);
        
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': sessionStorage.getItem('token'),
                        'Access-Control-Allow-Credentials': true,
                        'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
                    },
                    body: formData
                });
        
                if (!response.ok) {
                    if (response.status === 401) {
                        sessionStorage.clear();
                        navigate('/login');
                    } else {
                        throw new Error('API Failed');
                    }
                }
        
                const json = await response.json();
                const { success, data } = json;
    
                if (success === 1) {
                }
            } catch (error) {
                upfail=true;
                console.error('Server Connection Failed:', error);
            }
        }
        for (const item of inspectionlist) {
            const formData = new FormData();
            const url = "https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=checked-job-inspection-qc";
            formData.append('id', item.id);
            const checkedValue = item.checked === true || item.checked === "1" ? "1" : "0";
            formData.append('checked', checkedValue);
        
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': sessionStorage.getItem('token'),
                        'Access-Control-Allow-Credentials': true,
                        'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
                    },
                    body: formData
                });
        
                if (!response.ok) {
                    if (response.status === 401) {
                        sessionStorage.clear();
                        navigate('/login');
                    } else {
                        throw new Error('API Failed');
                    }
                }
        
                const json = await response.json();
                const { success, data } = json;
    
                if (success === 1) {
                }
            } catch (error) {
                upfail=true;
                console.error('Server Connection Failed:', error);
            }
        }
    }

    const saveseq = () => {
        deletelist.map((item,index)=>
            deletecomplain(item.id,item.path)
    )
    setdeletelist([]);
            addcomplain()
            if(upfail==false){
                toast.success("Complain Save Successfully", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            else{
                toast.success("Complain Save Failed", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setIsCheckInOpen(false);
            getjoblist('',1);
    }
    const saveseqinspection = async() => {
        await updateinspectionChecked()
        deletelist.map((item,index)=>
            deleteinspection(item.id,item.path)
    )
    setdeletelist([]);
            addinspection();
            if(upfail==true){
                toast.success("Inspection comment Save Failed", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
    }


   /* const loadFileFromURL = async (url,type) => {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Failed to fetch file from URL (${response.status} ${response.statusText})`);
            }
            const blob = await response.blob();
            let fileType;
            if (type === '1') {
                fileType = "audio/wav";
            } else {
                fileType = "image/jpeg";
            }
            const filename = url.substring(url.lastIndexOf('/') + 1);
            return new File([blob], filename, { type: fileType });
        } catch (error) {
            console.error('Error loading file from URL:', error);
            return null;
        }
    };*/


    const saveSignature = async (id) => {
        if (sigCanvas.current.isEmpty()) {
          alert("Please provide a signature first.");
        } else {
          sigCanvas.current.off()
          const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/jpeg");
          setImageURL(dataURL);
          console.log("Data URL:", dataURL);
          const formData = new FormData();
          let url='';
          if (modallist=='cplist'){
          url = "https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=job-completion";
          }else if (modallist=='vclist'){
          url = "https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=job-vehicle-collect";
          formData.append('vehicle_id', selectedItem.vehicle_id);
          formData.append('mileage',selectedItem.mileage);
          }
          const blob = await (await fetch(dataURL)).blob();
          formData.append('job_id', id);
          formData.append('signature', blob, 'signature.jpeg');
          
          return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                setIsCompleedFormanSignOpen(false);
                setIsVehicleCollectedOpen(false);
                getjoblist('',1);
                toast.success("Signature successfully uploaded", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                
                
                clearSignature();
                
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    }
}

    const getcompany = () => {
        const url = "https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=get-companylist";
        return fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data } = json;
            if (success == 1) {
                setCompanylist(data);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

    const addcomplain = async () => {
        for (const item of addlist) {
            const formData = new FormData();
            const url = "https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=add-job-complain";
            formData.append('job_id', item.job_id);
            formData.append('message', item.message);
            formData.append('type', item.type);
            if (item.type === '1' || item.type === '2') {
                formData.append('media', item.media);
            }
    
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': sessionStorage.getItem('token'),
                        'Access-Control-Allow-Credentials': true,
                        'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
                    },
                    body: formData
                });
    
                if (!response.ok) {
                    if (response.status === 401) {
                        sessionStorage.clear();
                        navigate('/login');
                    } else {
                        throw new Error('API Failed');
                    }
                }
    
                const json = await response.json();
                const { success, data } = json;
    
                if (success === 1) {
                }
            } catch (error) {
                upfail=true;
                console.error('Server Connection Failed:', error);
            }
        }
        setaddlist([]);
    }

    const deletecomplain = (id,path) => {

            const formData = new FormData();
            const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=delete-job-complain";
            formData.append('id', id);
            formData.append('path', path);
            return fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': sessionStorage.getItem('token'),
                    'Access-Control-Allow-Credentials':true,
                    'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
                },
                body: formData
            })
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        sessionStorage.clear();
                        navigate('/login');
                    } else {
                        throw new Error('API Failed');
                    }
                }
                return response.json();
            })
            .then((json) => {
                const { success, data} = json;
                if (success==1){
                    
                }
            })
            .catch(error => {
                upfail=true;
                console.error('Server Connection Failed');
            });
        }

    const addinspection = async () => {
        for (const item of addlist) {
            const formData = new FormData();
            const url = "https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=add-inspection-result";
            formData.append('job_id', item.job_id);
            formData.append('message', item.message);
            formData.append('type', item.type);
            if (item.type === '1' || item.type === '2') {
                formData.append('media', item.media);
            }
        
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': sessionStorage.getItem('token'),
                        'Access-Control-Allow-Credentials': true,
                        'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
                    },
                    body: formData
                });
        
                if (!response.ok) {
                    if (response.status === 401) {
                        sessionStorage.clear();
                        navigate('/login');
                    } else {
                        throw new Error('API Failed');
                    }
                }
        
                const json = await response.json();
                const { success, data } = json;
    
                if (success === 1) {
                }
            } catch (error) {
                upfail=true;
                console.error('Server Connection Failed:', error);
            }
        }
        setaddlist([]);
    }
    
    
    const deleteinspection = (id,path) => {
            const formData = new FormData();
            const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=delete-job-inspection";
            formData.append('id', id);
            formData.append('path', path);
            return fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': sessionStorage.getItem('token'),
                    'Access-Control-Allow-Credentials':true,
                    'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
                },
                body: formData
            })
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        sessionStorage.clear();
                        navigate('/login');
                    } else {
                        throw new Error('API Failed');
                    }
                }
                return response.json();
            })
            .then((json) => {
                const { success, data} = json;
                if (success==1){
                        
                }
            })
            .catch(error => {
                upfail=true;
                console.error('Server Connection Failed');
            });
        }

        const previousstatus = (id) => {
            const confirmed = window.confirm("Are you sure you want to back to previous?");
            if (confirmed){
            const formData = new FormData();
            const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=rollback-job";
            formData.append('job_id', id);
            return fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': sessionStorage.getItem('token'),
                    'Access-Control-Allow-Credentials':true,
                    'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
                },
                body: formData
            })
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        sessionStorage.clear();
                        navigate('/login');
                    } else {
                        throw new Error('API Failed');
                    }
                }
                return response.json();
            })
            .then((json) => {
                const { success, data} = json;
                if (success==1){
                    toast.success("Back to Previous Successfully", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setIsInspectionOpen(false);
                    setIsJobEstimationOpen(false);
                    setIsWaitingForPartsOpen(false);
                    setIsCompleteJobOpen(false);
                    setIsAssignTechnicianOpen(false);
                    setIsCompletedOpen(false);
                    setIsQCOpen(false);
                    setIsVehicleCollectedOpen(false);
                    getjoblist('',1);
                }
            })
            .catch(error => {
                console.error('Server Connection Failed');
            });
        }
    }

    const getcustomerlist = async(id) => {
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=get-customerlist";
        formData.append('company_id', id);

        return await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                setCustomerlist(data);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

    const getspartlist = async () => {
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=get-partslist";

        try {
            const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        if (!response.ok) {
            if (response.status === 401) {
                sessionStorage.clear();
                navigate('/login');
            } else {
                throw new Error('API Failed');
            }
        }
        const json = await response.json();
        const { success, data } = json;
        if (success == 1) {
            setpartslist(data);
          }
        } catch (error) {
          console.error('Server Connection Failed');
        }
      };

    const getjobpart = async (job_id) => {
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=get-job-parts";
        formData.append('job_id', job_id);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                const updatedData = data.map(item => ({ ...item, checked: false }));
                setestimatelist(updatedData);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };
    const getcustomervehiclelist = async(id) => {
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=get-customer-vehiclelist";
        formData.append('company_id', id);

        return await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                setVehiclelist(data);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };


    const getjoblist = async(searchvalue,page) => {
        let offset=(page-1)*20;
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=get-job-list";
        if (searchvalue !== undefined && searchvalue !== '') {
            formData.append('searchword', searchvalue);
        }
        if (filterlist.length > 0) {
            formData.append('status', filterlist.join(','));
        }
        if (pageelement.sortby !== undefined && pageelement.sortby !== '') {
            formData.append('sort', pageelement.sortby);
        }
        if (pageelement.order !== undefined && pageelement.order !== '') {
            formData.append('order', pageelement.order);
        }
        if (pageelement.completed !== undefined && pageelement.completed !== '') {
            formData.append('completed', pageelement.completed);
        }else{
            formData.append('completed', 0);
        }
        formData.append('offset',String(offset));

        return await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                setPostData(data);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

    const getjobdetail = (job_id) => {
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=get-job-detail";
        formData.append('job_id', job_id);
        
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                setSelectedItem(data);
                setcomplainlist(data.complain);
                setpartslist(data.parts);
                setinspectionlist(data.inspection);
                setSelectedTechnicians(data.assignment);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

    const getinspection = async (job_id) => {
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=get-job-inspection";
        formData.append('job_id', job_id);
        
        return await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                setinspectionlist(data);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

    const getservice = () => {
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=get-servicelist";
        
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                setservicelist(data);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };


    const handlePlay = (url) => {
        const audio = new Audio(url);
    
        // Add error handling
        audio.addEventListener('error', (e) => {
            console.error('Failed to play audio. Please check the URL or audio format.', e);
        });
    
        // Play the audio
        audio.play()
            .then(() => {
                console.log('Audio is playing');
            })
            .catch((error) => {
                console.error('Error occurred while trying to play the audio:', error);
            });
    };


    const getforemanlist = async (searchvalue) => {
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=loadforeman";
        formData.append('searchword', searchvalue);

        return await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                setForemanlist(data);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

    const doneinspection = async (done) => {
        if (selectedItem.inspection_carry && selectedItem.inspection_accompany && 
            selectedItem.inspection_carry !== 'Carry out by' && selectedItem.inspection_accompany !== 'Accompanied by'&&
            selectedItem.inspection_carry.trim() !== '' && 
            selectedItem.inspection_accompany.trim() !== '') {
        const formData = new FormData();
            const url = "https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=job-inspection";
            formData.append('job_id', selectedItem.id);
            formData.append('carry_by', selectedItem.inspection_carry);
            formData.append('accompany_by', selectedItem.inspection_accompany);
            formData.append('done', done);
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': sessionStorage.getItem('token'),
                        'Access-Control-Allow-Credentials': true,
                        'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
                    },
                    body: formData
                });
        
                if (!response.ok) {
                    if (response.status === 401) {
                        sessionStorage.clear();
                        navigate('/login');
                    } else {
                        throw new Error('API Failed');
                    }
                }
        
                const json = await response.json();
                const { success, data } = json;
    
                if (success == 1) {
                    toast.success("inspection Save Successfully", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setIsInspectionOpen(false);
                    getjoblist('',1);
                }
            } catch (error) {
                console.error('Server Connection Failed:', error);
            }
        }else{
            toast.error("Please Select Foreman for this job", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        
    }

    const qcdone = async () => {
        let allcheck=true;
        complainlist.forEach((item) => {
            if (item.qcchecked === false|| item.qcchecked === '0') {
              allcheck = false;
            }
        }
    )
        inspectionlist.forEach((item) => {
            if (item.checked === false|| item.checked === '0') {
            allcheck = false;
            }
        }
    )
    if (allcheck==true){
        if (selectedItem.qc_accompany && selectedItem.qc_check && 
            selectedItem.qc_check !== 'Checked by' && selectedItem.qc_accompany !== 'Accompanied by'&&
            selectedItem.qc_check.trim() !== '' && 
            selectedItem.qc_accompany.trim() !== '') {
        await updateqcChecked();
        const formData = new FormData();
            const url = "https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=job-qc";
            formData.append('job_id', selectedItem.id);
            formData.append('checked_by', selectedItem.qc_check);
            formData.append('accompanied_by', selectedItem.qc_accompany);
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': sessionStorage.getItem('token'),
                        'Access-Control-Allow-Credentials': true,
                        'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
                    },
                    body: formData
                });
        
                if (!response.ok) {
                    if (response.status === 401) {
                        sessionStorage.clear();
                        navigate('/login');
                    } else {
                        throw new Error('API Failed');
                    }
                }
        
                const json = await response.json();
                const { success, data } = json;
    
                if (success == 1) {
                    toast.success("qc done Successfully", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setIsQCOpen(false);
                    setinspectionlist([]);
                    setcomplainlist([]);
                    getjoblist('',1);
                }
            } catch (error) {
                console.error('Server Connection Failed:', error);
            }
        }else{
            toast.success("Please Select Foreman for this job", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }else{
        toast.success("Please check all comment of this job", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    }

    const doneestimate = async(done) => {
        if (selectedItem.estimate_carry && selectedItem.estimate_accompany && 
            selectedItem.estimate_carry !== 'Carry out by' && selectedItem.estimate_accompany !== 'Accompanied by'&&
            selectedItem.estimate_carry.trim() !== '' && 
            selectedItem.estimate_accompany.trim() !== '') {
        const formData = new FormData();
            const url = "https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=job-estimation";
            formData.append('job_id', selectedItem.id);
            formData.append('carry_by', selectedItem.estimate_carry);
            formData.append('accompany_by', selectedItem.estimate_accompany);
            formData.append('parts', JSON.stringify(estimatelist));
            let total_price = estimatelist.reduce((total, item) => {
                return total + item.total_cost;
            }, 0);
            formData.append('total_price', total_price);
            formData.append('done', done);
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': sessionStorage.getItem('token'),
                        'Access-Control-Allow-Credentials': true,
                        'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
                    },
                    body: formData
                });
        
                if (!response.ok) {
                    if (response.status === 401) {
                        sessionStorage.clear();
                        navigate('/login');
                    } else {
                        throw new Error('API Failed');
                    }
                }
        
                const json = await response.json();
                const { success, data } = json;
    
                if (success == 1) {
                    toast.success("Estimate Save Successfully", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    getjoblist('',1);
                }
            } catch (error) {
                console.error('Server Connection Failed:', error);
            }
        }else{
            toast.error("Please Select Foreman for this job", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        
    }
    const jobapproved = async(id) => {
            const formData = new FormData();
            const url = "https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=job-approval";
            formData.append('job_id', selectedItem.id);
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': sessionStorage.getItem('token'),
                        'Access-Control-Allow-Credentials': true,
                        'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
                    },
                    body: formData
                });
        
                if (!response.ok) {
                    if (response.status === 401) {
                        sessionStorage.clear();
                        navigate('/login');
                    } else {
                        throw new Error('API Failed');
                    }
                }
        
                const json = await response.json();
                const { success, data } = json;
    
                if (success == 1) {
                    toast.success("Job Approved", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    getjoblist('',1);
                }
            } catch (error) {
                console.error('Server Connection Failed:', error);
            }
        
    }

    const jobwaitingpart = async(id) => {
        let allcheck=true;
        estimatelist.forEach((item) => {
            if (item.checked === false) {
              allcheck = false;
            }
        }
    )
    if (allcheck==true){
        const formData = new FormData();
        const url = "https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=job-parts-arrival";
        formData.append('job_id', id);
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': sessionStorage.getItem('token'),
                    'Access-Control-Allow-Credentials': true,
                    'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
                },
                body: formData
            });
    
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
    
            const json = await response.json();
            const { success, data } = json;

            if (success == 1) {
                toast.success("Job Part Updated", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setIsWaitingForPartsOpen(false);
                getjoblist('',1);
            }
        } catch (error) {
            console.error('Server Connection Failed:', error);
        }
    
    }
    else{
            toast.success("Please check all the part(s) is arrived", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
    }
    }

    const jobassigntechnician = async(id) => {
    if (selectedTechnicians!=[] && workdeadline!=''){
        const formData = new FormData();
        const url = "https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=job-assignment";
        formData.append('job_id', id);
        formData.append('estimate_time', moment(workdeadline).unix());
        formData.append('estimate_date', moment(workdeadline).format('YYYY-MM-DD'));
        formData.append('technician', JSON.stringify(selectedTechnicians));
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': sessionStorage.getItem('token'),
                    'Access-Control-Allow-Credentials': true,
                    'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
                },
                body: formData
            });
    
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
    
            const json = await response.json();
            const { success, data } = json;

            if (success == 1) {
                toast.success("Job Assignment Done", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setSelectedTechnicians([]);
                setdeadline('');
                setIsAssignTechnicianOpen(false);
                getjoblist('',1);
            }
        } catch (error) {
            console.error('Server Connection Failed:', error);
        }
    
    }
    else{
            toast.success("Please check data is filled", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setSelectedTechnicians([]);
    }
}

    const addjob = async () => {
        if (selectedItem.company_id.trim() !=='' && selectedItem.customer_id.trim() !=='' && 
            selectedItem.vehicle_id.trim() !== '' && selectedItem.service_id.trim() !== '') {
        const formData = new FormData();
            const url = "https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=add-job";
            formData.append('service_id', selectedItem.service_id);
            formData.append('vehicle_id', selectedItem.vehicle_id);
            formData.append('company_id', selectedItem.company_id);
            formData.append('customer_id', selectedItem.customer_id);
            formData.append('price', selectedItem.price);
            formData.append('scheduled_date', moment(selectedItem.scheduled_date).format('YYYY-MM-DD'));
            formData.append('estimated_out', moment(selectedItem.estimated_out).format('YYYY-MM-DD'));
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': sessionStorage.getItem('token'),
                        'Access-Control-Allow-Credentials': true,
                        'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
                    },
                    body: formData
                });
        
                if (!response.ok) {
                    if (response.status === 401) {
                        sessionStorage.clear();
                        navigate('/login');
                    } else {
                        throw new Error('API Failed');
                    }
                }
                
                let json;
                try {
                    json = await response.json();
                } catch (e) {
                    throw new Error('Failed to parse JSON');
                }
                const { success, data } = json;
    
                if (success == 1) {
                    toast.success("Successfully Add New Job", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setIsAddNewOpen(false);
                    getjoblist('',1);
                }
            } catch (error) {
                console.error('Server Connection Failed:', error);
            }
        }else{
            toast.success("Please Complete the form", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        
    }
    const handleQuantityChange = (quantity) => {
        const price = estimateinput.price;
        setestimateinput((prevInput) => ({
            ...prevInput,
            quantity: quantity,
            total_cost: quantity * price
        }));
    };
    
    const handleChangeEsList = (index, property, value) => {
        if(property=='quantity'){
            setestimatelist((prevList) => {
                const updatedList = [...prevList];
                updatedList[index] = {
                    ...updatedList[index],
                    [property]: value,
                    ['total_cost']:value*estimatelist[index].price,
                };
                return updatedList;
            });
        }
        else{
        setestimatelist((prevList) => {
            const updatedList = [...prevList];
            updatedList[index] = {
                ...updatedList[index],
                [property]: value,
            };
            return updatedList;
        });
    };
}

    const jobcheckin = (id) => {
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=job-check-in";
        formData.append('job_id', id);
        
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                toast.success("Check in Successfully", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                getjoblist('',1);
            }
        })
        .catch(error => {
            toast.success("Check in failed", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            console.error('Server Connection Failed');
        });
    };
    
      const updateCheckedAtIndex = (index, checked) => {
        if (modallist=='inspectionlist'){
        setcomplainlist((prevList) =>
          prevList.map((item, idx) => (idx === index ? { ...item, checked } : item))
        );
    } else if (modallist=='qclist'){
        setcomplainlist((prevList) =>
            prevList.map((item, idx) => (idx === index ? { ...item, qcchecked:checked } : item))
          );
    }
      };

      const updateCheckedAtIndexisp = (index, checked) => {
        if (modallist=='inspectionlist'){
        setinspectionlist((prevList) =>
          prevList.map((item, idx) => (idx === index ? { ...item, checked } : item))
        );
    } else if (modallist=='qclist'){
        setinspectionlist((prevList) =>
            prevList.map((item, idx) => (idx === index ? { ...item, checked } : item))
          );
    }
      };

    const renderComplainlist=(item,index)=>{
        if(item.type=='0'){
            return(
            
            <div className='customer-complain__row'>
            <div className='customer-complain__row--number'>{index+1}</div>
            <input className='form-control__input' type="text" placeholder='' value={item.message} disabled />
            <button class='customer-complain__row--close-btn'onClick={()=>removeItemByIndex(index)}><img class='icon' src='./../images/close-icon.png' alt='close icon' /></button>
            </div>
            )
        }
        else if (item.type=='1'){
        return(
            <div className='customer-complain__row'>
            <div className='customer-complain__row--number'>{index+1}</div>
            <input className='form-control__input' type="text" placeholder='' value={item.message} disabled />
            <button class="form-add-new-btn" onClick={()=>handlePlay(item.path)}>
                <img style={{ height: '2rem', marginRight: '.8rem' }} src='./../images/play-icon.png' alt='play icon' />Play
            </button>
            <button class='customer-complain__row--close-btn' onClick={()=>removeItemByIndex(index)}><img class='icon' src='./../images/close-icon.png' alt='close icon' /></button>
            </div>
        )}
        else
        {
            return(       
            <div className='customer-complain__row align-items-start'>
            <div className='customer-complain__row--number'>{index+1}</div>
            {item.path && item.path.length < 30 ? (
            <div className='flex-grow-1'>
                <img className='h-100' src={`https://adminpanel.mewahautoworks.com.my/api/${item.path}`} alt="truck" />
            </div>
            ) : (
            <div className='flex-grow-1'>
                <img className='h-100' src={item.path} alt="truck" />
            </div>
)}
            <button class='customer-complain__row--close-btn'onClick={()=>removeItemByIndex(index)}><img class='icon' src='./../images/close-icon.png' alt='close icon' /></button>
        </div>
        )
    }
}

const renderinspectionlist=(item,index)=>{
    if (modallist=='inspectionlist'){
    if(item.type=='0'){
        return(
        
        <div className='customer-complain__row'>
        <div className='customer-complain__row--number'>{index+1}</div>
        <input className='form-control__input' type="text" placeholder='' value={item.message} disabled />
        <button class='customer-complain__row--close-btn'onClick={()=>removeItemByIndex(index)}><img class='icon' src='./../images/close-icon.png' alt='close icon' /></button>
        </div>
        )
    }
    else if (item.type=='1'){
    return(
        <div className='customer-complain__row'>
        <div className='customer-complain__row--number'>{index+1}</div>
        <input className='form-control__input' type="text" placeholder='' value={item.message} disabled />
        <button class="form-add-new-btn" onClick={()=>handlePlay(item.path)}>
            <img style={{ height: '2rem', marginRight: '.8rem' }} src='./../images/play-icon.png' alt='play icon' />Play
        </button>
        <button class='customer-complain__row--close-btn' onClick={()=>removeItemByIndex(index)}><img class='icon' src='./../images/close-icon.png' alt='close icon' /></button>
        </div>
    )}
    else
    {
        return(       
        <div className='customer-complain__row align-items-start'>
        <div className='customer-complain__row--number'>{index+1}</div>
        {item.path && item.path.length < 30 ? (
        <div className='flex-grow-1'>
            <img className='h-100' src={`https://adminpanel.mewahautoworks.com.my/api/${item.path}`} alt="truck" />
        </div>
        ) : (
        <div className='flex-grow-1'>
            <img className='h-100' src={item.path} alt="truck" />
        </div>
)}
        <button class='customer-complain__row--close-btn'onClick={()=>removeItemByIndex(index)}><img class='icon' src='./../images/close-icon.png' alt='close icon' /></button>
    </div>
    )
}
}
else if (modallist=='qclist'){
    if(item.type=='0'){
        return(
        <div className='customer-complain__row'>
        <div className='customer-complain__row--number'>{index+1}</div>
        <input className='form-control__input' type="text" placeholder='' value={item.message} disabled />
        <input className='customer-complain__row--checkbox' type="checkbox" checked={item.checked==="0"|| item.checked==="1"? parseInt(item.checked):item.checked} onChange={(e) => updateCheckedAtIndexisp(index,e.target.checked)} />
        </div>
        )
    }
    else if (item.type=='1'){
    return(
        <div className='customer-complain__row'>
        <div className='customer-complain__row--number'>{index+1}</div>
        <input className='form-control__input' type="text" placeholder='' value={item.message} disabled />
        <button class="form-add-new-btn" onClick={()=>handlePlay(item.path)}>
            <img style={{ height: '2rem', marginRight: '.8rem' }} src='./../images/play-icon.png' alt='play icon' />Play
        </button>
        <input className='customer-complain__row--checkbox' type="checkbox" checked={item.checked==="0"|| item.checked==="1"? parseInt(item.checked):item.checked} onChange={(e) => updateCheckedAtIndexisp(index,e.target.checked)} />
        </div>
    )}
    else
    {
        return(       
        <div className='customer-complain__row align-items-start'>
        <div className='customer-complain__row--number'>{index+1}</div>
        {item.path && item.path.length < 30 ? (
        <div className='flex-grow-1'>
            <img className='h-100' src={`https://adminpanel.mewahautoworks.com.my/api/${item.path}`} alt="truck" />
        </div>
        ) : (
        <div className='flex-grow-1'>
            <img className='h-100' src={item.path} alt="truck" />
        </div>
)}
        <input className='customer-complain__row--checkbox' type="checkbox" checked={item.checked==="0"|| item.checked==="1"? parseInt(item.checked):item.checked} onChange={(e) => updateCheckedAtIndexisp(index,e.target.checked)} />
    </div>
    )
}
}
else if (modallist=='jobdetail'){
    if(item.type=='0'){
        return(
        <div className='customer-complain__row'>
        <div className='customer-complain__row--number'>{index+1}</div>
        <input className='form-control__input' type="text" placeholder='' value={item.message} disabled />
        </div>
        )
    }
    else if (item.type=='1'){
    return(
        <div className='customer-complain__row'>
        <div className='customer-complain__row--number'>{index+1}</div>
        <input className='form-control__input' type="text" placeholder='' value={item.message} disabled />
        <button class="form-add-new-btn" onClick={()=>handlePlay(item.path)}>
            <img style={{ height: '2rem', marginRight: '.8rem' }} src='./../images/play-icon.png' alt='play icon' />Play
        </button>
        </div>
    )}
    else
    {
        return(       
        <div className='customer-complain__row align-items-start'>
        <div className='customer-complain__row--number'>{index+1}</div>
        {item.path && item.path.length < 30 ? (
        <div className='flex-grow-1'>
            <img className='h-100' src={`https://adminpanel.mewahautoworks.com.my/api/${item.path}`} alt="truck" />
        </div>
        ) : (
        <div className='flex-grow-1'>
            <img className='h-100' src={item.path} alt="truck" />
        </div>
)}
    </div>
    )
}
}
}

const renderComplainlistinspection=(item,index)=>{
    if (modallist=='inspectionlist'){
    if(item.type=='0'){
        return(
        
        <div className='customer-complain__row'>
        <div className='customer-complain__row--number'>{index+1}</div>
        <input className='form-control__input' type="text" placeholder='' value={item.message} disabled />
        <input className='customer-complain__row--checkbox' type="checkbox" checked={item.checked==="0"|| item.checked==="1"? parseInt(item.checked):item.checked} onChange={(e) => updateCheckedAtIndex(index,e.target.checked)} />
        </div>
        )
    }
    else if (item.type=='1'){
    return(
        <div className='customer-complain__row'>
        <div className='customer-complain__row--number'>{index+1}</div>
        <input className='form-control__input' type="text" placeholder='' value={item.message} disabled />
        <button class="form-add-new-btn" onClick={()=>handlePlay(item.path)}>
            <img style={{ height: '2rem', marginRight: '.8rem' }} src='./../images/play-icon.png' alt='play icon' />Play
        </button>
        <input className='customer-complain__row--checkbox' type="checkbox" checked={item.checked==="0"|| item.checked==="1"? parseInt(item.checked):item.checked} onChange={(e) => updateCheckedAtIndex(index,e.target.checked)} />
        </div>
    )}
    else
    {
        return(       
        <div className='customer-complain__row align-items-start'>
        <div className='customer-complain__row--number'>{index+1}</div>
        {item.path && item.path.length < 30 ? (
        <div className='flex-grow-1'>
            <img className='h-100' src={`https://adminpanel.mewahautoworks.com.my/api/${item.path}`} alt="truck" />
        </div>
        ) : (
        <div className='flex-grow-1'>
            <img className='h-100' src={item.path} alt="truck" />
        </div>
)}
        <input className='customer-complain__row--checkbox' type="checkbox" checked={item.checked==="0"|| item.checked==="1"? parseInt(item.checked):item.checked} onChange={(e) => updateCheckedAtIndex(index,e.target.checked)} />
    </div>
    )
}
    }
    else if (modallist=='qclist'){
        if(item.type=='0'){
            return(
            
            <div className='customer-complain__row'>
            <div className='customer-complain__row--number'>{index+1}</div>
            <input className='form-control__input' type="text" placeholder='' value={item.message} disabled />
            <input className='customer-complain__row--checkbox' type="checkbox" checked={item.qcchecked==="0"|| item.qcchecked==="1"? parseInt(item.qcchecked):item.qcchecked} onChange={(e) => updateCheckedAtIndex(index,e.target.checked)} />
            </div>
            )
        }
        else if (item.type=='1'){
        return(
            <div className='customer-complain__row'>
            <div className='customer-complain__row--number'>{index+1}</div>
            <input className='form-control__input' type="text" placeholder='' value={item.message} disabled />
            <button class="form-add-new-btn" onClick={()=>handlePlay(item.path)}>
                <img style={{ height: '2rem', marginRight: '.8rem' }} src='./../images/play-icon.png' alt='play icon' />Play
            </button>
            <input className='customer-complain__row--checkbox' type="checkbox" checked={item.qcchecked==="0"|| item.qcchecked==="1"? parseInt(item.qcchecked):item.qcchecked} onChange={(e) => updateCheckedAtIndex(index,e.target.checked)} />
            </div>
        )}
        else
        {
            return(       
            <div className='customer-complain__row align-items-start'>
            <div className='customer-complain__row--number'>{index+1}</div>
            {item.path && item.path.length < 30 ? (
            <div className='flex-grow-1'>
                <img className='h-100' src={`https://adminpanel.mewahautoworks.com.my/api/${item.path}`} alt="truck" />
            </div>
            ) : (
            <div className='flex-grow-1'>
                <img className='h-100' src={item.path} alt="truck" />
            </div>
    )}
            <input className='customer-complain__row--checkbox' type="checkbox" checked={item.qcchecked==="0"|| item.qcchecked==="1"? parseInt(item.qcchecked):item.qcchecked} onChange={(e) => updateCheckedAtIndex(index,e.target.checked)} />
        </div>
        )
    }  
    }
    else if (modallist=='jobdetail'){
        if(item.type=='0'){
            return(
            
            <div className='customer-complain__row'>
            <div className='customer-complain__row--number'>{index+1}</div>
            <input className='form-control__input' type="text" placeholder='' value={item.message} disabled />
            </div>
            )
        }
        else if (item.type=='1'){
        return(
            <div className='customer-complain__row'>
            <div className='customer-complain__row--number'>{index+1}</div>
            <input className='form-control__input' type="text" placeholder='' value={item.message} disabled />
            <button class="form-add-new-btn" onClick={()=>handlePlay(item.path)}>
                <img style={{ height: '2rem', marginRight: '.8rem' }} src='./../images/play-icon.png' alt='play icon' />Play
            </button>
            </div>
        )}
        else
        {
            return(       
            <div className='customer-complain__row align-items-start'>
            <div className='customer-complain__row--number'>{index+1}</div>
            {item.path && item.path.length < 30 ? (
            <div className='flex-grow-1'>
                <img className='h-100' src={`https://adminpanel.mewahautoworks.com.my/api/${item.path}`} alt="truck" />
            </div>
            ) : (
            <div className='flex-grow-1'>
                <img className='h-100' src={item.path} alt="truck" />
            </div>
    )}
        </div>
        )
    }  
    }
}

const renderComplainlistestimate=(item,index)=>{
    if(item.type=='0'){
        return(
        
        <div className='customer-complain__row'>
        <div className='customer-complain__row--number'>{index+1}</div>
        <input className='form-control__input' type="text" placeholder='' value={item.message} disabled />
        </div>
        )
    }
    else if (item.type=='1'){
    return(
        <div className='customer-complain__row'>
        <div className='customer-complain__row--number'>{index+1}</div>
        <input className='form-control__input' type="text" placeholder='' value={item.message} disabled />
        <button class="form-add-new-btn" onClick={()=>handlePlay(item.path)}>
            <img style={{ height: '2rem', marginRight: '.8rem' }} src='./../images/play-icon.png' alt='play icon' />Play
        </button>
        </div>
    )}
    else
    {
        return(       
        <div className='customer-complain__row align-items-start'>
        <div className='customer-complain__row--number'>{index+1}</div>
        {item.path && item.path.length < 30 ? (
        <div className='flex-grow-1'>
            <img className='h-100' src={`https://adminpanel.mewahautoworks.com.my/api/${item.path}`} alt="truck" />
        </div>
        ) : (
        <div className='flex-grow-1'>
            <img className='h-100' src={item.path} alt="truck" />
        </div>
)}
    </div>
    )
}
}
const handleSelect = (selectedList, selectedItem) => {
    setSelectedTechnicians([...selectedTechnicians, selectedItem.id]);
  };

  const handleSelectfilter = (selectedList, selectedItem) => {
    setfilterlist([...filterlist, selectedItem.key]);
  };
  const handleRemove = (selectedList, removedItem) => {
    setSelectedTechnicians(selectedTechnicians.filter(id => id !== removedItem.id));
  };

  const handleRemovefilter = (selectedList, removedItem) => {
    setfilterlist(filterlist.filter(key => key !== removedItem.key));
  };
const handleinspectionclicked=async (item)=>{
   setismodalloading(true);
   setIsInspectionOpen(true);
   setSelectedItem(item); 
   await getinspection(item.id);
   await getjobdetail(item.id);
   setcurrentmodallist('inspectionlist');
   setdeletelist([]);
   setaddlist([]);
   await getforemanlist('');
   setismodalloading(false);
}

    useEffect(() => {
        setPageElement({
            ...pageelement,
            ['service_id']: '',
            ['vehicle_no']: '',
            ['status']: '',
            ['sortby']: '',
            ['order']: '',
            ['completed']: '',         
        });
        
        waitjoblist();
        
    }, []);
    
useEffect(() => {
    if(complaininput.type=='2'||complaininput.type=='1'){
    setcomplainlist([...complainlist, complaininput]);
    setaddlist([...addlist, complaininput]);
    setcomplaininput({'job_id':'','type':'','message':''});
    }
    }, [complaininput]);

    useEffect(() => {
        if(inspectioninput.type=='2'||inspectioninput.type=='1'){
            setinspectionlist([...inspectionlist, inspectioninput]);
            setaddlist([...addlist, inspectioninput]);
            setinspectioninput({'job_id':'','type':'','message':''});
        }
        }, [inspectioninput]);

    useEffect(() => {
        if(isCheckInOpen == false){
            setcomplaininput({'job_id':'','type':'','message':''});
        }
        }, [isCheckInOpen]);

    useEffect(() => {
        if(isInspectionOpen == false){
            setinspectioninput({'job_id':'','type':'','message':''});
        }
        }, [isInspectionOpen]);

    const waitjoblist= async ()=>{
        setismainloading(true);
        await getjoblist('',pageno)
        setismainloading(false);
    }

    useEffect(() => {
        const fetchData = () => {
            getcompany();
            getservice();
        };

        fetchData();
        const intervalId = setInterval(fetchData, 60000);

        return () => clearInterval(intervalId);
    }, []); 

    return (
        <>
        <div className='main-content'>
            <TopPageHeader title='Jobs' />

            <section className='content-area'>
                
                <header className='content-area__header'>
                    <div>
                        <button className='content-area__header--btn' onClick={async() => {setismodalloading(true); setIsAddNewOpen(true);handleInputChange('service_id','');setismodalloading(false) }}>+ Add New Job</button>

                        {
                            isAddNewOpen &&
                            <Modal
                                title='Add New Job'
                                setIsOpen={setIsAddNewOpen}
                                actionBtn={<button className='form-action-btn' onClick={()=>addjob()}>Save</button>}
                                modelStyle='light-center'
                                //style={{ width: '52rem', height: '45rem' }}
                            >
                            {ismodalloading ? (
                                                <div className='loading-gif-container'>
                                                <img src='./../images/spinner.gif' alt='Loading...' />
                                                </div>
                                        ) : (
                                <div className='grid-3-form'>

                                <div className='form-control'>
                                        <label className='form-control__label'>Company</label>
                                        <select className='form-control__input' value={selectedItem.company_id || ""} onChange={async(e)=>{setismodalloading(true); handleInputChange('company_id', e.target.value);await getcustomerlist(e.target.value);await getcustomervehiclelist(e.target.value);setismodalloading(false)}}>
                                        <option value="">Select a company</option>
                                            {companylist.map((item,index)=>(
                                                <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                            ))}
                                        </select>
                                    </div>
                                        <div className='form-control'>
                                        <label className='form-control__label'>Customer</label>
                                        <select className='form-control__input' value={selectedItem.customer_id || ""} onChange={(e)=>handleInputChange('customer_id', e.target.value)}>
                                        <option value="">Select a customer</option>
                                            {customerlist.map((item,index)=>(
                                                <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='form-control'>
                                        <label className='form-control__label'>Vehicle No</label>
                                        <select className='form-control__input' value={selectedItem.vehicle_id || ""} onChange={(e)=>handleInputChange('vehicle_id', e.target.value)}>
                                        <option value="">Select a Vehicle</option>
                                            {vehiclelist.map((item,index)=>(
                                                <option key={item.id} value={item.id}>
                                                {item.vehicle_no}
                                            </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='form-control'>
                                        <label className='form-control__label'>Service Type</label>
                                        <select className='form-control__input' value={selectedItem.service_id || ""} onChange={(e)=>handleInputChange('service_id', e.target.value)}>
                                        <option value="">Select a service</option>
                                            {servicelist.map((item,index)=>(
                                                <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Price.</label>
                                        <input className='form-control__input' type="text" placeholder='Price.' value={selectedItem.price} onChange={(e)=>handleInputChange('price', e.target.value)}/>
                                    </div>
                                    <div className='form-control'>
                                        <label className='form-control__label'>Scheduled Date</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Scheduled Date' selected={selectedItem.scheduled_date} minDate={todaydate} maxDate={selectedItem.estimated_out} onChange={(date) => handleInputChange('scheduled_date', date)} dateFormat="dd/MM/yyyy" onKeyDown={(e) => e.preventDefault()} />
                                    </div>
                                    <div className='form-control'>
                                        <label className='form-control__label'>Estimated Out</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Estimated Out' selected={selectedItem.estimated_out} minDate = {selectedItem.scheduled_date} onChange={(date) => handleInputChange('estimated_out', date)} dateFormat="dd/MM/yyyy" onKeyDown={(e) => e.preventDefault()} />
                                    </div>
                                </div>
                                        )
                                        }
                            </Modal>
                        }

                        <div className="content-area__header--filter-row">
                            <label className="filter-row-label">Filter by :</label>
                            {filterVisible.all && <button className="filter-row-btn active">All</button>}
                            {filterVisible.repair && <button className="filter-row-btn active">Repair</button>}
                            {filterVisible.partSales && <button className="filter-row-btn">Part Sales</button>}
                            {filterVisible.trackSales && <button className="filter-row-btn">Track Sales</button>}
                            {filterVisible.servicing && <button className="filter-row-btn">Servicing</button>}
                        </div>
                    </div>

                    <div>
                        <div className='content-area__header--search'>
                            <img className='search-icon' src='./../images/search-icon.png' alt='search icon' /> 
                            <input className='search-input' type="text" placeholder='Search' value={searchData} onChange={(e) =>{searchfunction(e.target.value)}}/>
                        </div>

                        <nav className='content-area__header--pagination'>
                                <button className='pagination-btn nav' onClick={previouspage}>&lt;</button>
                                <button className={`pagination-btn ${pageno === 1 ? 'active' : ''}`} onClick={() => setPageNumber(1)}>1</button>
                                <button className={`pagination-btn ${pageno === 2 ? 'active' : ''}`} onClick={() => setPageNumber(2)}>2</button>
                                <button className={`pagination-btn ${pageno === 3 ? 'active' : ''}`} onClick={() => setPageNumber(3)}>3</button>
                                <button className='pagination-btn'>...</button>
                                <button className={`pagination-btn ${pageno === 10 ? 'active' : ''}`} onClick={() => setPageNumber(10)}>10</button>
                                <button className='pagination-btn nav' onClick={() => setPageNumber(pageno+1)}>&gt;</button>
                            </nav>

                        <div className='content-area__header--filter-bar'>
                            <button className='filter-btn' onClick={() => setIsFilterByOpen(true)}><i class="fa fa-sliders" aria-hidden="true"></i>Filter by</button>
                            <div className='d-flex flex-grow-1 flex-basis-10 position-relative ml-8'>
                                <button className='filter-btn' onClick={() => setIsFilterSortByOpen(!isFilterSortByOpen)}><i class="fa fa-sort-amount-desc" aria-hidden="true"></i>Sort by</button>
                                {
                                    isFilterSortByOpen &&
                                    <div className='filter-dropdown'>
                                        <button className='filter-dropdown__btn' onClick={async()=> {setismainloading(true); setPageElement({...pageelement,['sortby']:'id',['order']:'asc'});setPageNumber(1);await getjoblist('',1); setismainloading(false)}}>ORDER ID (HIGH TO LOW)</button>
                                        <button className='filter-dropdown__btn' onClick={async()=> {setismainloading(true); setPageElement({...pageelement,['sortby']:'id',['order']:'desc'});setPageNumber(1);await getjoblist('',1); setismainloading(false)}}>ORDER ID (LOW TO HIGH)</button>
                                        <button className='filter-dropdown__btn' onClick={async()=> {setismainloading(true); setPageElement({...pageelement,['sortby']:'created_at',['order']:'asc'});setPageNumber(1);await getjoblist('',1); setismainloading(false)}}>DATE (OLDER TO LATEST)</button>
                                        <button className='filter-dropdown__btn' onClick={async()=> {setismainloading(true); setPageElement({...pageelement,['sortby']:'created_at',['order']:'desc'});setPageNumber(1);await getjoblist('',1); setismainloading(false)}}>DATE (LATEST TO OLDER)</button>
                                        <button className='filter-dropdown__btn' onClick={async()=> {setismainloading(true); setPageElement({...pageelement,['sortby']:'technician_names',['order']:'asc'});setPageNumber(1);await getjoblist('',1); setismainloading(false)}}>FOREMAN (A TO Z)</button>
                                        <button className='filter-dropdown__btn' onClick={async()=> {setismainloading(true); setPageElement({...pageelement,['sortby']:'technician_names',['order']:'desc'});setPageNumber(1);await getjoblist('',1); setismainloading(false)}}>FOREMAN (Z TO A)</button>
                                        <button className='filter-dropdown__btn' onClick={async()=> {setismainloading(true); setPageElement({...pageelement,['sortby']:'customer_name',['order']:'asc'});setPageNumber(1);await getjoblist('',1); setismainloading(false)}}>CUSTOMER (A TO Z)</button>
                                        <button className='filter-dropdown__btn' onClick={async()=> {setismainloading(true); setPageElement({...pageelement,['sortby']:'customer_name',['order']:'desc'});setPageNumber(1);await getjoblist('',1); setismainloading(false)}}>CUSTOMER (Z TO A)</button>
                                    </div>
                                }
                            </div>
                        </div>

                        {
                            isFilterByOpen &&
                            <Modal
                                title='Filter by'
                                setIsOpen={setIsFilterByOpen}
                                actionBtn={<button className='form-action-btn' onClick={async()=>{setismainloading(true); await getjoblist('',pageno);setIsFilterByOpen(false);setismainloading(false)}}>Filter</button>}
                                modelStyle='light-center'
                                style={{ width: '52rem', height: '45rem' }}
                            >
                                <div className='grid-1-form'>
                                    <div className='form-control'>
                                        <label className='form-control__label'>Status</label>
                                        <Multiselect
                                            displayValue="name"
                                            onKeyPressFn={function noRefCheck() { }}
                                            onRemove={handleRemovefilter}
                                            onSearch={function noRefCheck() { }}
                                            onSelect={handleSelectfilter}
                                            options={options}
                                            selectedValues={filterlist.map(key => 
                                                options.find(option => option.key === key)
                                            )}
                                            showCheckbox
                                            style={{
                                                option: {
                                                    'font-family': 'Inter',
                                                    'font-size': '1.3rem',
                                                    'font-weight': 400,
                                                    'line-height': '2.5rem',
                                                    'color': '#27303D',
                                                    'height': '2.5rem',
                                                    'pending': '0rem',
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </Modal>
                        }
                    </div>
                </header>
                {ismainloading ? (
                    <div className='loading-screen'>
                    <img src='./../images/spinner.gif' alt='Loading...' />
                    </div>
            ) : (
                <>
                <div className='content-box transparent flex-column overflow-auto scroll-sm2'>
                {postData.map((item, index) => (
                    <div className='job-box'>
                        <div className='job-box__detail'>
                            <p className='job-box__detail--text'>Order ID: {item.id}</p>
                            <h3 className='job-box__detail--title'>{item.vehicle_no}</h3>
                            <p className='job-box__detail--text'>{item.equipment_type}</p>
                            <p className='job-box__detail--text'>{item.company_name}</p>
                            <div className='job-box__detail--btn-row'>
                                <button className='row-btn' onClick={async()=> {setismodalloading(true);setSelectedItem(item); setIsViewDetailsOpen(true); setcurrentmodallist('jobdetail'); await getjobdetail(item.id); setismodalloading(false)}}>View details</button>
                                {item.status >1 ? (
                                <button className='row-btn' onClick={()=>previousstatus(item.id)}>Previous action</button>
                                ):(
                                    <span className="row-placeholder"></span> // Placeholder to maintain alignment
                                  )
                                }
                                

                            </div>
                        </div>
                        <div className='job-box__steps'>
                            <div className='step-percent'>{parseInt(item.status)*10}%</div>
                            {parseInt(item.status)===0?(
                            <div className='step-btn current'>
                                Job Created
                                <div className='step-info'>
                                    <p>{moment(item.created_at).format('DD MMM YYYY')}</p>
                                    <p>{moment(item.created_at).format('h:mm A')}</p>
                                </div>
                            </div>
                            ):parseInt(item.status)>0?(
                            <div className='step-btn done'>
                                Job Created
                                <div className='step-info'>
                                    <p>{moment(item.created_at).format('DD MMM YYYY')}</p>
                                    <p>{moment(item.created_at).format('h:mm A')}</p>
                                </div>
                            </div>
                            ):(
                            <div className='step-btn'>
                                Job Created
                            </div>    
                            )
                        }
                        {parseInt(item.status)===1?(
                            <div className='step-btn current'>
                                Check-in
                                <div className='step-info'>
                                <button className='action-btn' onClick={async() => {setismodalloading(true); setIsStartJobOpen(true);await getjobdetail(item.id); setismodalloading(false)}}>Action</button>
                                </div>  
                            </div>
                            ):parseInt(item.status)>1?(
                            <div className='step-btn done'>
                                Check-in
                                <div className='step-info'>
                                    <p>{moment(item.checkin_at).format('DD MMM YYYY')}</p>
                                    <p>{moment(item.checkin_at).format('h:mm A')}</p>
                                </div>
                            </div>
                            ):(
                            <div className='step-btn'>
                                Check-in
                            </div>    
                            )
                        }
                        {parseInt(item.status)===2?(
                            <div className='step-btn current'>
                                Inspection
                                <div className='step-info'>
                                <button className='action-btn' onClick={() => {handleinspectionclicked(item)}}>Action</button>
                                </div>
                            </div>
                            ):parseInt(item.status)>2?(
                            <div className='step-btn done'>
                                Inspection
                                <div className='step-info'>
                                    <p>{moment(item.inspected_at).format('DD MMM YYYY')}</p>
                                    <p>{moment(item.inspected_at).format('h:mm A')}</p>
                                </div>
                            </div>
                            ):(
                            <div className='step-btn'>
                                Inspection
                            </div>    
                            )
                        }
                        {parseInt(item.status)===3?(
                            <div className='step-btn current'>
                                Job Estimation
                                <div className='step-info'>
                                <button className='action-btn' onClick={async() => {setismodalloading(true);setSelectedItem(item);setIsJobEstimationOpen(true);setcurrentmodallist('estimatelist'); getspartlist(); getjobpart(item.id); getforemanlist(''); await getjobdetail(item.id); setismodalloading(false)}}>Action</button>
                                </div>
                            </div>
                            ):parseInt(item.status)>3?(
                            <div className='step-btn done'>
                                Job Estimation
                                <div className='step-info'>
                                    <p>{moment(item.estimated_at).format('DD MMM YYYY')}</p>
                                    <p>{moment(item.estimated_at).format('h:mm A')}</p>
                                </div>
                            </div>
                            ):(
                            <div className='step-btn'>
                                Job Estimation
                            </div>    
                            )
                        }
                        {parseInt(item.status)===4?(
                            <div className='step-btn current'>
                                Pending Approval
                                <div className='step-info'>
                                <button className='action-btn' onClick={() => {setSelectedItem(item); setIsCompleteJobOpen(true);}}>Action</button>
                                </div>
                            </div>
                            ):parseInt(item.status)>4?(
                            <div className='step-btn done'>
                                Pending Approval
                                <div className='step-info'>
                                    <p>{moment(item.approved_at).format('DD MMM YYYY')}</p>
                                    <p>{moment(item.approved_at).format('h:mm A')}</p>
                                </div>
                            </div>
                            ):(
                            <div className='step-btn'>
                                Pending Approval
                            </div>    
                            )
                        }
                        {parseInt(item.status)===5?(
                            <div className='step-btn current'>
                                Waiting for Parts
                                <div className='step-info'>
                                <button className='action-btn' onClick={async () => {setismodalloading(true); setIsWaitingForPartsOpen(true); setSelectedItem(item);setcurrentmodallist('waitingforpart');await getjobpart(item.id);await getspartlist(); setismodalloading(false)}}>Action</button>
                                </div>
                            </div>
                            ):parseInt(item.status)>5?(
                            <div className='step-btn done'>
                                Waiting for Parts
                                <div className='step-info'>
                                    <p>{moment(item.parts_arrived_at).format('DD MMM YYYY')}</p>
                                    <p>{moment(item.parts_arrived_at).format('h:mm A')}</p>
                                </div>
                            </div>
                            ):(
                            <div className='step-btn'>
                                Waiting for Parts
                            </div>    
                            )
                        }
                        {parseInt(item.status)===6?(
                            <div className='step-btn current'>
                                Assign Technician
                                <div className='step-info'>
                                <button className='action-btn' onClick={async () => {setismodalloading(true); setIsAssignTechnicianOpen(true);setSelectedItem(item); await getforemanlist(''); setismodalloading(false)}}>Action</button>
                                </div>
                            </div>
                            ):parseInt(item.status)>6?(
                            <div className='step-btn done'>
                                Assign Technician
                                <div className='step-info'>
                                    <p>{moment(item.parts_assigned_at).format('DD MMM YYYY')}</p>
                                    <p>{moment(item.parts_assigned_at).format('h:mm A')}</p>
                                </div>
                            </div>
                            ):(
                            <div className='step-btn'>
                                Assign Technician
                            </div>    
                            )
                        }
                        {parseInt(item.status)===7?(
                            <div className='step-btn current'>
                                Completed
                                <div className='step-info'>
                                <button className='action-btn' onClick={() => {setSelectedItem(item);setcurrentmodallist('cplist'); setIsCompleedFormanSignOpen(true)}}>Action</button>
                                </div>
                            </div>
                            ):parseInt(item.status)>7?(
                            <div className='step-btn done'>
                                Completed
                                <div className='step-info'>
                                    <p>{moment(item.completed_at).format('DD MMM YYYY')}</p>
                                    <p>{moment(item.completed_at).format('h:mm A')}</p>
                                </div>
                            </div>
                            ):(
                            <div className='step-btn'>
                                Completed
                            </div>    
                            )
                        }
                        {parseInt(item.status)===8?(
                            <div className='step-btn current'>
                                QC
                                <div className='step-info'>
                                <button className='action-btn' onClick={async() => {setismodalloading(true); setcurrentmodallist('qclist');setSelectedItem(item); setIsQCOpen(true); await getjobdetail(item.id); await getinspection(item.id); await getforemanlist(''); setismodalloading(false)}}>Action</button>
                                </div>
                            </div>
                            ):parseInt(item.status)>8?(
                            <div className='step-btn done'>
                                QC
                                <div className='step-info'>
                                    <p>{moment(item.qc_at).format('DD MMM YYYY')}</p>
                                    <p>{moment(item.qc_at).format('h:mm A')}</p>
                                </div>
                            </div>
                            ):(
                            <div className='step-btn'>
                                QC
                            </div>    
                            )
                        }
                        {parseInt(item.status)===9?(
                            <div className='step-btn current'>
                                Waiting for Vehicle Collect
                                <div className='step-info'>
                                <button className='action-btn' onClick={() => {setSelectedItem(item); setcurrentmodallist('vclist'); setIsVehicleCollectedOpen(true)}}>Action</button>
                                </div>
                            </div>
                            ):parseInt(item.status)>9?(
                            <div className='step-btn done'>
                                Waiting for Vehicle Collect
                                <div className='step-info'>
                                    <p>{moment(item.collected_at).format('DD MMM YYYY')}</p>
                                    <p>{moment(item.collected_at).format('h:mm A')}</p>
                                </div>
                            </div>
                            ):(
                            <div className='step-btn'>
                                Waiting for Vehicle Collect
                            </div>    
                            )
                        }
        
                        </div>
                    </div>
                ))
                }
                    {
                        isStartJobOpen &&
                        <Modal
                            setIsOpen={setIsStartJobOpen}
                            modelStyle='alert'
                            style={{ width: '42rem', height: '21rem' }}
                        >
                            {ismodalloading ? (
                                <div className='loading-gif-container'>
                                <img src='./../images/spinner.gif' alt='Loading...' />
                                </div>
                            ) : (
                            <div className='alert-cont'>
                                <img className='alert-cont__icon' src='./../images/caution-icon.png' alt='caution icon' />
                                <p className='alert-cont__text'>Are you sure want to start the job?</p>
                                <div className='alert-cont__footer'>
                                    <button className='solid-btn' onClick={() => { setIsStartJobOpen(false); setIsCheckInOpen(true);  setcurrentmodallist('complainlist');}}>Yes</button>
                                    <button className='outline-btn' onClick={() => setIsStartJobOpen(false)}>No</button>
                                </div>
                            </div>
                            )
                        }
                        </Modal>
                    }

                    {
                        isCompleteJobOpen &&
                        <Modal
                            setIsOpen={setIsCompleteJobOpen}
                            modelStyle='alert'
                            style={{ width: '53rem', height: '30rem' }}
                        >
                            {ismodalloading ? (
                                <div className='loading-gif-container'>
                                <img src='./../images/spinner.gif' alt='Loading...' />
                                </div>
                            ) : (
                            <div className='alert-cont'>
                                <img className='alert-cont__icon' src='./../images/caution-icon.png' alt='caution icon' />
                                <p className='alert-cont__title'>{`Job ID :#${selectedItem.id}`}</p>
                                <p className='alert-cont__text'>Are you sure you want manual approve this quotation?</p>
                                <div className='alert-cont__footer'>
                                    <button className='solid-btn' onClick={async() => {await jobapproved(selectedItem.id);setIsCompleteJobOpen(false)}}>Yes</button>
                                    <button className='outline-btn' onClick={() => setIsCompleteJobOpen(false)}>No</button>
                                </div>
                            </div>
                            )
                            }
                        </Modal>
                    }

                    {
                        isCheckInOpen &&
                        <Modal
                            title={`Check-in (${selectedItem.registration_no})`}
                            subTitle={`Job ID :#${selectedItem.id}`}
                            setIsOpen={setIsCheckInOpen}
                            actionBtn={
                                ismodalloading ? (null):(
                                <div className='d-flex justify-content-between w-100p'>
                                    <div className='d-flex'>
                                        <button className='form-action-btn danger' onClick={() => { setIsStartJobOpen(true); setIsCheckInOpen(false); }}>Back to Previous</button>
                                        <button className='form-action-btn' onClick={() => {setIsCheckInOpen(false); }}>Close</button>
                                    </div>

                                    <div className='d-flex'>
                                        <button className='form-action-btn' onClick={()=> saveseq()}>Save</button>
                                        <button className='form-action-btn' onClick={async()=>{setismodalloading(true); saveseq(); jobcheckin(selectedItem.id);setIsCheckInOpen(false); await getjoblist('',1);setismodalloading(false)}}>Check in</button>
                                    </div>
                                </div>
                                )
                            }
                            style={{ width: '100rem', height: '70rem' }}
                        >
                            {ismodalloading ? (
                                <div className='loading-gif-container'>
                                <img src='./../images/spinner.gif' alt='Loading...' />
                                </div>
                            ) : (
                                <>
                            <div className='grid-2-form'>
                                <div className='form-control'>
                                    <label className='form-control__label'>Equipment</label>
                                    <input className='form-control__input' type="text" placeholder='Equipment' value={selectedItem.equipment_type} disabled />
                                </div>

                                <div className='mt-25'>
                                    <button className='form-outline-btn'>View History</button>
                                </div>
                            </div>

                            <div className='grid-4-form'>
                                <div className='form-control'>
                                    <label className='form-control__label'>Brand</label>
                                    <input className='form-control__input' type="text" placeholder='Brand' value={selectedItem.brand} disabled />
                                </div>

                                <div className='form-control'>
                                    <label className='form-control__label'>Model</label>
                                    <input className='form-control__input' type="text" placeholder='Model' value={selectedItem.model} disabled />
                                </div>

                                <div className='form-control'>
                                    <label className='form-control__label'>Plate No</label>
                                    <input className='form-control__input' type="text" placeholder='Plate No' value={selectedItem.vehicle_no} disabled />
                                </div>

                                <div className='form-control'>
                                    <label className='form-control__label'>Chasis No.</label>
                                    <input className='form-control__input' type="text" placeholder='Chasis No.' value={selectedItem.chassis_no} disabled />
                                </div>

                                <div className='form-control'>
                                    <label className='form-control__label'>Equipment Reg No.</label>
                                    <input className='form-control__input' type="text" placeholder='Equipment Reg No.' value={selectedItem.engine_no} disabled />
                                </div>
                            </div>

                            <div className='customer-complain'>
                                <p className='customer-complain__title'>Customer Complain:</p>
                                {complainlist.map((item,index)=> renderComplainlist(item, index))}
                            </div>

                            <div className='new-complain'>
                                <div className='d-flex'>
                                <input className='form-control__input flex-grow-1' type="text" placeholder='' value={complaininput.message} onChange={(e) => {setcomplaininput({
                                    ...complaininput,
                                    ['job_id']: selectedItem.id,
                                    ['type']: '0',
                                    ['message']: e.target.value
                                })}} />
                                <button className={`new-complain__btn1 ${status === 'recording' ? 'blinking' : ''}`} onClick={()=>toggleRecordingAndRecognition('complaininput')}>
                                <img className="microphone-icon" src="./../images/microphone-icon.png" alt="microphone icon" />
                                    {status==='recording' ? 'Stop Recording' : 'Start Recording'}
                                </button>
                                <label className="new-complain__btn">
                                <img className="photo-upload-icon" src="./../images/photo-upload-icon.png" alt="upload icon" />
                                <input type="file" style={{ display: 'none' }} onChange={(event)=>handleFileChange(event,'complaininput')} />
                                </label>
                                </div>
                                <button className='form-outline-btn mt-12' onClick={() => {
                                setcomplainlist([...complainlist, complaininput]);
                                setaddlist([...addlist, complaininput]);
                                setcomplaininput({'job_id':'','type':'','message':''});
                                }}>Submit</button>
                            </div>
                            </>
                            )
                            }
                        </Modal>
                    }

                      

                    {
                        isInspectionOpen &&
                        <Modal
                            title={`Inspection (${selectedItem.registration_no})`}
                            subTitle={`Job ID :#${selectedItem.id}`}
                            setIsOpen={setIsInspectionOpen}
                            actionBtn={
                                ismodalloading ? (null):(
                                <div className='d-flex justify-content-between w-100p'>
                                    <div className='d-flex'>
                                        <button className='form-action-btn danger' onClick={()=>{previousstatus(selectedItem.id);setIsInspectionOpen(false)}}>Back to Previous</button>
                                        
                                        <button className='form-action-btn' onClick={() => setIsInspectionOpen(false)}>Close</button>
                                    </div>

                                    <div className='d-flex'>
                                        <button className='form-action-btn' onClick={()=>{setismodalloading(true);saveseqinspection();doneinspection(false);setismodalloading(false)}}>Save</button>
                                        <button className='form-action-btn' onClick={async()=>{setismodalloading(true);await saveseqinspection();doneinspection(true);setismodalloading(false)}}>Done Inspection</button>
                                    </div>
                                </div>
                                )
                            }
                            style={{ width: '100rem', height: '70rem' }}
                        >
                            {ismodalloading ? (
                                <div className='loading-gif-container'>
                                <img src='./../images/spinner.gif' alt='Loading...' />
                                </div>
                            ) : (
                                <>
                            <div className='grid-2-form grid-form-dark'>
                                <div className='form-control'>
                                    <label className='form-control__label'>Carry out by:</label>
                                    <select className='form-control__input' value={selectedItem.inspection_carry || "" } onChange={(e) => handleInputChange('inspection_carry', e.target.value)}>
                                        <option value="">Carry out by</option>
                                        {Foremanlist.map((foreman) => (
                                                    <option key={foreman.name} value={foreman.name}>
                                                        {foreman.name}
                                                    </option>
                                                ))}
                                    </select>
                                </div>

                                <div className='form-control'>
                                    <label className='form-control__label'>Accompanied by:</label>
                                    <select className='form-control__input' value={selectedItem.inspection_accompany || "" } onChange={(e) => handleInputChange('inspection_accompany', e.target.value)}>
                                        <option value="">Accompanied by</option>
                                        {Foremanlist.map((foreman) => (
                                                    <option key={foreman.name} value={foreman.name}>
                                                        {foreman.name}
                                                    </option>
                                                ))}
                                    </select>
                                </div>
                            </div>

                            <div className='customer-complain border-top-none'>
                                <p className='customer-complain__title'>Customer Complain:</p>
                                {complainlist.map((item,index)=> renderComplainlistinspection(item, index))}
                            </div>

                            <div className='new-complain'>
                                <p className='customer-complain__title'>Add-on after inspection</p>
                                {inspectionlist.map((item,index)=>renderinspectionlist(item, index))}
                                

                                <div className='d-flex mt-25 ml-32'>
                                <input className='form-control__input flex-grow-1' type="text" placeholder='' value={inspectioninput.message} onChange={(e) => {setinspectioninput({
                                    ...inspectioninput,
                                    ['job_id']: selectedItem.id,
                                    ['type']: '0',
                                    ['message']: e.target.value
                                })}} />
                                <button className={`new-complain__btn1 ${status === 'recording' ? 'blinking' : ''}`} onClick={()=>toggleRecordingAndRecognition('inspectioninput')}>
                                <img className="microphone-icon" src="./../images/microphone-icon.png" alt="microphone icon" />
                                    {status==='recording' ? 'Stop Recording' : 'Start Recording'}
                                </button>
                                <label className="new-complain__btn">
                                <img className="photo-upload-icon" src="./../images/photo-upload-icon.png" alt="upload icon" />
                                <input type="file" style={{ display: 'none' }} onChange={(event)=>handleFileChange(event,'inspectioninput')} />
                                </label>
                                </div>
                                <button className='form-outline-btn mt-12 ml-32' onClick={() => {
                                setinspectionlist([...inspectionlist, inspectioninput]);
                                setaddlist([...addlist, inspectioninput]);
                                setinspectioninput({'job_id':'','type':'','message':''});
                                }}>Submit</button>
                            </div>
                            </>
                            )
                            }
                        </Modal>
                    }

                    {
                        isJobEstimationOpen && 
                        <Modal
                            title={`Job Estimation (${selectedItem.registration_no})`}
                            subTitle={`Job ID :#${selectedItem.id}`}
                            setIsOpen={setIsJobEstimationOpen}
                            actionBtn={
                                ismodalloading ? (null):(
                                <div className='d-flex justify-content-between w-100p'>
                                    <div className='d-flex'>
                                        <button className='form-action-btn danger' onClick={()=>previousstatus(selectedItem.id)}>Back to Previous</button>
                                        <button className='form-action-btn' onClick={() => { setIsJobEstimationOpen(false); }}>Close</button>
                                    </div>

                                    <div className='d-flex'>
                                        <button className='form-action-btn' onClick={async() => {setismodalloading(true);await doneestimate(false); setIsJobEstimationOpen(false);setismodalloading(false); }}>Save</button>
                                        <button className='form-action-btn' onClick={async() => {setismodalloading(true);await doneestimate(true);setIsJobEstimationOpen(false); setIsCompleteJobOpen(true);setismodalloading(false);}}>Done Estimate</button>
                                    </div>
                                </div>
                                )
                            }
                            style={{ width: '100rem', height: '70rem' }}
                        >
                            {ismodalloading ? (
                                <div className='loading-gif-container'>
                                <img src='./../images/spinner.gif' alt='Loading...' />
                                </div>
                            ) : (
                                <>
                            <div className='grid-2-form grid-form-dark'>
                                <div className='form-control'>
                                    <label className='form-control__label'>Carry out by:</label>
                                    <select className='form-control__input' value={selectedItem.estimate_carry || "" } onChange={(e) => handleInputChange('estimate_carry', e.target.value)}>
                                        <option value="">Carry out by</option>
                                        {Foremanlist.map((foreman) => (
                                                    <option key={foreman.name} value={foreman.name}>
                                                        {foreman.name}
                                                    </option>
                                                ))}
                                    </select>
                                </div>

                                <div className='form-control'>
                                    <label className='form-control__label'>Accompanied by:</label>
                                    <select className='form-control__input' value={selectedItem.estimate_accompany || "" } onChange={(e) => handleInputChange('estimate_accompany', e.target.value)}>
                                        <option value="">Accompanied by</option>
                                        {Foremanlist.map((foreman) => (
                                                    <option key={foreman.name} value={foreman.name}>
                                                        {foreman.name}
                                                    </option>
                                                ))}
                                    </select>
                                </div>
                        </div>

                            <div className='customer-complain border-top-none light-gray scroll-sm'>
                                <p className='customer-complain__title'>Customer Complain:</p>
                                {complainlist.map((item,index)=> renderComplainlistestimate(item, index))}
                            </div>

                            <div className='part-scope scroll-sm'>
                                <h4 className='part-scope__title'>Part & Scope Needed</h4>

                                <div className='customer-complain__row'>
                                    <div className='part-scope--row-col-1'></div>

                                    <div className='part-scope--row-col-2'>
                                        <p className='part-scope--row-col-label'>Item</p>
                                    </div>

                                    <div className='part-scope--row-col-3'>
                                        <p className='part-scope--row-col-label'>Price</p>
                                    </div>

                                    <div className='part-scope--row-col-text'></div>

                                    <div className='part-scope--row-col-3'>
                                        <p className='part-scope--row-col-label'>Quality</p>
                                    </div>

                                    <div className='part-scope--row-col-text'></div>

                                    <div className='part-scope--row-col-4'>
                                        <p className='part-scope--row-col-label'>Total Cost</p>
                                    </div>

                                    <div className='part-scope--row-col-5'>
                                        <p className='part-scope--row-col-label'>Stock Status</p>
                                    </div>

                                    <div className='part-scope--row-col-6'></div>
                                </div>

                                <div className='customer-complain__row'>
                                    <div className='part-scope--row-col-1'></div>

                                    <select className='form-control__input part-scope--row-col-2' value={estimateinput.parts_id || "" } onChange={(e) => setestimateinput((prevInput) => ({
                                        ...prevInput,
                                        ['parts_id']: e.target.value,
                                        ['price']: getPriceFromId(e.target.value),
                                        ['quantity']: 0,
                                    }))}>
                                        <option value="" >Select A Part</option>
                                        {partslist.map((parts) => (
                                            <option key={parts.id} value={parts.id}>
                                                {parts.name}
                                            </option>
                                        ))}
                                    </select>

                                    <input className='form-control__input part-scope--row-col-3' type="text" placeholder='' value={`RM${estimateinput.price || '0'}`} disabled/>

                                    <div className='part-scope--row-col-text'>
                                        <p className='part-scope--row-col-label'>X</p>
                                    </div>

                                    <div className='part-scope--row-col-3'>
                                        <NumberInput
                                            style={{ width: '8rem' }}
                                            value={estimateinput.quantity||0}
                                            onChange={(value) => setestimateinput((prevInput) => ({
                                                ...prevInput,
                                                ['quantity']: value,
                                                ['total_cost']: value * prevInput.price,
                                            }))}
                                        />
                                    </div>

                                    <div className='part-scope--row-col-text'>
                                        <p className='part-scope--row-col-label font-size-18'>=</p>
                                    </div>

                                    <input className='form-control__input part-scope--row-col-4' type="text" placeholder='' value={`RM${estimateinput.total_cost || '0'}`} disabled />

                                    {getstatusFromId(estimateinput.parts_id) == "1" ? (
                                        <div className='part-scope--row-col-5 status in-stock'>In Stock</div>
                                    ) : getstatusFromId(estimateinput.parts_id) == "0" ? (
                                        <div className='part-scope--row-col-5 status out-stock'>Out Stock</div>
                                    ) : (
                                        <div className='part-scope--row-col-5 status none'>None</div>
                                    )}

                                    <button className='customer-complain__row--check-btn' onClick={() => {setestimatelist((prevInput) => [...prevInput, estimateinput]); setestimateinput({['parts_id']:"",['price']:0,['quantity']:0,['total_cost']:0})}}>
                                        <img className='icon' src='./../images/check-icon.png' alt='check icon' />
                                    </button>
                        </div>
                                {estimatelist.map((item, index)=>(
                                    <div className='customer-complain__row'>
                                    <div className='customer-complain__row--number'>{index+1}</div>

                                    <select className='form-control__input part-scope--row-col-2' value={item.parts_id || "" } onChange={(e)=>handleChangeEsList(index,'parts_id',e.target.value)}>
                                    {partslist.map((parts)=>(
                                            <option key={parts.id} value={parts.id}>
                                            {parts.name}
                                            </option>
                                        )
                                        )}
                                    </select>

                                    <input className='form-control__input part-scope--row-col-3' type="text" placeholder='' value={`RM${item.price}`} disabled />

                                    <div className='part-scope--row-col-text'>
                                        <p className='part-scope--row-col-label'>X</p>
                                    </div>

                                    <div className='part-scope--row-col-3'>
                                    <NumberInput
                                                style={{ width: '8rem' }}
                                                value={item.quantity}
                                                onChange={(value)=>handleChangeEsList(index,'quantity',value)}
                                            />
                                    </div>

                                    <div className='part-scope--row-col-text'>
                                        <p className='part-scope--row-col-label font-size-18'>=</p>
                                    </div>

                                    <input className='form-control__input part-scope--row-col-4' type="text" placeholder='' value={`RM${item.total_cost || '0'}`} disabled />

                                    {getstatusFromId(item.parts_id)==1?(
                                    <div className='part-scope--row-col-5 status in-stock'>In Stock</div>
                                ):getstatusFromId(item.parts_id)==0?(
                                    <div className='part-scope--row-col-5 status out-stock'>Out Stock</div>
                                ):(
                                <div className='part-scope--row-col-5 status none'>None</div>
                            )
                        }

                                    <button class='customer-complain__row--close-btn' onClick={()=>removeItemByIndex(index)}><img class='icon' src='./../images/close-icon.png' alt='close icon'/></button>
                                </div>
                                ))}
                            </div>
                            </>
                            )
                            }
                        </Modal>
                    }

                    {
                        isWaitingForPartsOpen &&
                        <Modal
                            title={`Waiting for Parts (${selectedItem.registration_no})`}
                            subTitle={`Job ID :#${selectedItem.id}`}
                            setIsOpen={setIsWaitingForPartsOpen}
                            actionBtn={
                                ismodalloading ? (null):(
                                <div className='d-flex justify-content-between w-100p'>
                                    <button className='form-action-btn danger' onClick={() => previousstatus(selectedItem.id)}>Back to Previous</button>
                                    <button className='form-action-btn' onClick={() => { setIsWaitingForPartsOpen(false); }}>Close</button>
                                    <button className='form-action-btn' onClick={async () => {setismodalloading(true);await jobwaitingpart(selectedItem.id); setismodalloading(false);}}>Save</button>
                                </div>
                                )
                            }
                            modelStyle='light-center'
                            style={{ width: '57.5rem', height: '50rem' }}
                        >
                            {ismodalloading ? (
                                <div className='loading-gif-container'>
                                <img src='./../images/spinner.gif' alt='Loading...' />
                                </div>
                            ) : (
                                <>
                         {estimatelist.map((item, index) => (
                            getstatusFromId(item.parts_id) == 1 ? (
                                <div key={index} className='d-flex flex-column align-items-center'>
                                <div className='waiting-for-parts'>
                                    <input className='waiting-for-parts__checkbox' type="checkbox" checked={item.checked==="0"|| item.checked==="1"? parseInt(item.checked):item.checked} onChange={(e) => setestimatelist(prevList =>
                                            prevList.map((item, i) => 
                                                i === index ? { ...item, checked: !item.checked } : item
                                            )
                                            )}/>
                                    <div className='waiting-for-parts__cont'>
                                    <span>{item.name}</span>
                                    <span className='green-text'>In Stock</span>
                                    </div>
                                </div>
                                </div>
                            ) : (
                                <div key={index} className='d-flex flex-column align-items-center'>
                                <div className='waiting-for-parts disabled'>
                                    <input className='waiting-for-parts__checkbox' type="checkbox" name="" id="" disabled />
                                    <div className='waiting-for-parts__cont'>
                                    <span>{item.name}</span>
                                    <span className='red-text'>Out of Stock</span>
                                    </div>
                                </div>
                                </div>
                            )
                            ))}
                            </>
                            )
                            }
                        </Modal>
                    }

                    {
                        isAssignTechnicianOpen &&
                        <Modal
                            title={`Assign Technician (${selectedItem.registration_no})`}
                            subTitle={`Job ID : #${selectedItem.id}`}
                            setIsOpen={setIsAssignTechnicianOpen}
                            actionBtn={
                                ismodalloading ? (null):(
                                <div className='d-flex justify-content-between w-100p'>
                                    <div className='d-flex'>
                                        <button className='form-action-btn danger' onClick={() => previousstatus(selectedItem.id)}>Back to Previous</button>
                                        <button className='form-action-btn' onClick={() => { setIsAssignTechnicianOpen(false); }}>Close</button>
                                    </div>

                                    <button className='form-action-btn' onClick={async () => {setismodalloading(true);await jobassigntechnician(selectedItem.id);setismodalloading(false); }}>Save</button>
                                </div>
                                )
                            }
                            modelStyle='light-center'
                            style={{ width: '57.5rem', height: '50rem' }}
                        >
                            {ismodalloading ? (
                                <div className='loading-gif-container'>
                                <img src='./../images/spinner.gif' alt='Loading...' />
                                </div>
                            ) : (
                                <>
                            <div className='grid-1-form'>
                                <div className='form-control'>
                                    <label className='form-control__label'>Assign Technician</label>
                                    <Multiselect
                                        displayValue="name"
                                        onKeyPressFn={function noRefCheck() { }}
                                        onRemove={handleRemove}
                                        onSearch={function noRefCheck() { }}
                                        onSelect={handleSelect}
                                        options={Foremanlist}
                                        showCheckbox
                                        style={{
                                            option: {
                                                'font-family': 'Inter',
                                                'font-size': '1.3rem',
                                                'font-weight': 400,
                                                'line-height': '2.5rem',
                                                'color': '#27303D',
                                                'height': '2.5rem',
                                                'pending': '0rem',
                                            }
                                        }}
                                    />
                                </div>

                                <div className='form-control'>
                                    <label className='form-control__label'>Estimate Complete Date:</label>
                                    <DatePicker className='form-control__input' type="date" placeholder='Deadline' selected={workdeadline} onChange={(date) => setdeadline(date)} dateFormat="dd/MM/yyyy" onKeyDown={(e) => e.preventDefault()} />
                                </div>
                            </div>
                            </>
                            )
                        }
                        </Modal>
                    }

                    {
                        isCompletedOpen &&
                        <Modal
                            setIsOpen={setIsCompletedOpen}
                            modelStyle='alert'
                            style={{ width: '48rem', height: '23rem' }}
                        >
                            {ismodalloading ? (
                                <div className='loading-gif-container'>
                                <img src='./../images/spinner.gif' alt='Loading...' />
                                </div>
                            ) : (
                                <>
                            <div className='alert-cont'>
                                <img className='alert-cont__icon' src='./../images/caution-icon.png' alt='caution icon' />
                                <p className='alert-cont__title'>Job ID : #MW1688</p>
                                <p className='alert-cont__text'>Are you sure all the inspection been done?</p>
                                <div className='alert-cont__footer'>
                                    <button className='solid-btn danger'>Back to Previous </button>
                                    <button className='solid-btn' onClick={() => setIsCompletedOpen(false)}>Yes</button>
                                    <button className='outline-btn' onClick={() => setIsCompletedOpen(false)}>No</button>
                                </div>
                            </div>
                            </>
                            )
                            }
                        </Modal>
                    }

                    {
                        isCompleedFormanSignOpen &&
                        <Modal
                            title={`Completed (${selectedItem.registration_no})`}
                            subTitle={`Job ID : #${selectedItem.id}`}
                            setIsOpen={setIsCompleedFormanSignOpen}
                            actionBtn={
                                ismodalloading ? (null):(
                                <div className='d-flex justify-content-between w-100p'>
                                    <button className='form-action-btn danger' onClick={() => previousstatus(selectedItem.id)}>Back to Previous</button>
                                    <button className='form-action-btn' onClick={async() => { setismodalloading(true);await saveSignature(selectedItem.id);setismodalloading(false); }}>Save</button>
                                </div>
                                )
                            }
                            modelStyle='light-center'
                            style={{ width: '60rem', height: '44rem' }}
                        >
                            {ismodalloading ? (
                                <div className='loading-gif-container'>
                                <img src='./../images/spinner.gif' alt='Loading...' />
                                </div>
                            ) : (
                                <>
                            <div className='grid-1-form'>
                                <div className='form-control'>
                                    <label className='form-control__label'>Foreman sign here :</label>
                                    <SignatureCanvas 
                                    ref={sigCanvas}
                                    penColor="black"
                                    canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                                    backgroundColor="white" 
                                />
                                </div>
                            </div>
                            </>
                            )
                        }
                        </Modal>
                    }

                    {
                        isVehicleCollectedOpen &&
                        <Modal
                            title={`Vehicle Collected (${selectedItem.registration_no})`}
                            subTitle={`Job ID : #${selectedItem.id}`}
                            setIsOpen={setIsVehicleCollectedOpen}
                            actionBtn={
                                ismodalloading ? (null):(
                                <div className='d-flex justify-content-between w-100p'>
                                    <button className='form-action-btn danger' onClick={() => previousstatus(selectedItem.id)}>Back to Previous</button>
                                    <button className='form-action-btn' onClick={async() => { setismodalloading(true);await saveSignature(selectedItem.id);setismodalloading(false); }}>Save</button>
                                </div>
                                )
                            }
                            modelStyle='light-center'
                            style={{ width: '60rem', height: '50rem' }}
                        >
                            {ismodalloading ? (
                                <div className='loading-gif-container'>
                                <img src='./../images/spinner.gif' alt='Loading...' />
                                </div>
                            ) : (
                                <>
                            <div className='grid-1-form'>
                                <div className='form-control'>
                                    <label className='form-control__label'>Current Mileage:</label>
                                    <input className='form-control__input flex-grow-1' type="number" placeholder='' value={selectedItem.mileage} onChange={(e) => {setSelectedItem({
                                    ...selectedItem,
                                    ['mileage']: e.target.value})}} />
                                    </div>
                                <div className='form-control'>
                                    <label className='form-control__label'>Customer sign here :</label>
                                    <SignatureCanvas 
                                    ref={sigCanvas}
                                    penColor="black"
                                    canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                                    backgroundColor="white" 
                                />
                                </div>
                            </div>
                            </>
                            )
                        }
                        </Modal>
                    }

                    {
                        isQCOpen &&
                        <Modal
                            title={`QC (${selectedItem.registration_no})`}
                            subTitle={`Job ID: #${selectedItem.id}`}
                            setIsOpen={setIsQCOpen}
                            actionBtn={
                                ismodalloading ? (null):(
                                <div className='d-flex justify-content-between w-100p'>
                                    <button className='form-action-btn danger' onClick={()=>previousstatus(selectedItem.id)}>Back to Previous</button>

                                    <button className='form-action-btn' onClick={()=>{setismodalloading(true);qcdone();setismodalloading(false)}}>Save</button>
                                </div>
                                )
                            }
                            style={{ width: '100rem', height: '70rem' }}
                        >
                            {ismodalloading ? (
                                <div className='loading-gif-container'>
                                <img src='./../images/spinner.gif' alt='Loading...' />
                                </div>
                            ) : (
                                <>
                            <div className='grid-1-form'>
                                <div className='form-control'>
                                <label className='form-control__label'>Accompany by:</label>
                                <select className='form-control__input' value={selectedItem.qc_accompany || "" } onChange={(e) => handleInputChange('qc_accompany', e.target.value)}>
                                        <option value="">Accompanied by</option>
                                        {Foremanlist.map((foreman) => (
                                                    <option key={foreman.name} value={foreman.name}>
                                                        {foreman.name}
                                                    </option>
                                                ))}
                                    </select>
                                </div>

                                <div className='form-control'>
                                    <label className='form-control__label'>Checked by:</label>
                                    <select className='form-control__input' value={selectedItem.qc_check || "" } onChange={(e) => handleInputChange('qc_check', e.target.value)}>
                                    <option value="">Checked by</option>
                                        {Foremanlist.map((foreman) => (
                                                    <option key={foreman.name} value={foreman.name}>
                                                        {foreman.name}
                                                    </option>
                                                ))}
                                    </select>
                                </div>

                            </div>

                            <div className='customer-complain'>
                                <p className='customer-complain__title'>Customer Complain:</p>
                                {complainlist.map((item,index)=> renderComplainlistinspection(item, index))}
                            </div>

                            <div className='new-complain'>
                                <p className='customer-complain__title'>Add-on after inspection</p>
                                {inspectionlist.map((item,index)=>renderinspectionlist(item, index))}
                                
                            </div>
                            </>
                            )
                            }
                        </Modal>
                    }

{
                        isViewDetailsOpen &&
                        <Modal
                            title={`View details (${selectedItem.registration_no})`}
                            setIsOpen={setIsViewDetailsOpen}
                            actionBtn={
                                ismodalloading ? (null):(
                                <button className='form-action-btn danger' onClick={()=>setIsViewDetailsOpen(false)}>Close</button>
                                )
                            }
                            style={{ width: '100rem', height: '70rem' }}
                        >
                            {ismodalloading ? (
                                <div className='loading-gif-container'>
                                <img src='./../images/spinner.gif' alt='Loading...' />
                                </div>
                            ) : (
                                <>
                            <div className='grid-2-form'>
                                <div className='form-control'>
                                    <label className='form-control__label'>Vehicle No:</label>
                                    <input className='form-control__input' type='text' placeholder='Vehicle No' value={selectedItem.vehicle_no} disabled />
                                </div>

                                <div className='form-control'>
                                    <label className='form-control__label'>Date:</label>
                                    <input className='form-control__input' type='text' placeholder='Date' value={moment(selectedItem.created_at).format('DD/MM/YY')} disabled />
                                </div>

                                <div className='form-control'>
                                    <label className='form-control__label'>Part:</label>
                                    <input className='form-control__input' type='text' placeholder='Part' value={Array.isArray(partslist) ? partslist.map(part => part.name).join(', ') : ''} disabled />
                                </div>

                                <div className='form-control'>
                                    <label className='form-control__label'>Detention Day:</label>
                                    <input className='form-control__input' type='text' placeholder='Detention Day' value={`${moment(selectedItem.estimated_out).diff(moment(selectedItem.checkin_at),'days')} days`} disabled />
                                </div>

                                <div className='form-control'>
                                    <label className='form-control__label'>Technician:</label>
                                    <input className='form-control__input' type='text' placeholder='Technician' value={Array.isArray(selectedTechnicians) ? selectedTechnicians.map(technicians => technicians.technician_name).join(', ') : ''} disabled />
                                </div>

                                <div className='form-control'>
                                    <label className='form-control__label'>QC Checked by:</label>
                                    <input className='form-control__input' type='text' placeholder='QC Checked by' value={selectedItem.qc_check} disabled />
                                </div>
                            </div>
                            {selectedItem.status>1?(
                                <>
                            <div className='grid-2-form grid-form-dark'>
                                <div className='form-control'>
                                    <label className='form-control__label'>Carry out by:</label>
                                    <input className='form-control__input' type='text' placeholder='Inspection Carry' value={selectedItem.inspection_carry} disabled />
                                </div>

                                <div className='form-control'>
                                    <label className='form-control__label'>Accompanied by:</label>
                                    <input className='form-control__input' type='text' placeholder='Inspection Accompany' value={selectedItem.inspection_accompany} disabled />
                                </div>
                            </div>

                            <div className='customer-complain border-top-none mt-0 scroll-sm'>
                                <p className='customer-complain__title'>Customer Complain:</p>

                                {complainlist.map((item,index)=> renderComplainlistinspection(item, index))}

                            </div>

                            <div className='new-complain border-top-none mt-0'>
                                <p className='customer-complain__title'>Add-on after inspection</p>

                                {inspectionlist.map((item,index)=>renderinspectionlist(item, index))}
                                
                            </div>
                            </>
                            ):null}
                            </>
                            )
                        }
                        </Modal>
}
                </div>
                </>
      )
    }
            </section>
        </div>
     
</>
    )
}

export default JobPage;