import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import SetUpCompanyPage from './pages/SetUpCompanyPage';
import SetUpCustomerPage from './pages/SetUpCustomerPage';
import SetUpVehiclePage from './pages/SetUpVehiclePage';
import SetUpServicePage from './pages/SetUpServicePage';
import SetUpUserPage from './pages/SetUpUserPage';
import SetUpForemanPage from './pages/SetUpForemanPage';
import JobPage from './pages/JobPage';
import AppointmentPage from './pages/AppointmentPage';
import IndustryNewsPage from './pages/IndustryNewsPage';
import JobHistoryPage from './pages/JobHistoryPage';
import TechAssistantPage from './pages/TechAssistantPage';
import InventoryPage from './pages/InventoryPage';
import CorePage from './pages/CorePage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/font-awesome.css';
import './css/main.css';


function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path='/' element={<LoginPage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='core' element={<CorePage />}>
          <Route path='dashboard' element={<DashboardPage />} />
          <Route path='setup-company' element={<SetUpCompanyPage />} />
          <Route path='setup-customer' element={<SetUpCustomerPage />} />
          <Route path='setup-vehicle' element={<SetUpVehiclePage />} />
          <Route path='setup-service' element={<SetUpServicePage />} />
          <Route path='setup-user' element={<SetUpUserPage />} />
          <Route path='setup-foreman' element={<SetUpForemanPage />} />
          <Route path='job' element={<JobPage />} />
          <Route path='appointment' element={<AppointmentPage />} />
          <Route path='news' element={<IndustryNewsPage />} />
          <Route path='job-history' element={<JobHistoryPage />} />
          <Route path='tech-assistant' element={<TechAssistantPage />} />
          <Route path='inventory' element={<InventoryPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
