import React, {useEffect,useState } from 'react';
import LeftNav from '../components/LeftNav';
import TopPageHeader from '../components/TopPageHeader';
import Modal from '../components/Modal';
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SetUpServicePage = () => {
    const [isAddNewOpen, setIsAddNewOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [postData, setPostData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchData, setSearchData] = useState('');
    const [pageno,PageNumber]=useState(1);
    const [ismainloading,setismainloading]=useState(false);
    const [isServiceNameValid, setIsServiceNameValid] = useState(true);
    const [isRateValid, setIsRateValid] = useState(true);
    const [isStandardTimeValid, setIsStandardTimeValid] = useState(true);
    const [isDescriptionValid, setIsDescriptionValid] = useState(true);
    const [ismodalloading,setismodalloading]=useState(false);
    const navigate = useNavigate();

    const handleEditClick = (item) => {
        setIsServiceNameValid(true);
        setIsRateValid(true);
        setIsStandardTimeValid(true);
        setIsDescriptionValid(true);
        setSelectedItem(item);
        setIsEditOpen(true);
    };

    const AddNew = () =>{
        clearSelectedItem();
        setIsAddNewOpen(true);
    };

    const clearSelectedItem = () => {
        setSelectedItem({
            ['id']: '',
            ['name']:'',
            ['rate']:'',
            ['standard_time']:'',
            ['description']:''

    });
    };

    const setPageNumber =(page)=>{
        PageNumber(page);
        getservicelist('',page)
    }

    const searchfunction =(value)=>{
        setSearchData(value);
        getservicelist(value,1)
    }

    const previouspage =()=>{
        if (pageno!=1){
            setPageNumber(pageno-1);
        }
    }

    const handleEditInputChange = (property, value) => {
        setSelectedItem({
            ...selectedItem,
            [property]: value,
        });
    };

    const addnewservice = async() =>{
        const confirmed = window.confirm("Are you sure you want to save changes?");
    if (!confirmed) {
        toast.error("Service Create Canceled", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        return;
    }

    const validateField = (field) => field && field.trim() !== '';

    const isNameValid = validateField(selectedItem.name);
    const isRateValid = validateField(selectedItem.rate);
    const isStandardTimeValid = validateField(selectedItem.standard_time);
    const isDescriptionValid = validateField(selectedItem.description);

    setIsServiceNameValid(isNameValid);
    setIsRateValid(isRateValid);
    setIsStandardTimeValid(isStandardTimeValid);
    setIsDescriptionValid(isDescriptionValid);

    if (!isNameValid || !isRateValid || !isStandardTimeValid || !isDescriptionValid) {
        return;
    }
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=add-service";
        formData.append('service_name', selectedItem.name);
        formData.append('rate', selectedItem.rate);
        formData.append('standard_time', selectedItem.standard_time);
        formData.append('description', selectedItem.description);
        return await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                toast.success("Service Create Completed", {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            setIsAddNewOpen(false);
                            getservicelist('',1);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

    const editservice = async() =>{
        const confirmed = window.confirm("Are you sure you want to save changes?");
    if (!confirmed) {
        toast.error("Service Content Change Canceled", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        return;
    }

    const validateField = (field) => {
        if (typeof field === 'string') {
          return field.trim() !== ''; // Check for non-empty strings
        } else if (typeof field === 'number') {
          return !isNaN(field); // Check for valid numbers
        }
        return false; // Return false for other data types
      };

    const isNameValid = validateField(selectedItem.name);
    const isRateValid = validateField(selectedItem.rate);
    const isStandardTimeValid = validateField(selectedItem.standard_time);
    const isDescriptionValid = validateField(selectedItem.description);

    setIsServiceNameValid(isNameValid);
    setIsRateValid(isRateValid);
    setIsStandardTimeValid(isStandardTimeValid);
    setIsDescriptionValid(isDescriptionValid);

    if (!isNameValid || !isRateValid || !isStandardTimeValid || !isDescriptionValid) {
        return;
    }
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=edit-service";
        formData.append('id', selectedItem.id);
        formData.append('service_name', selectedItem.name);
        formData.append('rate', selectedItem.rate);
        formData.append('standard_time', selectedItem.standard_time);
        formData.append('description', selectedItem.description);
        return await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                toast.success("Service Content Change Completed", {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            setIsEditOpen(false);
                            getservicelist('',1);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

    const getservicelist = async(searchvalue,page) => {
        setismainloading(true);
        let offset=(page-1)*20;
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=get-service";
        formData.append('searchword', searchvalue);
        formData.append('offset',String(offset));

        return await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                setPostData(data);
                setismainloading(false);
                return postData.map((item, index) => (
                    <tr key={index}>
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                        <td>{item.rate}</td>
                        <td>{item.standard_time}</td>
                        <td>
                        <button className='view-btn' onClick={() => handleEditClick(item)}>View details</button>
            </td>
        </tr>
                )
            )
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
            setismainloading(false);
        });
    };

    useEffect(() => {
            getservicelist('',pageno);
    }, []);

    return (
            <div className='main-content'>
                <TopPageHeader title='Service' />

                <section className='content-area'>
                    <header className='content-area__header'>
                        <button className='content-area__header--btn' onClick={() => AddNew()}>+ Add New Service</button>
                        {
                            isAddNewOpen &&
                            <Modal
                                title='Add New Service'
                                setIsOpen={setIsAddNewOpen}
                                actionBtn={ismodalloading ? (null):(<button className='form-action-btn' onClick={async() => {setismodalloading(true);await addnewservice();setismodalloading(false)}}>Save</button>)}
                            >
                                {ismodalloading ? (
                                                <div className='loading-gif-container'>
                                                <img src='./../images/spinner.gif' alt='Loading...' />
                                                </div>
                                        ) : (
                                            <>
                                <div className='grid-2-form'>
                                    <div className='form-control'>
                                        <label className='form-control__label'>Service Name</label>
                                        <input className='form-control__input' type="text" placeholder='Service Name' value={selectedItem.name} onChange={(e) =>handleEditInputChange('name', e.target.value)}/>
                                        {!isServiceNameValid && (
                                            <label className='form-control__error'>Please key in Service Name</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Rate (RM)</label>
                                        <input className='form-control__input' type="text" placeholder='Rate' value={selectedItem.rate} onChange={(e) =>handleEditInputChange('rate', e.target.value)} />
                                        {!isRateValid && (
                                            <label className='form-control__error'>Please key in Rate</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Standard Time (mins)</label>
                                        <input className='form-control__input' type="number" placeholder='Standard Time (mins)' value={selectedItem.standard_time} onChange={(e) =>handleEditInputChange('standard_time', e.target.value)} />
                                        {!isStandardTimeValid && (
                                            <label className='form-control__error'>Please key in Standard Time</label>
                                        )}
                                    </div>
                                </div>

                                <div className='form-control mt-28'>
                                    <label className='form-control__label'>Description</label>
                                    <textarea className='form-control__textarea' rows='13' placeholder='Description' value={selectedItem.description} onChange={(e) =>handleEditInputChange('description', e.target.value)}>
                                    </textarea>
                                    {!isDescriptionValid && (
                                        <label className='form-control__error'>Please key in Description</label>
                                    )}
                                </div>
                                </>
                                        )
                                        }
                            </Modal>
                        }

                        <div>
                            <div className='content-area__header--search'>
                                <img className='search-icon' src='./../images/search-icon.png' alt='search icon' />
                                <input className='search-input' type="text" placeholder='Search' value={searchData} onChange={(e) =>{searchfunction(e.target.value)}}/>
                            </div>

                            <nav className='content-area__header--pagination'>
                                <button className='pagination-btn nav' onClick={previouspage}>&lt;</button>
                                <button className={`pagination-btn ${pageno === 1 ? 'active' : ''}`} onClick={() => setPageNumber(1)}>1</button>
                                <button className={`pagination-btn ${pageno === 2 ? 'active' : ''}`} onClick={() => setPageNumber(2)}>2</button>
                                <button className={`pagination-btn ${pageno === 3 ? 'active' : ''}`} onClick={() => setPageNumber(3)}>3</button>
                                <button className='pagination-btn'>...</button>
                                <button className={`pagination-btn ${pageno === 10 ? 'active' : ''}`} onClick={() => setPageNumber(10)}>10</button>
                                <button className='pagination-btn nav' onClick={() => setPageNumber(pageno+1)}>&gt;</button>
                            </nav>
                        </div>
                    </header>
                    {ismainloading ? (
                        <div className='loading-screen'>
                        <img src='./../images/spinner.gif' alt='Loading...' />
                        </div>
                    ) : (
                    <>
                    <div className='content-box'>
                        <div className='data-table scroll-sm'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Service ID</th>
                                        <th>Name</th>
                                        <th>Rate</th>
                                        <th>Standard Time (mins)</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                {postData.map((item, index) => (
                                    <tr key={index}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>RM {item.rate}</td>
                                    <td>{item.standard_time}</td>
                                    <td>
                                    <button className='view-btn' onClick={() => handleEditClick(item)}>View details</button>
                                    </td>
                                </tr>
                                )
                            )
                            }
                                </tbody>
                            </table>
                        </div>

                        {
                            isEditOpen &&
                            <Modal
                                title='Edit Service Details '
                                setIsOpen={setIsEditOpen}
                                actionBtn={ismodalloading ? (null):(<button className='form-action-btn' onClick={async() => {setismodalloading(true);await editservice();setismodalloading(false)}}>Save</button>)}
                            >
                                {ismodalloading ? (
                                                <div className='loading-gif-container'>
                                                <img src='./../images/spinner.gif' alt='Loading...' />
                                                </div>
                                        ) : (
                                            <>
                                <div className='grid-2-form'>
                                    <div className='form-control'>
                                        <label className='form-control__label'>Service ID </label>
                                        <input className='form-control__input' type="text" placeholder='Service ID' value={selectedItem.id} disabled={true} style={{backgroundColor:'#C0C0C0'}}/>
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Service Name</label>
                                        <input className='form-control__input' type="text" placeholder='Service Name' value={selectedItem.name} onChange={(e) =>handleEditInputChange('name', e.target.value)} />
                                        {!isServiceNameValid && (
                                            <label className='form-control__error'>Please key in Service Name</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Rate (RM)</label>
                                        <input className='form-control__input' type="text" placeholder='Rate' value={selectedItem.rate} onChange={(e) =>handleEditInputChange('rate', e.target.value)} />
                                        {!isRateValid && (
                                            <label className='form-control__error'>Please key in Rate</label>
                                        )}
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Standard Time (mins)</label>
                                        <input className='form-control__input' type="number" placeholder='Standard Time (mins)' value={selectedItem.standard_time} onChange={(e) =>handleEditInputChange('standard_time', e.target.value)} />
                                        {!isStandardTimeValid && (
                                            <label className='form-control__error'>Please key in Standard Time</label>
                                        )}
                                    </div>
                                </div>

                                <div className='form-control mt-28'>
                                    <label className='form-control__label'>Description</label>
                                    <textarea className='form-control__textarea' rows='13' placeholder='Description' value={selectedItem.description} onChange={(e) =>handleEditInputChange('description', e.target.value)}>
                                    </textarea>
                                    {!isDescriptionValid && (
                                        <label className='form-control__error'>Please key in Description</label>
                                    )}
                                </div>
                                </>
                                        )
                                        }
                            </Modal>
                        }
                    </div>
                    </>
                    )
                    }
                </section>
            </div>
    )
}

export default SetUpServicePage;